import { Navigate, Outlet, useLocation } from "react-router-dom"
import { useUserStore } from "@root/stores/user"


export default function PrivateRoute() {
	const location = useLocation()
	const user = useUserStore(state => state.user)

	return user ? <Outlet /> : <Navigate to="/" replace />
}
