export const MAX_FILE_SIZE = 10485760;
const MAX_PHOTO_SIZE = 5242880;
const MAX_VIDEO_SIZE = 52428800;

export const ACCEPT_SEND_MEDIA_TYPES_MAP = {
  picture: ["jpg", "png"],
  video: ["mp4"],
  document: ["doc", "docx", "pdf", "txt", "xlsx", "csv"],
} as const;

const TOO_LARGE_TEXT = "Файл слишком большой";

export const checkAcceptableMediaType = (file: File) => {
  if (file === null) return "";

  const segments = file?.name.split(".");
  const extension = segments[segments.length - 1];
  const type = extension.toLowerCase();

  switch (type) {
    case ACCEPT_SEND_MEDIA_TYPES_MAP.document.find((name) => name === type):
      if (file.size > MAX_FILE_SIZE) return TOO_LARGE_TEXT;
      return "";

    case ACCEPT_SEND_MEDIA_TYPES_MAP.picture.find((name) => name === type):
      if (file.size > MAX_PHOTO_SIZE) return TOO_LARGE_TEXT;
      return "";

    case ACCEPT_SEND_MEDIA_TYPES_MAP.video.find((name) => name === type):
      if (file.size > MAX_VIDEO_SIZE) return TOO_LARGE_TEXT;
      return "";

    default:
      return "Не удалось отправить файл. Недопустимый тип";
  }
};
