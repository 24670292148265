import { Box } from "@mui/material";
import { useLocation } from "react-router-dom";

export default function ChatImageNewWindow() {
  const location = useLocation();
  const srcImage = location.pathname.split("image/")[1];
  return (
    <>
      <Box
        component="img"
        sx={{
          maxHeight: "100vh",
          maxWidth: "100vw",
        }}
        src={`https://s3.timeweb.cloud/3c580be9-cf31f296-d055-49cf-b39e-30c7959dc17b/angelsight/${srcImage}`}
      />
    </>
  );
}
