import { useState, useEffect } from "react"
import { Slider, useTheme } from "@mui/material"

type CustomSliderProps = {
  value: number;
  min: number;
  max: number;
  onChange: (value: number | number[]) => void;
  firstStep: number;
};

export const CustomSlider = ({
	value,
	min = 0,
	max = 100,
	onChange,
	firstStep
}: CustomSliderProps) => {
	const theme = useTheme()
	const [step, setStep] = useState<number>(1)

	useEffect(() => {
		if (firstStep === 1) return setStep(1)
		if (value <= firstStep) {
			return setStep(firstStep)
		}
		
		if (value < 100) {
			return setStep(10)
		}

		if (value < 500) {
			return setStep(20)
		}

		if (value < 2000) {
			return setStep(50)
		}

		setStep(150)
	}, [value])

	const handleChange = ({ type }: Event, newValue: number | number[]) => {
		// Для корректной работы слайдера в FireFox
		if (type !== "change") {
			onChange(newValue)
		}
	}

	return (
		<Slider
			value={value}
			defaultValue={0}
			min={min}
			max={max}
			step={step}
			onChange={handleChange}
			sx={{
				color: theme.palette.purple.main,
				height: "12px",
				"& .MuiSlider-track": {
					border: "none",
				},
				"& .MuiSlider-rail": {
					backgroundColor: "#F2F3F7",
					border: `1px solid ${theme.palette.gray.main}`,
				},
				"& .MuiSlider-thumb": {
					height: 32,
					width: 32,
					border: `6px solid ${theme.palette.purple.main}`,
					backgroundColor: "white",
					boxShadow: `0px 0px 0px 3px white,
                            0px 4px 4px 3px #C3C8DD`,
					"&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
						boxShadow: `0px 0px 0px 3px white,
                                0px 4px 4px 3px #C3C8DD`,
					},
				},
			}}
		/>
	)
}
