import { IconButton, Box, Typography, useMediaQuery, useTheme } from "@mui/material"
import SectionWrapper from "../../components/SectionWrapper"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import { useHistoryTracker } from "@root/utils/hooks/useHistoryTracker"
import SaveWrapper from "./SaveWrapper"
import { useTariffStore } from "@root/stores/tariffs"
import { type Tariff } from "@frontend/kitui"
import { withErrorBoundary } from "react-error-boundary"
import { handleComponentError } from "@root/utils/handleComponentError"

function SavedTariffs() {
	const theme = useTheme()
	const upMd = useMediaQuery(theme.breakpoints.up("md"))
	const isMobile = useMediaQuery(theme.breakpoints.down(550))
	const isTablet = useMediaQuery(theme.breakpoints.down(1000))

	const tariffs: Tariff[] = useTariffStore((state) => state.tariffs)

	const isCustomTariffs = tariffs.filter(({ isCustom }) => isCustom)

	const handleCustomBackNavigation = useHistoryTracker()

	return (
		<SectionWrapper
			maxWidth="lg"
			sx={{
				mt: upMd ? "25px" : "20px",
				mb: upMd ? "70px" : "37px",
				px: isTablet ? (isTablet ? "18px" : "40px") : "20px",
			}}
		>
			<Box
				sx={{
					mt: "20px",
					mb: upMd ? "40px" : "20px",
					display: "flex",
					alignItems: "center",
					gap: "10px",
				}}
			>
				{!upMd && (
					<IconButton onClick={handleCustomBackNavigation} sx={{ p: 0, height: "28px", width: "28px", color: "black" }}>
						<ArrowBackIcon />
					</IconButton>
				)}
				<Typography
					sx={{
						fontSize: isMobile ? "24px" : "36px",
						fontWeight: "500",
					}}
				>
          Сохраненные тарифы
				</Typography>
			</Box>
			<Box mt={upMd ? "27px" : "10px"}>
				{isCustomTariffs.map(({ _id, privileges, createdAt }, index) => (
					<SaveWrapper
						first={index === 0}
						last={index === isCustomTariffs.length - 1}
						key={_id}
						tariffId={_id}
						createdAt={createdAt}
						privileges={privileges}
					/>
				))}
			</Box>
		</SectionWrapper>
	)
}

export default withErrorBoundary(SavedTariffs, {
	fallback: <Typography mt="8px" textAlign="center">Ошибка при отображении сохраненных тарифов</Typography>,
	onError: handleComponentError,
})
