import { Box, useMediaQuery, useTheme } from "@mui/material"
import { useState } from "react"
import ExpandIcon from "./icons/ExpandIcon"
import type { ReactNode } from "react"

interface Props {
  header: ReactNode;
  divide?: boolean;
  privilege: ReactNode;
  last?: boolean;
  first?: boolean;
}

export default function CustomSaveAccordion({ header, divide = false, privilege, last, first }: Props) {
	const theme = useTheme()
	const upMd = useMediaQuery(theme.breakpoints.up("md"))
	const upXs = useMediaQuery(theme.breakpoints.up("xs"))
	const [isExpanded, setIsExpanded] = useState<boolean>(false)

	return (
		<Box
			sx={{
				backgroundColor: "white",
				borderTopLeftRadius: first ? "12px" : "0",
				borderTopRightRadius: first ? "12px" : "0",
				borderBottomLeftRadius: last ? "12px" : "0",
				borderBottomRightRadius: last ? "12px" : "0",
				borderBottom: `1px solid ${theme.palette.gray.main}`,

				...(last && { borderBottom: "none" }),
			}}
		>
			<Box
				onClick={() => setIsExpanded((prev) => !prev)}
				sx={{
					minHeight: "72px",
					px: "20px",
					display: "flex",
					alignItems: "stretch",
					justifyContent: "space-between",
					cursor: "pointer",
					userSelect: "none",
					rowGap: "10px",
					flexDirection: upXs ? undefined : "column",
				}}
			>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						width: "100%",
						fontSize: upMd ? undefined : "16px",
						lineHeight: upMd ? undefined : "19px",
						fontWeight: 500,
						color: theme.palette.gray.dark,
						px: 0,
					}}
				>
					{header}
				</Box>
				<Box
					sx={{
						pl: "20px",
						width: "52px",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						borderLeft: divide ? "1px solid #000000" : "none",
					}}
				>
					<ExpandIcon isExpanded={isExpanded} />
				</Box>
			</Box>
			{isExpanded && privilege}
		</Box>
	)
}
