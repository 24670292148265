import { useEffect, useState } from "react";
import { GetHistoryResponse, getHistory } from "@root/api/history";
import { setHistory } from "@root/stores/history";

export const useHistoryData = () => {

    useEffect(() => {
        async function fetchData() {
            try {
                const [response, errorMsg] = await getHistory();
                if (errorMsg) {
                    console.error("Произошла ошибка при вызове getHistory:", errorMsg);
                }
                if (response) {
                    setHistory(response.records);
                }
            } catch (err) {
                console.error("Произошла ошибка при вызове getHistory:", err);
            }
        }

        fetchData();
    }, []);
};
