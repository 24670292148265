import { getTariffArray } from "@root/api/tariff";
import { useUserStore } from "@root/stores/user";
import useSWR from "swr";


export function useCartTariffs() {
    const cartTariffIds = useUserStore((state) => state.userAccount?.cart);
    const { data } = useSWR(
        ["cartTariffs", cartTariffIds],
        key => getTariffArray(key[1]),
        {
            keepPreviousData: true,
        }
    );

    return data;
}
