import { Box, IconButton, Typography, Badge, useMediaQuery, useTheme } from "@mui/material";
import SectionWrapper from "@components/SectionWrapper";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import TotalPrice from "@components/TotalPrice";
import CustomWrapper from "./CustomWrapper";
import { useCart } from "@root/utils/hooks/useCart";
import { useLocation } from "react-router-dom";
import { usePrevLocation } from "@root/utils/hooks/handleCustomBackNavigation";
import { handleComponentError } from "@root/utils/handleComponentError";
import { withErrorBoundary } from "react-error-boundary";

function Cart() {
    const theme = useTheme();
    const upMd = useMediaQuery(theme.breakpoints.up("md"));
    const isMobile = useMediaQuery(theme.breakpoints.down(550));
    const isTablet = useMediaQuery(theme.breakpoints.down(1000));
    const cart = useCart();
    const location = useLocation();

    const totalPriceBeforeDiscounts = cart.priceBeforeDiscounts;
    const totalPriceAfterDiscounts = cart.priceAfterDiscounts;

    const handleCustomBackNavigation = usePrevLocation(location);

    return (
        <SectionWrapper
            maxWidth="lg"
            sx={{
                mt: upMd ? "25px" : "20px",
                px: isTablet ? (upMd ? "40px" : "18px") : "20px",
                mb: upMd ? "70px" : "37px",
            }}
        >
            <Box
                sx={{
                    mt: "20px",
                    mb: upMd ? "40px" : "20px",
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                }}
            >
                {isMobile && (
                    <IconButton onClick={handleCustomBackNavigation} sx={{ p: 0, height: "28px", width: "28px", color: "black" }}>
                        <ArrowBackIcon />
                    </IconButton>
                )}
                <Typography
                    sx={{
                        fontSize: isMobile ? "24px" : "36px",
                        fontWeight: "500",
                    }}
                >
                    Корзина
                </Typography>
            </Box>
            <Box
                sx={{
                    mt: upMd ? "27px" : "10px",
                }}
            >
                {cart.services.map((serviceData, index) => (
                    <CustomWrapper
                        key={serviceData.serviceKey}
                        serviceData={serviceData}
                        first={index === 0}
                        last={index === cart.services.length - 1}
                    />
                ))}
            </Box>
            <TotalPrice priceBeforeDiscounts={totalPriceBeforeDiscounts} priceAfterDiscounts={totalPriceAfterDiscounts} />
        </SectionWrapper>
    );
}

export default withErrorBoundary(Cart, {
    fallback: <Typography mt="8px" textAlign="center">Ошибка при отображении корзины</Typography>,
    onError: handleComponentError,
});
