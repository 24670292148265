import { useState } from "react"
import { Box, SvgIcon, Typography, useMediaQuery, useTheme } from "@mui/material"
import ExpandIcon from "@components/icons/ExpandIcon"
import ClearIcon from "@mui/icons-material/Clear"
import { currencyFormatter } from "@root/utils/currencyFormatter"
import { removeTariffFromCart } from "@root/stores/user"
import { enqueueSnackbar } from "notistack"
import { CloseButton,   ServiceCartData,   getMessageFromFetchError } from "@frontend/kitui"

import type { MouseEvent } from "react"
import CustomTariffAccordion from "@root/components/CustomTariffAccordion"

const name: Record<string, string> = {
	templategen: "Шаблонизатор",
	squiz: "Опросник",
	reducer: "Сокращатель ссылок",
	custom: "Мои тарифы",
}

interface Props {
  serviceData: ServiceCartData;
  last?: boolean;
  first?: boolean;
}

export default function CustomWrapper({ serviceData, last, first }: Props) {
	const theme = useTheme()
	const upMd = useMediaQuery(theme.breakpoints.up("md"))
	const upSm = useMediaQuery(theme.breakpoints.up("sm"))
	const [isExpanded, setIsExpanded] = useState<boolean>(false)

	function handleItemDeleteClick(tariffId: string) {
		removeTariffFromCart(tariffId)
			.then(() => {
				enqueueSnackbar("Тариф удален")
			})
			.catch((error) => {
				const message = getMessageFromFetchError(error)
				if (message) enqueueSnackbar(message)
			})
	}

	const deleteService = async (event: MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation()

		setIsExpanded(false)

		for (const { id } of serviceData.tariffs) {
			try {
				await removeTariffFromCart(id)
			} catch {}
		}

		enqueueSnackbar("Тарифы удалены")
	}

	return (
		<Box
			sx={{
				borderRadius: "12px",
			}}
		>
			<Box
				sx={{
					backgroundColor: "white",
					borderTopLeftRadius: first ? "12px" : "0",
					borderTopRightRadius: first ? "12px" : "0",
					borderBottomLeftRadius: last ? "12px" : "0",
					borderBottomRightRadius: last ? "12px" : "0",
					borderBottom: `1px solid ${theme.palette.gray.main}`,

					...(last && { borderBottom: "none" }),
				}}
			>
				<Box
					onClick={() => setIsExpanded((prev) => !prev)}
					sx={{
						height: "72px",
						px: "20px",
						display: "flex",
						gap: "15px",
						alignItems: "center",
						justifyContent: "space-between",
						cursor: "pointer",
						userSelect: "none",
					}}
				>
					<Box
						sx={{
							width: "50px",
							height: "100%",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<ExpandIcon isExpanded={isExpanded} />
					</Box>
					<Typography
						sx={{
							width: "100%",
							fontSize: upMd ? "20px" : "16px",
							lineHeight: upMd ? undefined : "19px",
							fontWeight: 500,
							color: theme.palette.text.secondary,
							px: 0,
							overflow: "hidden",
							textOverflow: "ellipsis",
						}}
					>
						{name[serviceData.serviceKey]}
					</Typography>
					<Box
						sx={{
							display: "flex",
							justifyContent: "flex-end",
							height: "100%",
							alignItems: "center",
							gap: upSm ? "111px" : "17px",
						}}
					>
						<Typography
							sx={{
								color: theme.palette.gray.dark,
								fontSize: upSm ? "20px" : "16px",
								fontWeight: 500,
							}}
						>
							{currencyFormatter.format(serviceData.price / 100)}
						</Typography>
					</Box>
					<CloseButton style={{ height: "22 px", width: "22px" }} onClick={deleteService} />
				</Box>
				{isExpanded &&
          serviceData.tariffs.map((tariff) => {
          	const privilege = tariff.privileges[0]


          	return tariff.privileges.length > 1 ? (
          		<CustomTariffAccordion key={tariff.id} tariffCartData={tariff} />
          	) : (
          		<Box
          			key={tariff.id + privilege.privilegeId}
          			sx={{
          				px: "20px",
          				py: upMd ? "25px" : undefined,
          				pt: upMd ? undefined : "15px",
          				pb: upMd ? undefined : "25px",
          				backgroundColor: "#F1F2F6",
          				display: "flex",
          				justifyContent: "space-between",
          				alignItems: "center",
          				gap: "15px",
          			}}
          		>
          			<Typography
          				sx={{
          					fontSize: upMd ? undefined : "16px",
          					lineHeight: upMd ? undefined : "19px",
          					color: theme.palette.gray.dark,
          					width: "100%",
          				}}
          			>
          				{privilege.description}
          			</Typography>
          			<Box
          				sx={{
          					width: upSm ? "140px" : "123px",
          					marginRight: upSm ? "65px" : 0,
          				}}
          			>
          				<Typography
          					sx={{
          						color: theme.palette.gray.dark,
          						fontSize: upSm ? "20px" : "16px",
          						fontWeight: 500,
          					}}
          				>
          					{currencyFormatter.format(tariff.price / 100)}
          				</Typography>
          			</Box>
          			<Box
          				sx={{
          					cursor: "pointer",
          					width: "35px",
          					display: "flex",
          					alignItems: "center",
          					justifyContent: "center",
          				}}
          				onClick={() => handleItemDeleteClick(tariff.id)}
          			>
          				<SvgIcon component={ClearIcon} sx={{ fill: theme.palette.purple.main }} />
          			</Box>
          		</Box>
          	)
          })}
			</Box>
		</Box>
	)
}
