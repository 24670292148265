import { Box } from "@mui/material"

interface Props {
  color?: string;
  bgcolor?: string;
  width?: string;
  height?: string;
}

export default function CustomIcon() {
	return (
		<Box
			sx={{
				stroke: "inherit",
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				borderRadius: "6px"
			}}
      
		>
			<svg width="24" height="24" viewBox="0 0 24 24" stroke="inherit" fill="none" xmlns="http://www.w3.org/2000/svg">
				<rect x="3.2002" y="0.75" width="17.8" height="22.25" rx="4"  strokeWidth="1.5"/>
				<path d="M7.65039 6.3125H16.5504"  strokeWidth="1.5" strokeLinecap="round"/>
				<path d="M7.65039 11.875H16.5504"  strokeWidth="1.5" strokeLinecap="round"/>
				<path d="M7.65039 17.4375H12.1004"  strokeWidth="1.5" strokeLinecap="round"/>
			</svg>
		</Box>
	)
}
