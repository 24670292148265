import {Box, Button, Typography, useMediaQuery, useTheme} from "@mui/material"
import card1Image from "@root/assets/landing/card1.png"

interface Props {
	name?: string;
	desc?: string;
	image?: string;
	href?: string;
}

export default function TemplCardPhoneLight({name="PenaDoc", desc="Самый удобный сервис для автоматизации документооборота и заполнения однотипных документов", image = card1Image, href = "#" }: Props) {
	const theme = useTheme()
	const upMd = useMediaQuery(theme.breakpoints.up("md"))
	const isMobile = useMediaQuery(theme.breakpoints.down(600))
	return (
		<Box
			sx={{
				mt: upMd ? "93px" : "55px",
				display: "flex",
				flexWrap: "wrap",
				justifyContent: "space-evenly",
				columnGap: "40px",
				rowGap: "50px",
				backgroundColor: "inherit",
			}}
		>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					flexGrow: 1,
					alignItems: "start",
					p: "20px",
					maxWidth: "360px",
					backgroundColor: " #E6E6EB",
					borderRadius: "12px",
					boxShadow: "0 10px 0 -5px #BABBC8",
					color: "black",
					height: "520px",
					justifyContent: "space-between",
				}}
			>
				<img
					src={image}
					alt=""
					style={{
						objectFit: "contain",
						width: "100%",
						display: "block",
						pointerEvents: "none",
					}}
				/>
				<Typography variant="h5">{name}</Typography>
				<Typography mt="10px" mb="20px">
					{desc}
				</Typography>

				<Button
					variant="pena-contained-light"
					target={"_blank"}
					href={href}>
				Подробнее</Button>
			</Box>
		</Box>
	)
}
