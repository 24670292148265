import { Box, Typography, useMediaQuery, useTheme } from "@mui/material"
import { isDateToday } from "@root/utils/date"


interface Props {
    unAuthenticated?: boolean;
    isSelf: boolean;
    text: string;
    createdAt: string;
}

export default function ChatMessage({ unAuthenticated = false, isSelf, text, createdAt }: Props) {
	const theme = useTheme()
	const upMd = useMediaQuery(theme.breakpoints.up("md"))

	const messageBackgroundColor = isSelf ? "white" : unAuthenticated ? "#EFF0F5" : theme.palette.gray.main

	const date = new Date(createdAt);
	const today = isDateToday(date);
	const time = date.toLocaleString([], {
		hour: "2-digit",
		minute: "2-digit",
		...(!today && { year: "2-digit", month: "2-digit", day: "2-digit" })
	})

	return (
		<Box
			sx={{
				display: "flex",
				gap: "9px",
				padding: isSelf ? "0 8px 0 0" : "0 0 0 8px",
			}}
		>
			<Typography
				sx={{
					alignSelf: "end",
					fontWeight: 400,
					fontSize: "14px",
					lineHeight: "17px",
					order: isSelf ? 1 : 2,
					margin: isSelf ? "0 0 0 auto" : "0 auto 0 0",
					color: theme.palette.gray.main,
					mb: "-4px",
					whiteSpace: "nowrap",
				}}
			>{time}</Typography>
			<Box
				sx={{
					backgroundColor: messageBackgroundColor,
					border: unAuthenticated ? "1px solid #E3E3E3" : `1px solid ${theme.palette.gray.main}`,
					order: isSelf ? 2 : 1,
					p: upMd ? "18px" : "12px",
					borderRadius: "8px",
					color: (isSelf || unAuthenticated) ? theme.palette.gray.dark : "white",
					position: "relative",
					maxWidth: `calc(100% - ${today ? 45 : 110}px)`,
					overflowWrap: "break-word",
				}}
			>
				<svg
					style={{
						position: "absolute",
						top: "-1px",
						right: isSelf ? "-8px" : undefined,
						left: isSelf ? undefined : "-8px",
						transform: isSelf ? undefined : "scale(-1, 1)",
					}}
					xmlns="http://www.w3.org/2000/svg"
					width="16"
					height="8"
					viewBox="0 0 16 8"
					fill="none"
				>
					<path d="M0.5 0.5L15.5 0.500007
                        C10 0.500006 7.5 8 7.5 7.5H7.5H0.5V0.5Z"
					fill={messageBackgroundColor}
					stroke={unAuthenticated ? "#E3E3E3" : theme.palette.gray.main}
					/>
					<rect y="1" width="8" height="8" fill={messageBackgroundColor} />
				</svg>
				<Typography
					sx={{
						fontWeight: 400,
						fontSize: "16px",
						lineHeight: "19px",
					}}
				>{text}</Typography>
			</Box>
		</Box >
	)
}
