import { Box, Typography, useMediaQuery, useTheme } from "@mui/material"

interface Props {
  bigText: string;
  text: string;
  flex: string;
}

export default function Infographics({ bigText, text, flex }: Props) {
	const theme = useTheme()
	const upMd = useMediaQuery(theme.breakpoints.up("md"))

	return (
		<Box
			sx={{
				flex,
				maxWidth: upMd ? undefined : "120px",
			}}
		>
			<Typography
				variant="infographic"
				color={theme.palette.purple.main}
				sx={{
					whiteSpace: "nowrap",
				}}
			>
				{bigText}
			</Typography>
			<Typography variant={upMd ? "body1" : "body2"} sx={{ maxWidth: upMd ? "11em" : "5em", fontWeight: 500 }}>
				{text}
			</Typography>
		</Box>
	)
}
