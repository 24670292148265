import {
  Box,
  Typography,
  Button,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { Link } from "react-router-dom";

import { NotFoundIcon } from "@root/assets/Icons/404";

export const PageNotFound = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(800));

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        minHeight: "70vh",
        margin: "0 10px",
      }}
    >
      <NotFoundIcon size={isMobile ? 275 : 390} />
      <Typography
        sx={{
          color: "#4D4D4D",
          fontSize: "18px",
          textAlign: "center",
          lineHeight: "1em",
          fontWeight: "400",
        }}
      >
        Страница не найдена
      </Typography>
      <Typography
        sx={{
          color: "#4D4D4D",
          fontSize: "18px",
          textAlign: "center",
          lineHeight: "1em",
          fontWeight: "400",
          marginBottom: "30px",
        }}
      >
        Посетите главную страницу, возможно вы найдете ее
      </Typography>
      <Link to="/" style={{ textDecoration: "none" }}>
        <Button variant="pena-contained-dark">На главную</Button>
      </Link>
    </Box>
  );
};
