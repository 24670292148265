import { UserName } from "@frontend/kitui"


export enum VerificationStatus {
  VERIFICATED = "verificated",
  NOT_VERIFICATED = "notVerificated",
  WAITING = "waiting",
}

export type UserAccountSettingsFieldStatus = Record<
  keyof UserName,
  {
    value: string;
    error: string | null;
    touched: boolean;
  }
>;
