import { Box, Typography } from "@mui/material";

export const ApologyPage = ({ message }: { message: string }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <Typography
        sx={{
          textAlign: "center",
        }}
      >
        {message || "что-то пошло не так"}
      </Typography>
    </Box>
  );
};
