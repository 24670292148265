import Typography from "@mui/material/Typography";
import TariffCard from "./TariffCard";
import NumberIcon from "@root/components/NumberIcon";
import {Box, Button, Tooltip, useTheme} from "@mui/material"
import {cardShadow} from "@utils/theme"

export default function FreeTariffCard() {
  const theme = useTheme();

  return (
      <>
          <Box
              sx={{
                  width: "100%",
                  minHeight: "250px",
                  borderRadius: "12px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  p: "20px",
                  boxShadow: cardShadow,
                  backgroundColor: theme.palette.purple.main,
                  color: "white",
              }}
          >
              <Box
                  sx={{
                      position: "relative",
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: "16px",
                  }}
              >
                  <NumberIcon number={0} color={theme.palette.purple.main} backgroundColor="white" />
                      <Box
                          sx={{
                              display: "flex",
                              alignItems: "baseline",
                              flexWrap: "wrap",
                              columnGap: "10px",
                              rowGap: 0,
                          }}
                      >
                          <Typography variant="price" color="white">
                              0 руб.
                          </Typography>
                      </Box>
              </Box>
                  <Typography
                      variant="h5"
                      sx={{
                          mt: "14px",
                          mb: "10px",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          width: "100%",
                      }}
                  >
                      Бесплатно 14 дней
                  </Typography>
                  <Box
                      sx={{
                          width: "100%",
                          mb: "auto",
                      }}
                  >
                      <Typography sx={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                          Каждому пользователю все наши продукты в  первые 14 дней доступны совершенно бесплатно (кроме доп.услуг)
                      </Typography>
                  </Box>
          </Box>
      </>

  );
}
