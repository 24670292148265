import { Box, Button, Typography, useMediaQuery, useTheme } from "@mui/material"
import SectionWrapper from "@components/SectionWrapper"

export default function Section5() {
	const theme = useTheme()
	const upMd = useMediaQuery(theme.breakpoints.up("md"))
	const isTablet = useMediaQuery(theme.breakpoints.down(1000))

	return (
		<SectionWrapper
			component="section"
			maxWidth="lg"
			outerContainerSx={{
				backgroundColor: theme.palette.purple.main,
			}}
			sx={{
				pt: upMd ? "101px" : "80px",
				pb: upMd ? "100px" : "80px",
				px: isTablet ? (upMd ? "40px" : "18px") : "20px",
			}}
		>
			<Box
				sx={{
					display: "grid",
					gridTemplate: upMd ? "auto auto / 1fr 1fr" : "repeat(3, auto) / auto",
				}}
			>
				<Typography variant="h4" sx={{ mb: upMd ? "62px" : "30px" }}>
        Хотите свой IT продукт?
				</Typography>
				<Typography
					sx={{
						maxWidth: isTablet ? "100%" : "79.3%",
						gridRow: upMd ? "span 2" : "",
						justifySelf: upMd ? "end" : "start",
						mb: upMd ? undefined : "50px",
					}}
				>
          Закажите разработку любого продукта на любом этапе. У высококлассной команды специалистов 
с обширным опытом и компетенциями, получить продукт мечты еще никогда не было так просто.
				</Typography>
				<Box
					sx={{
						display: "flex",
						flexDirection: upMd ? "row" : "column",
						alignItems: "start",
						gap: upMd ? "24px" : "20px",
					}}
				>
					<Button sx={{ width: "180px", height: "44px", p: 0 }}
							variant="pena-contained-light"
							target={"_blank"}
							href={"https://pena.digital/"}>
            Наши услуги
					</Button>
				</Box>
			</Box>
		</SectionWrapper>
	)
}
