import type { UserDocumentTypes } from "@root/model/user"

export const DOCUMENT_TYPE_MAP: Record<
  "inn" | "rule" | "egrule" | "certificate",
  UserDocumentTypes
> = {
	inn: "ИНН",
	rule: "Устав",
	certificate: "Свидетельство о регистрации НКО",
	egrule: "ИНН",
}
