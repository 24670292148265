import { Box, Button, Dialog, IconButton, Typography } from "@mui/material"
import { enqueueSnackbar } from "notistack"
import CloseSmallIcon from "@root/components/icons/CloseSmallIcon"
import {
	closeDocumentsDialog,
	setDocument,
	useUserStore,
} from "@root/stores/user"
import DocumentUploadItem from "./DocumentUploadItem"
import DocumentItem from "./DocumentItem"
import { verify } from "../helper"
import { VerificationStatus } from "@root/model/account"
import { sendDocuments, updateDocuments } from "@root/api/verification"
import { readFile } from "@root/utils/readFile"
import { deleteEmptyKeys } from "@root/utils/deleteEmptyKeys"
import { useState } from "react"
import { theme } from "@root/utils/theme"

const dialogContainerStyle = {
	height: "100%",
	overflowY: "scroll",
}

export default function NkoDocumentsDialog() {
	const isOpen = useUserStore((state) => state.isDocumentsDialogOpen)
	const verificationStatus = useUserStore((state) => state.verificationStatus)
	const documents = useUserStore((state) => state.documents)
	const documentsUrl = useUserStore((state) => state.documentsUrl)
	const userId = useUserStore((state) => state.userId) ?? ""

	const sendUploadedDocuments = async () => {
		if (
			documents["ИНН"].file &&
      documents["Устав"].file &&
      documents["Свидетельство о регистрации НКО"].file
      && !documentsUrl["ИНН"] && !documentsUrl["Устав"] && !documentsUrl["Свидетельство о регистрации НКО"]
		) {
			closeDocumentsDialog()
			//Пользователь заполнил все поля и на беке пусто
			const inn = await readFile(documents["ИНН"].file, "binary")
			const rule = await readFile(documents["Устав"].file, "binary")
			const certificate = await readFile(
				documents["Свидетельство о регистрации НКО"].file,
				"binary"
			)

			const [_, sendDocumentsError] = await sendDocuments({
				status: "nko",
				inn,
				rule,
				certificate,
			})

			if (sendDocumentsError) {
				enqueueSnackbar(sendDocumentsError)

				return
			}
			if (_ === "OK") {
				enqueueSnackbar("Информация доставлена")
			}

			setDocument("ИНН", null)
			setDocument("Устав", null)
			setDocument("Свидетельство о регистрации НКО", null)
  
			await verify(userId)
		} else { //Пользователь заполнил не все, или на беке что-то есть
			if ((documents["ИНН"].file || documents["Устав"].file || documents["Свидетельство о регистрации НКО"].file) && (documentsUrl["ИНН"] || documentsUrl["Устав"] || documentsUrl["Свидетельство о регистрации НКО"])) { //минимум 1 поле заполнено на фронте и минимум 1 поле на беке записано
				closeDocumentsDialog()
				const inn = documents["ИНН"].file
					? await readFile(documents["ИНН"].file, "binary")
					: undefined
				const rule = documents["Устав"].file
					? await readFile(documents["Устав"].file, "binary")
					: undefined
				const certificate = documents["Свидетельство о регистрации НКО"].file
					? await readFile(
						documents["Свидетельство о регистрации НКО"].file,
						"binary"
					)
					: undefined

				const [_, updateDocumentsError] = await updateDocuments(
					deleteEmptyKeys({
						status: "nko",
						inn,
						rule,
						certificate,
					})
				)

				if (updateDocumentsError) {
					enqueueSnackbar(updateDocumentsError)

					return
				}
				if (_ === "OK") {
					enqueueSnackbar("Информация доставлена")
				}

				setDocument("ИНН", null)
				setDocument("Устав", null)
				setDocument("Свидетельство о регистрации НКО", null)
    
				await verify(userId)
			}
		}

	}

	const disbutt = () => {
		if (documents["ИНН"].file && documents["Устав"].file && documents["Свидетельство о регистрации НКО"].file && !documentsUrl["ИНН"] && !documentsUrl["Устав"] && !documentsUrl["Свидетельство о регистрации НКО"]) { //post
			//все поля заполнены и на беке пусто
			return false
		} else {//patch
			if (documents["ИНН"].file || documents["Устав"].file || documents["Свидетельство о регистрации НКО"].file ) {
				//минимум одно поле заполнено
				return false
			}
			return true
		}
	}

	const documentElements =
    verificationStatus === VerificationStatus.VERIFICATED ? (
    	<>
    		<DocumentItem
    			text="1. Свидетельство о регистрации НКО"
				keyName="certificate"
    			documentUrl={documentsUrl["Свидетельство о регистрации НКО"]}
				
    		/>
    		<DocumentItem
    			text="2. Скан ИНН организации НКО (выписка из ЕГЮРЛ)"
				keyName="inn"
    			documentUrl={documentsUrl["ИНН"]}
    		/>
    		<DocumentItem
    			text="3. Устав организации"
    			documentUrl={documentsUrl["Устав"]}
				keyName="rule"
    		/>
    	</>
    ) : (
    	<>
    		<DocumentUploadItem
    			text="1. Свидетельство о регистрации НКО"
    			accept="application/pdf"
    			document={documents["Свидетельство о регистрации НКО"]}
    			documentUrl={documentsUrl["Свидетельство о регистрации НКО"]}
    			onFileChange={(e) =>
    				setDocument(
    					"Свидетельство о регистрации НКО",
    					e.target?.files?.[0] || null
    				)
    			}
    		/>
    		<DocumentUploadItem
    			text="2. Скан ИНН организации НКО (выписка из ЕГЮРЛ)"
    			accept="application/pdf"
    			document={documents["ИНН"]}
    			documentUrl={documentsUrl["ИНН"]}
    			onFileChange={(e) => setDocument("ИНН", e.target?.files?.[0] || null)}
    		/>
    		<DocumentUploadItem
    			text="3. Устав организации"
    			accept="application/pdf"
    			document={documents["Устав"]}
    			documentUrl={documentsUrl["Устав"]}
    			onFileChange={(e) =>
    				setDocument("Устав", e.target?.files?.[0] || null)
    			}
    		/>
    	</>
    )

	return (
		<Dialog
			open={isOpen}
			onClose={closeDocumentsDialog}
			PaperProps={{
				sx: {
					width: "600px",
					maxWidth: "600px",
					backgroundColor: "white",
					position: "relative",
					display: "flex",
					flexDirection: "column",
					gap: "20px",
					borderRadius: "12px",
					boxShadow: "none",
					overflowY: "hidden",
				},
			}}
			slotProps={{
				backdrop: { style: { backgroundColor: "rgb(0 0 0 / 0.7)" } },
			}}
		>
			<IconButton
				onClick={closeDocumentsDialog}
				sx={{
					position: "absolute",
					right: "7px",
					top: "7px",
				}}
			>
				<CloseSmallIcon />
			</IconButton>
			<Box sx={{ p: "40px", pb: "70px",  overflowY: "scroll" }}>
				<Typography variant="h5" lineHeight="100%">
					{verificationStatus === VerificationStatus.VERIFICATED
						? "Ваши документы"
						: "Загрузите документы"}
				</Typography>
				<Typography
					sx={{
						fontWeight: 400,
						fontSize: "16px",
						lineHeight: "100%",
						mt: "12px",
					}}
				>
          для верификации НКО в формате PDF
				</Typography>
				{Boolean(!documentsUrl["ИНН"] && !documentsUrl["Устав"] && !documentsUrl["Свидетельство о регистрации НКО"]) && <Typography
					sx={{
						fontWeight: 400,
						fontSize: "16px",
						lineHeight: "100%",
						mt: "12px",
						color: theme.palette.purple.main
					}}
				>
          Пожалуйста, заполните все поля!
				</Typography>}
				<Box sx={dialogContainerStyle}>
					<Box
						sx={{
							maxHeight: "280px",
							mt: "30px",
							display: "flex",
							flexDirection: "column",
							gap: "25px",
						}}
					>
						{documentElements}
					</Box>
				</Box>
			</Box>
			{verificationStatus === VerificationStatus.NOT_VERIFICATED && (
				<Button
					sx={{ position: "absolute", bottom: "20px", right: "20px" }}
					onClick={sendUploadedDocuments}
					variant="pena-contained-dark"
					disabled={disbutt()}
				>
          Отправить
				</Button>
			)}
			<></>
		</Dialog>
	)
}