import { Link, useNavigate } from "react-router-dom";
import {
  Box,
  IconButton,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import LogoutIcon from "../icons/LogoutIcon";
import Drawers from "../Drawers";
import { logout } from "@root/api/auth";
import { enqueueSnackbar } from "notistack";
import { clearUserData, useUserStore } from "@root/stores/user";
import { AvatarButton, clearAuthToken } from "@frontend/kitui";
import { clearCustomTariffs } from "@root/stores/customTariffs";
import { clearTickets } from "@root/stores/tickets";

import { currencyFormatter } from "@root/utils/currencyFormatter";

import walletIcon from "@root/assets/Icons/wallet_icon.svg";
import {setNotEnoughMoneyAmount} from "@stores/notEnoughMoneyAmount"

export const NavbarPanel = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down(1000));
  const cash = useUserStore((state) => state.userAccount?.wallet.cash) ?? 0;
  const initials = useUserStore((state) => state.initials);

  async function handleLogoutClick() {
    clearAuthToken();
    clearUserData();
    clearCustomTariffs();
    clearTickets();
    setNotEnoughMoneyAmount(0)
    navigate("/");

    const [_, logoutError] = await logout();

    if (logoutError) {
      return enqueueSnackbar(logoutError);
    }
  }

  return (
    <Box sx={{ display: "flex", ml: "auto" }}>
      <Drawers />
      <IconButton
        sx={{
          display: "flex",
          alignItems: "center",
          ml: isTablet ? "10px" : "20px",
          bgcolor: "#F2F3F7",
          borderRadius: "6px",
          height: "36px",
          width: "36px",
        }}
        onClick={() => navigate("/wallet")}
      >
        <img src={walletIcon} alt="wallet" />
      </IconButton>
      <Box sx={{ ml: "8px", whiteSpace: "nowrap" }}>
        <Typography
          sx={{
            fontSize: "12px",
            lineHeight: "14px",
            color: theme.palette.gray.dark,
          }}
        >
          Мой баланс
        </Typography>
        <Typography variant="body2" color={theme.palette.purple.main}>
          {currencyFormatter.format(cash / 100)}
        </Typography>
      </Box>
      <AvatarButton component={Link} to="/settings" sx={{ ml: "27px" }}>
        {initials}
      </AvatarButton>
      <IconButton
        onClick={handleLogoutClick}
        sx={{
          ml: "20px",
          bgcolor: "#F2F3F7",
          borderRadius: "6px",
          height: "36px",
          width: "36px",
        }}
      >
        <LogoutIcon />
      </IconButton>
    </Box>
  );
};
