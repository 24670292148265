import { Box, useMediaQuery, useTheme } from "@mui/material"
import InputTextfield from "@components/InputTextfield"
import PasswordInput from "@components/passwordInput"
import { setSettingsField, useUserStore } from "@root/stores/user"
import type { LP } from "./AccountSettings"

interface Props {
	loginPasswordFocus: boolean;
	setLoginPasswordFocus: (a:boolean) => void;
	setOnChangeTypeLP: (a:LP) => void;
}

export default function UserFields({
	setLoginPasswordFocus,
	setOnChangeTypeLP
}: Props) {
	const theme = useTheme()
	const upSm = useMediaQuery(theme.breakpoints.up("sm"))
	const upMd = useMediaQuery(theme.breakpoints.up("md"))

	const { settingsFields, user } = useUserStore((state) => state)
	const a = useUserStore((state) => state)

	const textFieldProps = {
		gap: upMd ? "16px" : "10px",
		color: "#F2F3F7",
		bold: true,
	}

	return (
		<Box
			sx={{
				display: "grid",
				gridAutoFlow: upSm ? "column" : "row",
				gridTemplateRows: "repeat(4, auto)",
				gridAutoColumns: "1fr",
				rowGap: "15px",
				columnGap: "31px",
				flexGrow: 1,
			}}
		>
			<InputTextfield
				TextfieldProps={{
					placeholder: "Имя",
					value: settingsFields.firstname.value || "",
					helperText: settingsFields.firstname.touched && settingsFields.firstname.error,
					error: settingsFields.firstname.touched && Boolean(settingsFields.firstname.error),
				}}
				onChange={(e) => setSettingsField("firstname", e.target.value)}
				id="firstname"
				label="Имя"
				{...textFieldProps}
			/>
			<InputTextfield
				TextfieldProps={{
					placeholder: "Фамилия",
					value: settingsFields.secondname.value || "",
					helperText: settingsFields.secondname.touched && settingsFields.secondname.error,
					error: settingsFields.secondname.touched && Boolean(settingsFields.secondname.error),
				}}
				onChange={(e) => setSettingsField("secondname", e.target.value)}
				id="secondname"
				label="Фамилия"
				{...textFieldProps}
			/>
			<InputTextfield
				TextfieldProps={{
					placeholder: "Отчество",
					value: settingsFields.middlename.value || "",
					helperText: settingsFields.middlename.touched && settingsFields.middlename.error,
					error: settingsFields.middlename.touched && Boolean(settingsFields.middlename.error),
				}}
				onChange={(e) => setSettingsField("middlename", e.target.value)}
				id="middlename"
				label="Отчество"
				{...textFieldProps}
			/>
			<InputTextfield
				TextfieldProps={{
					placeholder: "ООО Фирма",
					value: settingsFields.orgname.value || "",
					helperText: settingsFields.orgname.touched && settingsFields.orgname.error,
					error: settingsFields.orgname.touched && Boolean(settingsFields.orgname.error),
				}}
				onChange={(e) => setSettingsField("orgname", e.target.value)}
				id="orgname"
				label="Название компании"
				{...textFieldProps}
			/>
			<InputTextfield
				TextfieldProps={{
					placeholder: "username@penahaub.com",
					value: settingsFields.email.value || "",
					helperText: settingsFields.email.touched && settingsFields.email.error,
					error: settingsFields.email.touched && Boolean(settingsFields.email.error),
				}}
				onChange={(e) => setSettingsField("email", e.target.value)}
				onFocus={() => {
					setLoginPasswordFocus(true)
				}}
				onBlur={() => {
					setLoginPasswordFocus(false)
					if (settingsFields.email.value !== user?.email) setOnChangeTypeLP("email")
				}}
				id="email"
				label="Изменить E-mail"
				{...textFieldProps}
			/>
			<InputTextfield
				TextfieldProps={{
					placeholder: "+7 900 000 00 00",
					value: settingsFields.phoneNumber.value || "",
					helperText: settingsFields.phoneNumber.touched && settingsFields.phoneNumber.error,
					error: settingsFields.phoneNumber.touched && Boolean(settingsFields.phoneNumber.error),
				}}
				onChange={(e) => setSettingsField("phoneNumber", e.target.value)}
				id="phoneNumber"
				label="Телефон"
				{...textFieldProps}
			/>
			<PasswordInput
				TextfieldProps={{
					placeholder: "Не менее 8 символов",
					value: settingsFields.password.value || "",
					helperText: settingsFields.password.touched && settingsFields.password.error,
					error: settingsFields.password.touched && Boolean(settingsFields.password.error),
					autoComplete: "new-password",
				}}
				onFocus={() => {
					setLoginPasswordFocus(true)
				}}
				onBlur={() => {
					setLoginPasswordFocus(false)
					if (settingsFields.password.value.length > 0) setOnChangeTypeLP("password")
				}}

				onChange={(e) => setSettingsField("password", e.target.value)}
				id="password"
				label="Изменить пароль"
				{...textFieldProps}
			/>
		</Box>
	)
}