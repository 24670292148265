import {
	Link,
	Typography
} from "@mui/material"
import IconAmo from "@root/components/icons/logo.png"  
  
export default function () {
	return (
		<Link
			style={{
				backgroundColor: "#329dc9",
				width: "310px",
				height: "47px",
				display: "inline-flex",
				justifyContent: "start",
				border: "#316a88 1px solid",
				color: "white",
				textDecoration: "none"

			}}      
			href="https://oauth.pena.digital/amocrm/auth/redirect?returnUrl=hub.pena.digital/tariffs">
			<img
				src={IconAmo}
				style={{
					height: "100%",
					maxWidth: "339px",
					objectFit: "scale-down",
					userSelect: "none",
					pointerEvents: "none",
				}}
			/>
			<Typography
            
				sx={{
					margin: "auto",
					letterSpacing: "1px",
					fontSize: "14px",
					fontWeight: 600,
					textTransform: "uppercase"
				}}>войти</Typography>
            
		</Link>
	)
}