import makeRequest from "@api/makeRequest";

import { jsonToFormdata } from "@root/utils/jsonToFormdata";
import { parseAxiosError } from "@root/utils/parse-error";

import type {
  Verification,
  SendDocumentsArgs,
  UpdateDocumentsArgs,
} from "@root/model/auth";

const API_URL = `${process.env.REACT_APP_DOMAIN}/verification/v1.0.0/verification`;

export const verification = async (
  userId: string
): Promise<[Verification | null, string?]> => {
  try {
    const verificationResponse = await makeRequest<never, Verification>({
      method: "GET",
      url: API_URL,
      // url: `${API_URL}/${userId}`,
      useToken: true,
      withCredentials: true,
    });

    verificationResponse.files = verificationResponse.files.map((obj) => {
      obj.url = obj.url
        .replace(
          "https://hub.pena.digital",
          process.env.REACT_APP_DOMAIN?.toString() || ""
        )
        .replace(
          "https://shub.pena.digital",
          process.env.REACT_APP_DOMAIN?.toString() || ""
        );
      return obj;
    });

    return [verificationResponse];
  } catch (nativeError) {
    const [error, status] = parseAxiosError(nativeError);

    if (status === 404) {
      return [null, "нет данных"];
    }

    return [null, `Ошибка запроса верификации. ${error}`];
  }
};

export const sendDocuments = async (
  documents: SendDocumentsArgs
): Promise<[Verification | "OK" | null, string?]> => {
  try {
    const sendDocumentsResponse = await makeRequest<FormData, Verification>({
      method: "POST",
      url: API_URL,
      body: jsonToFormdata({ ...documents}),
      useToken: true,
      withCredentials: true,
    });

    return [sendDocumentsResponse];
  } catch (nativeError) {
    const [error] = parseAxiosError(nativeError);

    return [null, `Ошибка отправки документов. ${error}`];
  }
};

export const updateDocuments = async (
  documents: UpdateDocumentsArgs
): Promise<[Verification | "OK" | null, string?]> => {
  try {
    // .replace(/\s/g, '_')
    // if (documents.inn) {
    //   documents.inn.append("name", "blob");
    // }
    // if (documents.rule) {
    //   documents.rule.append("name", "blob");
    // }
    // if (documents.certificate) {
    //   documents.certificate.append("name", "blob");
    // }
    console.log("documents")
    console.log(documents)
    const updateDocumentsResponse = await makeRequest<FormData, Verification>({
      method: "PUT",
      url: `${API_URL}`,
      body: jsonToFormdata(
        documents.inn ? { ...documents} : documents
      ),
      useToken: true,
      withCredentials: true,
    });

    return [updateDocumentsResponse];
  } catch (nativeError) {
    const [error] = parseAxiosError(nativeError);

    return [null, `Ошибка обновления документов. ${error}`];
  }
};

export const updateDocument = async (
  body: FormData
): Promise<[Verification | "OK" | null, string?]> => {
  console.log("body")
  console.log(body)
  try {
    const updateDocumentResponse = await makeRequest<FormData, Verification>({
      method: "PATCH",
      url: API_URL,
      body,
      useToken: true,
      withCredentials: true,
    });

    return [updateDocumentResponse];
  } catch (nativeError) {
    const [error] = parseAxiosError(nativeError);

    return [null, `Ошибка обновления документа. ${error}`];
  }
};
