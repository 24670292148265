import { Box, Typography, SxProps, Theme, Button, useTheme, useMediaQuery } from "@mui/material"
import cardImageBig from "@root/assets/landing/card1big.png"
import { PenaLink } from "@frontend/kitui"
import { Link as RouterLink } from "react-router-dom"

interface Props {
  light?: boolean;
  sx?: SxProps<Theme>;
  name?: string;
  desc?: string;
  image?: string;
  href?: string;
}

export default function WideTemplCard({ light = true, sx, name="PenaDoc", desc="Самый удобный сервис для автоматизации документооборота и заполнения однотипных документов", image = cardImageBig, href="#" }: Props) {
	const theme = useTheme()
	const isTablet = useMediaQuery(theme.breakpoints.down(1000))

	return (
		<Box
			sx={{
				position: "relative",
				display: "flex",
				justifyContent: "space-between",
				p: "40px 20px 20px 20px",
				backgroundColor: light ? "#E6E6EB" : "#434657",
				borderRadius: "12px",
				...sx,
			}}
		>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					alignItems: "start",
					justifyContent: "space-between"
				}}
			>
				<Typography variant="h5">{name}</Typography>
				<Typography maxWidth="552px">
					{desc}
				</Typography>
				<Button sx={{ width: "180px", height: "44px", p: 0 }}
						variant="pena-contained-light"
						target={"_blank"}
						href={href}>
					Подробнее
				</Button>
			</Box>
			<img
				src={image}
				alt=""
				style={{
					display: "block",
					objectFit: "contain",
					pointerEvents: "none",
					marginBottom: "-40px",
					marginTop: "-110px",
					maxWidth: "390px",
				}}
			/>
		</Box>
	)
}
