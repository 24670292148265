import { useMediaQuery, useTheme } from "@mui/material"
import NavbarCollapsed from "./NavbarCollapsed"
import NavbarFull from "./NavbarFull"

interface Props {
  isCollapsed?: boolean;
  isLoggedIn: boolean;
}

export default function Navbar({ isLoggedIn, isCollapsed = false }: Props) {
	const theme = useTheme()
	const upMd = useMediaQuery(theme.breakpoints.up("md"))

	return upMd ? <NavbarFull isLoggedIn={isLoggedIn} /> : <NavbarCollapsed isLoggedIn={isLoggedIn} />
}
