import React from "react"
import { Typography, Box } from "@mui/material"


export default () => {
    return (
        <Box
            sx={{
                padding: " 15px 30px",
                "*": {
                    textAlign: "justify",
                    fontSize: "14px",
                },
                p: {
                    textIndent: "35px",
                },
                li: {
                    textIndent: "35px",
                    textAlign: "justify",
                    fontSize: "14px",
                },
            }}
        >
            <Typography variant="h5" sx={{textAlign: "center"}}>
                ПУБЛИЧНАЯ ОФЕРТА
                &nbsp;
            </Typography>
            <Typography variant="h6" sx={{textAlign: "center"}}>
                на разработку Квиза&nbsp;
            </Typography>
            <Typography>Настоящий документ является публичной
                офертой <Typography variant="span" sx={{fontWeight: 600}}>Общества
                    с ограниченной ответственностью “ПЕНА”</Typography> , далее
                именуемому <Typography variant="span"
                                       sx={{fontWeight: 600}}>Исполнитель</Typography> и
                содержит все существенные условия по оказанию Пользователю
                Платформы “Pena.digital” , далее именуемому Заказчик,
                дополнительной услуги по созданию Квиза .
            </Typography>
            <Typography>Оферта адресована неопределённому кругу лиц согласно ч.
                2 ст. 437 Гражданского кодекса Российской Федерации (ГК РФ). В
                соответствии с ч. 3 ст. 438 ГК РФ акцепт оферты равносилен
                заключению договора на условиях, изложенных в оферте,
                следовательно, любое лицо, выполнившее акцепт настоящей оферты,
                считается заключившим договор с Исполнителем в порядке и на
                условиях, изложенных ниже.</Typography>
            <Typography variant="h6" sx={{textAlign: "center"}}>
                ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ
            </Typography>
            <Typography><Typography variant="span"
                                    sx={{fontWeight: 600}}>Исполнитель</Typography> –
                Общество с ограниченной ответственностью “ПЕНА”
                (ОГРН1182536017993 ИНН /КПП 2543126056/254301001)</Typography>
            <Typography><Typography variant="span"
                                    sx={{fontWeight: 600}}>Платформа</Typography> –
                инновационная, интерактивная информационная система, размещенная
                в сети Интернет по адресу Pena.digital, включая поддомены – hub,
                admin, services, storage, tempgen, links, docs, quiz, newerait,
                владельцем которой в соответствии с пп.17 п. 1 ст.2 Закона « Об
                информации, информационных технологиях и защите информации» №
                149-ФЗ , владельцем которой является Исполнитель
            </Typography>
            <Typography>Личный кабинет Пользователя – сервис, предоставляемый в
                разделе Платформы, доступный Пользователю после регистрации,
                через который осуществляется доступ к сервисам Платформы и
                взаимодействие с ними.</Typography>
            <Typography><Typography variant="span"
                                    sx={{fontWeight: 600}}>Заказчик</Typography> –
                физическое или юридическое лицо, Пользователь Платформы,
                заинтересованное в дополнительных услугах Исполнителя,
                совершившее акцепт данной оферты, являющееся таким образом
                Заказчиком его Услуг Исполнителя по разработке Квиза (см.ниже)
            </Typography>
            <Typography><Typography variant="span"
                                    sx={{fontWeight: 600}}>Квиз</Typography> -
                электронная анкета , позволяющая Заказчику создать маркетинговый
                опрос целевой аудитории (потребителей) , разместить его в Личном
                кабинете Заказчика и обеспечить сбор контактных данных
                опрашиваемых лиц. </Typography>
            <Typography><Typography variant="span"
                                    sx={{fontWeight: 600}}>Услуги</Typography> -совокупность
                технических и организационных мер , предпринимаемых Исполнителем
                для создания Квиза.
            </Typography>
            <Typography><Typography variant="span" sx={{fontWeight: 600}}>Акцепт
                оферты</Typography> – полное и безоговорочное принятие оферты
                путем осуществления Заказчиком действий, предусмотренных
                настоящей Офертой (конклюдентных действий). Акцепт оферты
                создает договор оферты.</Typography>
            <Typography variant="h5" sx={{textAlign: "center"}}>
                1. ПРЕДМЕТ ДОГОВОРА-ОФЕРТЫ
                &nbsp;
            </Typography>
            <Typography>1.1. Настоящий Договор-оферта заключается с целью
                предоставления Заказчику Услуг , сопутствующих использованию
                Платформы, Пользователем которой выступает Заказчик и
                результатам которых является создание Квиза.
            </Typography>
            <Typography>1.2. Заказчик соглашается с тем, что Услуги, оказываемые
                в соответствии с настоящим Договором-офертой осуществляются без
                каких-либо гарантий и обязательств со стороны Исполнителя в
                случае объективной технической невозможности их предоставления
                данных Услуг либо непредставления Заказчиком Исполнителю
                исходных данных, необходимых для разработки Квиза. </Typography>
            <Typography variant="h5" sx={{textAlign: "center"}}>
                2. ПОРЯДОК ЗАКЛЮЧЕНИЯ ДОГОВОРА-ОФЕРТЫ
                &nbsp;
            </Typography>
            <Typography>2.1.Заказчик направляет Исполнителю запрос на создание
                Квиза посредством электронного письма на почту team@pena.digital
                , либо заявки в Личном кабинете Заказчика. </Typography>
            <Typography>2.2.Исполнитель в лице его представителей связывается с
                Заказчиком с целью получения сведений, необходимых для оказания
                Услуг. </Typography>
            <Typography> 2.3. Заказчик вносит Исполнителю оплату, тем самым
                совершая Акцепт Оферты.</Typography>
            <Typography variant="h5" sx={{textAlign: "center"}}>
                3. СТОИМОСТЬ И СРОКИ ОКАЗАНИЯ УСЛУГ
                &nbsp;
            </Typography>
            <Typography>3.1.Стоимость услуг определяется Исполнителем в
                одностороннем порядке согласно тарифа на создание квиза в
                разделе https://hub.pena.digital/tariffs . Акцепт настоящей
                Оферты выражает бесспорное согласие Заказчика с ценами.
                Исполнитель вправе в любой момент изменить цены за оказание
                услуг в одностороннем порядке.</Typography>
            <Typography>3.2.Исполнитель обязуется создать Квиз в срок до 3
                (трех) рабочих дней, начало которого следует за днем внесения
                оплаты (предоплаты)</Typography>
            <Typography>3.3.Услуга Исполнителя считается оказанной с момента
                установки Квиза в Личном кабинете Заказчика.</Typography>
            <Typography variant="h5" sx={{textAlign: "center"}}>
                4. ПРАВА И ОБЯЗАННОСТИ СТОРОН
                &nbsp;
            </Typography>
            <Typography><Typography variant="span" sx={{fontWeight: 600}}>4.1.
                Права и обязанности Заказчика:</Typography></Typography>
            <Typography>а.)Заказчик обязуется соблюдать условия настоящего
                Соглашения;</Typography>
            <Typography>б)Заказчик обязуется не использовать Услугу для
                распространения информации, содержание которой противоречит
                нормам российского и международного права;</Typography>
            <Typography>в)Заказчик обязуется осуществлять оплату за оказанные
                Услуги в размере и в сроки, указанные на странице
                оплаты;</Typography>
            <Typography><Typography variant="span" sx={{fontWeight: 600}}>4.2.
                Права и обязанности Исполнителя:</Typography></Typography>
            <Typography>а)Исполнитель обязуется обеспечить конфиденциальность
                информации, предоставленной Заказчиком при заполнении
                регистрационной формы, за исключением случаев, предусмотренных
                законодательством;</Typography>
            <Typography>б)Исполнитель имеет право собирать, хранить, передавать
                партнерам и использовать в целях улучшения качества
                предоставляемой услуги информацию обо всех подключениях
                Заказчика, включая IP-адреса, содержимое файлов cookies и адреса
                запрошенных страниц, если такая информация была получена в ходе
                оказания Услуг;</Typography>
            <Typography>в)Исполнитель имеет право изменять цену Услуг путём
                опубликования новых цен в разделе
                https://hub.pena.digital/tariffs </Typography>
            <Typography variant="h5" sx={{textAlign: "center"}}>
                5. ОГРАНИЧЕНИЕ ОТВЕТСТВЕННОСТИ
                &nbsp;
            </Typography>
            <Typography>5.1. Исполнитель полностью освобождается от
                ответственности за полное или частичное неисполнение своих
                обязательств, или нанесенный ущерб другим веб-сайтам Заказчика
                по настоящему Договору-оферте, если неисполнение или
                ненадлежащее исполнение или нанесенный вред является следствием
                обстоятельств непреодолимой силы, носящих техногенный характер,
                а именно: авария (сбой в работе) в силовых электрических или
                компьютерных сетях или иных системах электрической связи,
                используемых для функционирования или других. неверных действий
                Заказчика, а равно в результате умышленных противоправных
                действий третьих лиц или каких либо других действий и лиц,
                направленных на нарушение функционирования Платформы и ее
                сервисов , а также веб-сайтов Заказчика.</Typography>
            <Typography>5.2. Исполнитель не несёт ответственности за работу
                веб-сайтов Заказчика, в том числе, если работа этих сайтов была
                остановлена, повреждена в связи с использованием
                Услуги.</Typography>
            <Typography>5.3. Исполнитель освобождается от ответственности в
                случае полной или частичной потери информации Заказчика, если
                такая потеря была вызвана умышленными или неосторожными
                действиями самого Заказчика</Typography>
            <Typography>5.4.Исполнитель не несёт ответственности за
                достоверность информационных или рекламных материалов или их
                соответствие желаниям или потребностям Заказчика, а также за
                любой ущерб или упущенную выгоду, как Заказчика, так и любых
                третьих лиц, даже если это стало результатом использования либо
                невозможности использования Услуг.</Typography>
            <Typography>5.5.Исполнитель не возмещает Заказчику и/или третьим
                лицам убытки, понесенные в связи с использованием и/или
                невозможностью использования Услуг, разглашения информации
                третьим лицам в связи с использованием Услуг, и другие убытки,
                прямо или косвенно связанные с использованием и/или
                невозможностью использования Услуг, включая (но не
                ограничиваясь) упущенную выгоду.</Typography>
            <Typography>5.6.Заказчик несёт ответственность за соответствие
                действующему законодательству информации, используемой
                Заказчиком с использованием Услуг.</Typography>
            <Typography>5.7.В случае обнаружения нарушений действующего
                законодательства и при наличии соответствующих распоряжений со
                стороны правоохранительных и иных уполномоченных государственных
                органов и должностных лиц, Исполнитель оставляет за собой право
                ограничить доступ Заказчика к Услуг, направив Заказчику
                соответствующее уведомление по e-mail или в письменном
                виде.</Typography>
            <Typography>5.8.Заказчик использует Услуги на свой собственный риск.
                Услуги предоставляется «как есть». Исполнитель не принимает на
                себя никакой ответственности, в том числе за соответствие Услуг
                целям Заказчика.</Typography>
            <Typography>5.9. Заказчик признает и соглашается с тем, что Услуги
                Платформы , иных веб-сайта https://quiz.pena.digital/, иных
                веб-сайтов Исполнителя, информационные материалы, публикуемые
                Исполнителем и его партнерами, компьютерные программы,
                используемые для оказания Услуг, дизайн охраняются внутренним
                российским и международным законодательством. Использование и
                цитирование указанных материалов допускается только со ссылкой
                на Исполнителя, его Платформу и
                https://quiz.pena.digital/</Typography>
            <Typography>5.10.Заказчик при сборе контактов с помощью Квиза
                обязуется выполнять требования законодательства страны, из
                которой приходят заявки, или в которой он находится. Заказчик
                обязан предусмотреть наличие в тесте или опросе
                нормативно-правовых документов, учитывающих особенности работы
                сервисов Платформы , веб сайта https://quiz.pena.digital/, а
                также юридическую специфику страны или региона. Также он обязан
                проверить соответствие особенностей работы сервиса перед
                использованием.</Typography>
            <Typography variant="h5" sx={{textAlign: "center"}}>
                6. ПОРЯДОК ВНЕСЕНИЯ ИЗМЕНЕНИЙ И ДОПОЛНЕНИЙ В ОФЕРТУ
                &nbsp;
            </Typography>
            <Typography>1. Исполнитель может вносить изменения и (или)
                дополнения в настоящую Оферту в одностороннем
                порядке.</Typography>
            <Typography>2. Изменения и (или) дополнения внесённые в Оферту,
                вступают в силу в день их опубликования на Платформе в разделе
                “ДОКУМЕНТЫ”. С этого же момента текст изменений и (или)
                дополнений в настоящую Оферту либо новая редакция Оферты
                считается доведенной Исполнителем до всеобщего
                сведения. </Typography>
            <Typography variant="h5" sx={{textAlign: "center"}}>
                7. СОГЛАСИЕ НА ОБРАБОТКУ ПЕРСОНАЛЬНЫХ ДАННЫХ
                &nbsp;
            </Typography>
            <Typography>В соответствии с требованиями Федерального закона от
                27.07.2006 №152-ФЗ «О персональных данных» Заказчик даёт своё
                согласие на обработку (сбор, систематизацию, накопление,
                хранение, уточнение (обновление, изменение), использование,
                распространение, передачу (включая трансграничную передачу),
                обезличивание, блокирование и уничтожение) его персональных
                данных, в том числе с использованием средств автоматизации.
                Такое согласие дается:</Typography>
            <ul type="disc">
                <li>в отношении любой информации, относящейся к Заказчику, включая: фамилию, имя, отчество; данные документа, удостоверяющего личность; год, месяц, дата и место рождения, адрес и другие сведения, предоставленные Заказчиком для использования Услуг, содержащиеся в заявлениях, письмах, соглашениях и иных документах;</li>
                <li>для целей исполнения Оферты, для целей продвижения иных услуг Исполнителя, совместных услуг Исполнителя и третьих лиц, продуктов (товаров, работ, услуг) третьих лиц;</li>
                <li>как Исполнителю, так и любым третьим лицам, которые в результате обработки персональных данных получили персональные данные Заказчика, агентам и уполномоченным лицам Исполнителя и указанных третьих лиц, а также компаниям (в объеме фамилия, имя, отчество, адреса и номера телефонов), осуществляющим почтовую рассылку по заявке Исполнителя. Право выбора указанных компаний предоставляется Заказчиком Исполнителю и дополнительного согласования не требует.</li>
            </ul>
            <Typography>Согласие предоставляется на неопределенный срок, соответственно, прекращение оказания Услуг не прекращает действие согласия. Заказчик осведомлен, что настоящее согласие может быть отозвано им путем направления Исполнителю заявления в простой письменной форме.</Typography>
            <Typography variant="h5" sx={{textAlign: "center"}}>
                8. ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ
                &nbsp;
            </Typography>
            <Typography>1. В случае, если какое-либо из условий настоящей Оферты потеряет юридическую силу, будет признано незаконным или будет исключено из настоящей Оферты, это не влечет недействительность остальных условий Оферты, которые сохранят юридическую силу и являются обязательными для исполнения всеми Сторонами.</Typography>
            <Typography>2. Все вопросы, не урегулированные настоящей Офертой, разрешаются в соответствии с действующим законодательством Российской Федерации, международными договорами, нормами международного права. </Typography>
        </Box>
    )
}
