import { Box, CssBaseline, ThemeProvider, useTheme, useMediaQuery } from "@mui/material"
import Section1 from "./Section1"
import Section2 from "./Section2"
import Section3 from "./Section3"
import Section4 from "./Section4"
import Section5 from "./Section5"
import FloatingSupportChat from "@root/components/FloatingSupportChat/FloatingSupportChat"
import Footer from "@root/components/Footer"
import Navbar from "@root/components/NavbarLanding/Navbar"
import { theme } from "@root/utils/theme"

export default function Landing() {
	const muiTheme = useTheme()
	const isTablet = useMediaQuery(muiTheme.breakpoints.down(900))

	return (
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<Box
				sx={{
					position: "relative",
					paddingTop: isTablet ? "51px" : "80px",
					color: "white",
				}}
			>
				<Navbar isLoggedIn={false} />
				<Section1 />
				<Section2 />
				<Section3 />
				<Section4 />
				<Section5 />
				<Footer />
				<FloatingSupportChat />
			</Box>
		</ThemeProvider>
	)
}
