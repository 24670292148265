import { Box } from "@mui/material"


export default function SendIcon() {

	return (
		<Box sx={{
			width: "24px",
			height: "24px",
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			flexShrink: 0,
		}}>
			<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M8.0625 7.80957L12 3.80957L15.9375 7.80957" stroke="#7E2AEA" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
				<path d="M12 14.4762V3.80957" stroke="#7E2AEA" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
				<path d="M20.25 14.4761V19.8094C20.25 20.0115 20.171 20.2053 20.0303 20.3482C19.8897 20.491 19.6989 20.5713 19.5 20.5713H4.5C4.30109 20.5713 4.11032 20.491 3.96967 20.3482C3.82902 20.2053 3.75 20.0115 3.75 19.8094V14.4761" stroke="#7E2AEA" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
			</svg>
		</Box>
	)
}