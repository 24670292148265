import { useNavigate, useSearchParams } from "react-router-dom";


//Раньше эта страничка благодарила за покупку и перенаправляла сталкера своей дорогой.
//Чтобы не мелькать хабом перед перенаправлением на другие домены - логика останется на этом отдельном url адресе

//Эта страничка ТОЛЬКО перенаправляет на нужный адрес. Процесс автопокупки после пополнения лежит на хуке useAutoPay
export default () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const userId = searchParams.get("userid");
  const from = searchParams.get("from") || "hub";
  const purpose = searchParams.get("purpose")
  const isCC = searchParams.get("cc")

  const host = window.location.hostname;
  const domain = (host.includes("s") ? "s" : "") + from;
  const pathname = from.includes("hub") ? "/tariffs" : "/list";

  let link = `https://${domain}.pena.digital${pathname}?purpose=${purpose}&userid=${userId}`

  console.log("isCC")
  if (isCC) link = link + "&cc=true"

  document.location.href = link
  return <></>;
};
