import makeRequest from "@api/makeRequest";
import type { GetDiscountsResponse } from "@root/model/discount";
import { useUserStore } from "@root/stores/user";
import { parseAxiosError } from "@root/utils/parse-error";
import { enqueueSnackbar } from "notistack";
import useSWR from "swr";

const API_URL = `${process.env.REACT_APP_DOMAIN}/price`;

export const getDiscounts = async (userId: string | null) => {
  if (userId === null) {
    return;
  }

  try {
    const discountsResponse = await makeRequest<never, GetDiscountsResponse>({
      method: "GET",
      url: `${API_URL}/discount/user/${userId}`,
      useToken: true,
    });

    return discountsResponse.Discounts;
  } catch (nativeError) {
    const [error] = parseAxiosError(nativeError);

    throw new Error(`Ошибка получения списка скидок. ${error}`);
  }
};

export const useDiscounts = (userId: string | null) => {
  const { data } = useSWR("discounts", () => getDiscounts(userId), {
    keepPreviousData: true,
    onError: (error) => {
      if (!(error instanceof Error)) return;

      enqueueSnackbar(error.message, { variant: "error" });
    },
  });

  return data;
};
