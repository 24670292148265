import {
	CircularProgress,
	List,
	ListItem,
	Box,
	useTheme,
	Pagination,
	Typography,
} from "@mui/material"
import TicketCard from "./TicketCard"
import { setTicketApiPage, useTicketStore } from "@root/stores/tickets"
import { Ticket } from "@frontend/kitui"
import { withErrorBoundary } from "react-error-boundary"
import { handleComponentError } from "@root/utils/handleComponentError"


function TicketList() {
	const theme = useTheme()
	const tickets = useTicketStore((state) => state.tickets)
	const ticketCount = useTicketStore((state) => state.ticketCount)
	const ticketApiPage = useTicketStore((state) => state.apiPage)
	const ticketsPerPage = useTicketStore((state) => state.ticketsPerPage)
	const fetchState = useTicketStore(state => state.ticketsFetchState)

	const sortedTickets = tickets
		.sort(sortTicketsByUpdateTime)
		.slice(
			ticketApiPage * ticketsPerPage,
			(ticketApiPage + 1) * ticketsPerPage
		)

	return (
		<Box
			sx={{
				display: "flex",
				gap: "40px",
				flexDirection: "column",
			}}
		>
			<List
				sx={{
					p: 0,
					minHeight: "120px",
					display: "flex",
					flexDirection: "column",
					gap: "40px",
					opacity: fetchState === "fetching" ? 0.4 : 1,
					transitionProperty: "opacity",
					transitionDuration: "200ms",
				}}
			>
				{sortedTickets.map((ticket) => (
					<ListItem key={ticket.id} disablePadding>
						<TicketCard ticket={ticket} />
					</ListItem>
				))}
				{fetchState === "fetching" && (
					<Box
						sx={{
							position: "absolute",
							width: "100%",
							height: "100%",
							minHeight: "120px",
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
						}}
					>
						<CircularProgress
							sx={{ color: theme.palette.purple.main }}
							size={60}
						/>
					</Box>
				)}
			</List>
			{ticketCount > ticketsPerPage && (
				<Pagination
					count={Math.ceil(ticketCount / ticketsPerPage)}
					page={ticketApiPage + 1}
					onChange={(e, value) => setTicketApiPage(value - 1)}
					sx={{ alignSelf: "center" }}
				/>
			)}
		</Box>
	)
}

function sortTicketsByUpdateTime(ticket1: Ticket, ticket2: Ticket) {
	const date1 = new Date(ticket1.updated_at).getTime()
	const date2 = new Date(ticket2.updated_at).getTime()
	return date2 - date1
}

export default withErrorBoundary(TicketList, {
	fallback: <Typography mt="8px" textAlign="center">Ошибка загрузки тикетов</Typography>,
	onError: handleComponentError,
})
