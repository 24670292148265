import { FetchState, TicketMessage } from "@frontend/kitui"
import { create } from "zustand"
import { devtools } from "zustand/middleware"


interface MessageStore {
    messages: TicketMessage[];
    apiPage: number;
    messagesPerPage: number;
    lastMessageId: string | undefined;
    isPreventAutoscroll: boolean;
    ticketMessageFetchState: FetchState;
}

export const useMessageStore = create<MessageStore>()(
	devtools(
		(set, get) => ({
			messages: [],
			messagesPerPage: 10,
			apiPage: 0,
			lastMessageId: undefined,
			isPreventAutoscroll: false,
			ticketMessageFetchState: "idle",
		}),
		{
			name: "Messages"
		}
	)
)

export const addOrUpdateMessages = (receivedMessages: TicketMessage[]) => {
	const messages = useMessageStore.getState().messages
	const messageIdToMessageMap: { [messageId: string]: TicketMessage; } = {};

	[...messages, ...receivedMessages].forEach(message => messageIdToMessageMap[message.id] = message)

	const sortedMessages = Object.values(messageIdToMessageMap).sort(sortMessagesByTime)

	useMessageStore.setState({
		messages: sortedMessages,
		lastMessageId: sortedMessages.at(-1)?.id,
	})
}

export const clearMessageState = () => useMessageStore.setState({
	messages: [],
	apiPage: 0,
	lastMessageId: undefined,
})

export const incrementMessageApiPage = () => {
	const state = useMessageStore.getState()

	useMessageStore.setState({ apiPage: state.apiPage + 1 })
}

export const setIsPreventAutoscroll = (isPreventAutoscroll: boolean) => useMessageStore.setState({ isPreventAutoscroll })

export const setTicketMessageFetchState = (ticketMessageFetchState: FetchState) => useMessageStore.setState({ ticketMessageFetchState })

function sortMessagesByTime(ticket1: TicketMessage, ticket2: TicketMessage) {
	const date1 = new Date(ticket1.created_at).getTime()
	const date2 = new Date(ticket2.created_at).getTime()
	return date1 - date2
}
