import { Typography, Box, useTheme, useMediaQuery, IconButton } from "@mui/material"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import { useParams } from "react-router-dom"
import SectionWrapper from "@components/SectionWrapper"
import SupportChat from "./SupportChat"
import CreateTicket from "./CreateTicket"
import TicketList from "./TicketList/TicketList"
import { useHistoryTracker } from "@root/utils/hooks/useHistoryTracker"

export default function Support() {
	const theme = useTheme()
	const upMd = useMediaQuery(theme.breakpoints.up("md"))
	const isMobile = useMediaQuery(theme.breakpoints.down(550))
	const isTablet = useMediaQuery(theme.breakpoints.down(1000))

	const ticketId = useParams().ticketId

	const handleCustomBackNavigation = useHistoryTracker()

	return (
		<SectionWrapper
			maxWidth="lg"
			sx={{
				pt: upMd ? "25px" : "20px",
				px: isTablet ? (upMd ? "40px" : "18px") : "20px",
				pb: upMd ? "82px" : "20px",
			}}
		>
			<Box
				sx={{
					mt: "20px",
					mb: "40px",
					display: "flex",
					gap: "10px",
				}}
			>
				<IconButton onClick={handleCustomBackNavigation} sx={{ p: 0, height: "28px", width: "28px", color: "black" }}>
					<ArrowBackIcon />
				</IconButton>
				<Typography
					sx={{
						fontSize: isMobile ? "24px" : "36px",
						fontWeight: "500",
					}}
				>
          Запрос в службу поддержки
				</Typography>
			</Box>
			{ticketId ? (
				<SupportChat />
			) : (
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						gap: upMd ? "40px" : "60px",
					}}
				>
					<CreateTicket />
					<TicketList />
				</Box>
			)}
		</SectionWrapper>
	)
}
