import { Box, SxProps, Theme, Typography, useMediaQuery, useTheme } from "@mui/material"
import { PenaLink } from "@frontend/kitui"
import { Link as RouterLink } from "react-router-dom"

interface Props {
  image?: string;
  headerText: string;
  text: string;
  linkHref: string;
  isHighlighted?: boolean;
  sx?: SxProps<Theme>;
}

export default function CardWithLink({ image, headerText, text, linkHref, isHighlighted = false, sx }: Props) {
	const theme = useTheme()

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				flexGrow: 1,
				alignItems: "start",
				p: "20px",
				maxWidth: "360px",
				backgroundColor: isHighlighted ? theme.palette.purple.main : "#434657",
				borderRadius: "12px",
				color: "white",
				boxShadow: `
                    0px 100px 309px rgba(37, 39, 52, 0.24),
                    0px 41.7776px 129.093px rgba(37, 39, 52, 0.172525),
                    0px 22.3363px 69.0192px rgba(37, 39, 52, 0.143066),
                    0px 12.5216px 38.6916px rgba(37, 39, 52, 0.12),
                    0px 6.6501px 20.5488px rgba(37, 39, 52, 0.0969343),
                    0px 2.76726px 8.55082px rgba(37, 39, 52, 0.0674749)
                `,
				...sx,
			}}
		>
			{image && (
				<img
					src={image}
					alt=""
					style={{
						objectFit: "contain",
						width: "100%",
						display: "block",
						marginTop: "calc(-18px - 11%)",
						pointerEvents: "none",
					}}
				/>
			)}
			<Typography variant="h5">{headerText}</Typography>
			<Typography mt="20px" mb="29px">
				{text}
			</Typography>
			<PenaLink
				component={RouterLink}
				to={linkHref}
				sx={{
					color: "white",
					textDecoration: "underline",
					mt: "auto",
					mb: "15px",
				}}
			>
        Подробнее
			</PenaLink>
		</Box>
	)
}
