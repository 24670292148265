import * as KIT from "@frontend/kitui";
import { Method, ResponseType, AxiosError } from "axios";
import { clearAuthToken } from "@frontend/kitui";
import { clearUserData } from "@root/stores/user";
import { clearCustomTariffs } from "@root/stores/customTariffs";
import { clearTickets } from "@root/stores/tickets";
import { redirect } from "react-router-dom";
import { setNotEnoughMoneyAmount } from "@stores/notEnoughMoneyAmount";

interface MakeRequest {
  method?: Method | undefined;
  url: string;
  body?: unknown;
  useToken?: boolean | undefined;
  contentType?: boolean | undefined;
  responseType?: ResponseType | undefined;
  signal?: AbortSignal | undefined;
  withCredentials?: boolean | undefined;
}
interface ErrorResponseData {
  message?: string;
}

async function makeRequest<TRequest = unknown, TResponse = unknown>(
  data: MakeRequest
): Promise<TResponse> {
  try {
    const response = await KIT.makeRequest<unknown>(data);

    return response as TResponse;
  } catch (e) {
    const error = e as AxiosError;
    if (
      error.response?.status === 400 &&
      (error.response?.data as ErrorResponseData)?.message ===
        "refreshToken is empty"
    ) {
      clearAuthToken();
      clearUserData();
      clearCustomTariffs();
      clearTickets();
      setNotEnoughMoneyAmount(0);
      redirect("/");
    }
    throw e;
  }
}
export default makeRequest;
