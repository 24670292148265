import { Box, Typography, useMediaQuery, useTheme } from "@mui/material"
import WideTemplCard from "@components/wideTemplCard"
import TemplCardPhonePink from "@components/templCardPhonePink"
import SectionWrapper from "@components/SectionWrapper"
import { PenaLink } from "@frontend/kitui"
import { Link as RouterLink } from "react-router-dom"
import CardWithLink from "@components/CardWithLink"
import card1Image from "@root/assets/landing/card1.png"
import card2Image from "@root/assets/landing/card2.png"
import card2ImageBig from "@root/assets/landing/card2big.png"

export default function Section2() {
	const theme = useTheme()
	const upMd = useMediaQuery(theme.breakpoints.up("md"))

	const isTablet = useMediaQuery(theme.breakpoints.down(1000))

	return (
		<SectionWrapper
			component="section"
			maxWidth="lg"
			outerContainerSx={{
				backgroundColor: theme.palette.bg.dark,
				mb: "-68px",
			}}
			sx={{
				pt: upMd ? "90px" : "50px",
				px: isTablet ? (upMd ? "40px" : "18px") : "20px",
			}}
		>
			<Box
				sx={{
					display: "flex",
					flexDirection: upMd ? "row" : "column",
					gap: isTablet ? "16.5%" : "3.5%",
				}}
			>
				<Typography
					variant="h4"
					sx={{
						flexGrow: 1,
						flexBasis: "31%",
						maxWidth: "50%",
					}}
				>
          Хватит сливать бюджет!
				</Typography>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						gap: "30px",
						alignItems: "start",
						flexGrow: 1,
						flexBasis: "65.5%",
						mt: upMd ? "10px" : "30px",
					}}
				>
					<Typography sx={{ fontSize: upMd ? "18px" : "17px" }} maxWidth="560px">
          Надежные сервисы, которые не подведут. Увеличение эффективности рекламной кампании и увеличение ROI и ROMI на десятки процентов при использовании всех наших продуктов.
					</Typography>
				</Box>
			</Box>
{/*			{upMd ? <WideTemplCard name="PenaDoc" desc="Сервис автоматизации, избавляющий от рутины */}
{/*в документообороте. 1 раз делаешь шаблон и используешь всю жизнь " light={false} sx={{ marginTop: "126px" }} /> : <TemplCardPhonePink />}*/}
			{upMd ? <WideTemplCard name="PenaQuiz" desc="Конструктор квиз опросов, для любых видов исследований и квиз маркетинга, арбитража трафика" image={card2ImageBig} light={false} sx={{ marginTop: "126px" }} />
				:
				<Box
					sx={{
						mt: upMd ? "93px" : "55px",
						display: "flex",
						flexWrap: "wrap",
						justifyContent: "space-evenly",
						columnGap: "40px",
						rowGap: "50px",
						backgroundColor: "\"#E6E6EB",
					}}
				>
					<CardWithLink
						headerText="PenaQuiz"
						text="Конструктор квиз опросов, для любых видов исследований и квиз маркетинга, арбитража трафика"
						linkHref="#"
						image={card2Image}
						isHighlighted={!upMd}
					/>
				</Box>}

      
		</SectionWrapper>
	)
}
