import { User } from "@frontend/kitui";
import makeRequest from "@api/makeRequest";
import { PatchUserRequest } from "@root/model/user";
import { parseAxiosError } from "@root/utils/parse-error";

const API_URL = `${process.env.REACT_APP_DOMAIN}/user`;

export const patchUser = async (
  user: PatchUserRequest
): Promise<[User | null, string?]> => {
  try {
    const patchUserResponse = await makeRequest<PatchUserRequest, User>({
      method: "PATCH",
      url: `${API_URL}/`,
      body: user,
      contentType: true,
      useToken: true,
      withCredentials: false,
    });

    return [patchUserResponse];
  } catch (nativeError) {
    const [error] = parseAxiosError(nativeError);

    return [null, `Не удалось изменить пользователя. ${error}`];
  }
};
