import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import Faq from "./pages/Faq/Faq";
import Wallet from "./pages/Wallet";
import Payment from "./pages/Payment/Payment";
import QuizPayment from "./pages/QuizPayment/QuizPayment";
import Support from "./pages/Support/Support";
import ChatImageNewWindow from "./pages/Support/ChatImageNewWindow";
import AccountSettings from "./pages/AccountSettings/AccountSettings";
import Landing from "./pages/Landing/Landing";
import Tariffs from "./pages/Tariffs/Tariffs";
import SigninDialog from "./pages/auth/Signin";
import SignupDialog from "./pages/auth/Signup";
import RecoverDialog from "./pages/auth/Recover";
import History from "./pages/History";
import Cart from "./pages/Cart/Cart";
import TariffPage from "./pages/Tariffs/TariffsPage";
import SavedTariffs from "./pages/SavedTariffs";
import PrivateRoute from "@root/utils/routes/ProtectedRoute";
import reportWebVitals from "./reportWebVitals";
import { SnackbarProvider, enqueueSnackbar } from "notistack";
import "./index.css";
import ProtectedLayout from "./components/ProtectedLayout";
import {
  clearUserData,
  setUser,
  setUserAccount,
  useUserStore,
  OriginalUserSquizAccount,
  setQuizUserAccount
} from "./stores/user";
import TariffConstructor from "./pages/TariffConstructor/TariffConstructor";
import {
  clearAuthToken,
  getMessageFromFetchError,
  useUserAccountFetcher,
  useUserFetcher,
} from "@frontend/kitui";
import { pdfjs } from "react-pdf";
import { ruRU } from "@mui/x-date-pickers/locales";
import { theme } from "./utils/theme";
import PPofData from "@root/docs/PPofData";
import Docs from "@root/docs/docs";
import Oferta from "@root/docs/content/oferta";
import OfertaQuizDevelop from "@root/docs/content/ofertaQuizDevelop";
import PrivacyPolicy from "@root/docs/content/PrivacyPolicy";
import RecoverPassword from "@root/pages/auth/RecoverPassword";
import OutdatedLink from "@root/pages/auth/OutdatedLink";
import { verify } from "./pages/AccountSettings/helper";
import AfterPay from "./pages/AfterPay";
import { PageNotFound } from "./pages/PageNotFound";
import { useNotEnoughMoneyAmount } from "@stores/notEnoughMoneyAmount"
import { usePipeSubscriber } from "./utils/hooks/usePipeSubscriber";
import { useAfterPay } from "./utils/hooks/useAutoPay";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { ModalRequestCreate } from "./pages/Tariffs/ModalRequestCreate";
import * as crutch from "./useUserAccountFetcher";
import { useCart } from "./utils/hooks/useCart";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const localeText =
  ruRU.components.MuiLocalizationProvider.defaultProps.localeText;
const App = () => {
  const location = useLocation();
  const userId = useUserStore(state => state.userId);
  const navigate = useNavigate();
  useUserFetcher({
    url: process.env.REACT_APP_DOMAIN + `/user/${userId}`,
    userId,
    onNewUser: setUser,
    onError: (error) => {
      const errorMessage = getMessageFromFetchError(error);
      if (errorMessage) {
        enqueueSnackbar(errorMessage);
        clearUserData();
        clearAuthToken();
      }
    },
  });

  useUserAccountFetcher({
    url: process.env.REACT_APP_DOMAIN + "/customer/v1.0.1/account",
    userId,
    onNewUserAccount: setUserAccount,
    onError: (error) => {
      const errorMessage = getMessageFromFetchError(error);
      if (errorMessage) {
        enqueueSnackbar(errorMessage);
        clearUserData();
        clearAuthToken();
        navigate("/signin");
      }
    },
  });

  crutch.useUserAccountFetcher<OriginalUserSquizAccount>({
    url: `${process.env.REACT_APP_DOMAIN}/squiz/account/get`,
    userId,
    onNewUserAccount: setQuizUserAccount,
    onError: (error) => {
      const errorMessage = getMessageFromFetchError(error);
      if (errorMessage) {
        enqueueSnackbar(errorMessage);
        clearUserData();
        clearAuthToken();
        navigate("/signin");
      }
    },
  });

  usePipeSubscriber()

  verify(userId);

  useAfterPay()

console.log("location")
console.log(location)
console.log("window.location")
console.log(window.location)

  if (location.state?.redirectTo)
    return (
      <Navigate
        to={location.state.redirectTo}
        replace
        state={{ backgroundLocation: location }}
      />
    );

  return (
    <>
      {location.state?.backgroundLocation && (
        <Routes>
          <Route path="/signin" element={<SigninDialog />} />
          <Route path="/signup" element={<SignupDialog />} />
          <Route path="/recover" element={<RecoverDialog />} />
          <Route path="/changepwd" element={<RecoverPassword />} />
          <Route path="/changepwd/expired" element={<OutdatedLink />} />
        </Routes>
      )}

      <ModalRequestCreate />

      <Routes location={location.state?.backgroundLocation || location}>
        <Route path="/" element={<Landing />} />
        <Route
          path="/signin"
          element={
            <Navigate to="/" replace state={{ redirectTo: "/signin" }} />
          }
        />
        <Route
          path="/signup"
          element={
            <Navigate to="/" replace state={{ redirectTo: "/signup" }} />
          }
        />
        <Route
          path="/recover"
          element={
            <Navigate to="/" replace state={{ redirectTo: "/recover" }} />
          }
        />
        <Route
          path="/changepwd"
          element={
            <Navigate
              to="/"
              replace
              state={{
                redirectTo: `/changepwd${location.search}`,
              }}
            />
          }
        />
        <Route
          path="/changepwd/expired"
          element={
            <Navigate
              to="/"
              replace
              state={{ redirectTo: "/changepwd/expired" }}
            />
          }
        />

        <Route path={"/image/:srcImage"} element={<ChatImageNewWindow />} />
        <Route element={<PrivateRoute />}>
          <Route element={<ProtectedLayout />}>
            <Route path="/tariffs" element={<Tariffs />} />
            <Route path="/tariffs/time" element={<TariffPage />} />
            <Route path="/tariffs/volume" element={<TariffPage />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/support" element={<Support />} />
            <Route path="/support/:ticketId" element={<Support />} />
            <Route path="/tariffconstructor" element={<TariffConstructor />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/wallet" element={<Wallet />} />
            <Route path="/payment" element={<Payment />} />
            <Route path="/settings" element={<AccountSettings />} />
            <Route path="/history" element={<History />} />
            <Route
              path="/tariffconstructor/savedtariffs"
              element={<SavedTariffs />}
            />
          </Route>
        </Route>
        <Route path="/ppdd" element={<PPofData />} />
        <Route path="/quizpayment" element={<QuizPayment />} />
        <Route element={<Docs />}>
          <Route path={"/docs/oferta"} element={<Oferta />} />
          <Route
            path="/docs/ofertaQuizDevelop"
            element={<OfertaQuizDevelop />}
          />
          <Route path={"/docs/privacy"} element={<PrivacyPolicy />} />
        </Route>
        <Route path="/afterpay" element={<AfterPay />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </>
  );
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  // <React.StrictMode>
  <ThemeProvider theme={theme}>
    <LocalizationProvider
      dateAdapter={AdapterMoment}
      adapterLocale="ru"
      localeText={localeText}
    >
      <BrowserRouter>
        <CssBaseline />
        <SnackbarProvider />
        <App />
      </BrowserRouter>
    </LocalizationProvider>
  </ThemeProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
