import { Button, ButtonProps, SxProps, Theme, useMediaQuery, useTheme } from "@mui/material"
import { MouseEventHandler, ReactNode } from "react"


interface Props {
    icon?: ReactNode;
    ButtonProps?: ButtonProps;
    children?: ReactNode;
    sx?: SxProps<Theme>;
    onClick?: MouseEventHandler<HTMLButtonElement>;
}

export default function UnderlinedButtonWithIcon({ ButtonProps, icon, children, sx, onClick }: Props) {
	const theme = useTheme()
	const upMd = useMediaQuery(theme.breakpoints.up("md"))

	return (
		<Button
			variant="text"
			startIcon={icon}
			disableTouchRipple
			sx={{
				p: 0,
				fontWeight: 400,
				fontSize: upMd ? "18px" : "16px",
				lineHeight: "21px",
				textDecorationLine: "underline",
				color: theme.palette.purple.main,
				textAlign: "start",
				textUnderlineOffset: "2px",
				"& .MuiButton-startIcon": {
					alignSelf: "start",
				},
				"&:hover": {
					backgroundColor: "rgb(0 0 0 / 0)",
				},
				...sx,
			}}
			onClick={onClick}
			{...ButtonProps}
		>
			{children}
		</Button>
	)
}
