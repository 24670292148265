import { Box, IconButton, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import SectionWrapper from "@components/SectionWrapper";
import { useCustomTariffsStore } from "@root/stores/customTariffs";
import ComplexHeader from "@root/components/ComplexHeader";
import CustomTariffCard from "./CustomTariffCard";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import TotalPrice from "@root/components/TotalPrice";
import { serviceNameByKey } from "@root/utils/serviceKeys";
import { useHistoryTracker } from "@root/utils/hooks/useHistoryTracker";
import { withErrorBoundary } from "react-error-boundary";
import { handleComponentError } from "@root/utils/handleComponentError";
import { useCart } from "@root/utils/hooks/useCart";

function TariffConstructor() {
    const theme = useTheme();
    const upMd = useMediaQuery(theme.breakpoints.up("md"));
    const isTablet = useMediaQuery(theme.breakpoints.down(1000));
    const cart = useCart();
    const customTariffs = useCustomTariffsStore((state) => state.privilegeByService);

    const basePrice = cart.priceBeforeDiscounts;
    const discountedPrice = cart.priceAfterDiscounts;

    const handleCustomBackNavigation = useHistoryTracker();

    return (
        <SectionWrapper
            maxWidth="lg"
            sx={{
                mt: upMd ? "25px" : "20px",
                px: isTablet ? (upMd ? "40px" : "18px") : "20px",
                mb: upMd ? "93px" : "48px",
            }}
        >
            <Box
                sx={{
                    mt: "20px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "80px",
                }}
            >
                {Object.entries(customTariffs).filter(([serviceKey]) => serviceKey === "squiz").map(([serviceKey, privileges], index) => {
                    return (
                        <Box key={serviceKey}>
                            <Box
                                sx={{
                                    mb: "40px",
                                    display: "flex",
                                    gap: "10px",
                                }}
                            >
                                {!upMd && index === 0 && (
                                    <IconButton
                                        onClick={handleCustomBackNavigation}
                                        sx={{
                                            p: 0,
                                            height: "28px",
                                            width: "28px",
                                            color: "black",
                                        }}
                                    >
                                        <ArrowBackIcon />
                                    </IconButton>
                                )}
                                <ComplexHeader text1="Мой тариф " text2={serviceNameByKey[serviceKey] === "Опросник" ? "PenaQuiz" : serviceNameByKey[serviceKey]} />
                            </Box>
                            <CustomTariffCard serviceKey={serviceKey} privileges={privileges} />
                        </Box>
                    );
                })}
            </Box>
            <Link
                to="/tariffconstructor/savedtariffs"
                style={{
                    display: "block",
                    marginTop: "50px",
                    textUnderlinePosition: "under",
                    color: theme.palette.purple.main,
                    textDecorationColor: theme.palette.purple.main,
                }}
            >
                Ваши сохраненные тарифы
            </Link>
            <TotalPrice priceBeforeDiscounts={basePrice} priceAfterDiscounts={discountedPrice} isConstructor={true} />
        </SectionWrapper>
    );
}

export default withErrorBoundary(TariffConstructor, {
    fallback: <Typography mt="8px" textAlign="center">Ошибка при отображении моих тарифов</Typography>,
    onError: handleComponentError,
});
