import { useTheme , Box} from "@mui/material"


interface Props {
    isExpanded: boolean;
    
}
export default function ExpandIcon({ isExpanded }: Props) {
	const theme = useTheme()

	return (
		<Box sx={{
			width: "33px",
			height: "33px",
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			flexShrink: 0,
		}}>
			<svg style={{ transform: isExpanded ? "rotate(180deg)" : undefined }} xmlns="http://www.w3.org/2000/svg" width="32" height="33" viewBox="0 0 32 33" fill="none">
				<path stroke={isExpanded ? theme.palette.orange.main : theme.palette.purple.main} d="M16 28.7949C22.6274 28.7949 28 23.4223 28 16.7949C28 10.1675 22.6274 4.79492 16 4.79492C9.37258 4.79492 4 10.1675 4 16.7949C4 23.4223 9.37258 28.7949 16 28.7949Z" strokeWidth="2" strokeMiterlimit="10" />
				<path stroke={isExpanded ? theme.palette.orange.main : theme.palette.purple.main} d="M20.5 15.2949L16 20.2949L11.5 15.2949" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
			</svg>
		</Box>
	)
}
