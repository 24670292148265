import { IconButton, useMediaQuery, useTheme } from "@mui/material";
import { Select } from "@root/components/Select";
import { Box } from "@mui/material";
import { Typography } from "@mui/material";
import { useState } from "react";
import SectionWrapper from "../../components/SectionWrapper";
import AccordionWrapper from "./AccordionWrapper";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Tabs } from "@root/components/Tabs";
import { useHistoryTracker } from "@root/utils/hooks/useHistoryTracker";

const subPages = ["Pena hub", "Шаблоны", "Опросы", "Ссылки", "Финансовые", "Юридические", "Юридические лица"];

export default function Faq() {
  const theme = useTheme();
  const upMd = useMediaQuery(theme.breakpoints.up("md"));
  const isMobile = useMediaQuery(theme.breakpoints.down(550));
  const isTablet = useMediaQuery(theme.breakpoints.down(1000));
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [selectedItem, setSelectedItem] = useState<number>(0);

  const handleCustomBackNavigation = useHistoryTracker();

  return (
    <SectionWrapper
      maxWidth="lg"
      sx={{
        mt: upMd ? "25px" : "20px",
        px: isTablet ? (isMobile ? "18px" : "40px") : "20px",
        mb: upMd ? "70px" : "37px",
      }}
    >
      <Box
        sx={{
          mt: "20px",
          mb: isTablet ? "38px" : "20px",
          display: "flex",
          alignItems: "center",
          gap: "10px",
        }}
      >
        {isMobile && (
          <IconButton onClick={handleCustomBackNavigation} sx={{ p: 0, height: "28px", width: "28px", color: "black" }}>
            <ArrowBackIcon />
          </IconButton>
        )}
        <Typography
          sx={{
            fontSize: isMobile ? "24px" : "36px",
            fontWeight: "500",
          }}
        >
          Вопросы и ответы
        </Typography>
      </Box>
      {/* <Box sx={{ marginBottom: isMobile ? "20px" : "0px" }}>
        {isMobile ? (
          <Select items={subPages} selectedItem={selectedItem} setSelectedItem={setSelectedItem} />
        ) : (
          <Tabs items={subPages} selectedItem={tabIndex} setSelectedItem={setTabIndex} />
        )}
      </Box> */}

      <TabPanel value={tabIndex} index={0} mt={upMd ? "42px" : "10px"}>
        <AccordionWrapper
          content={[
            [
              "Что такое бесплатный тариф?",
              "Это тариф который доступен каждому пользователю после регистрации, он позволяет пользоваться всеми нашими продуктами 14 дней бесплатно, что позволит в полной мере понять ценность наших сервисов.",
            ],
            [
              "Что такое PenaHub? Куда я попал?",
              "Это личный кабинет по управлению всеми продуктами экосистемы PenaHub, тут вы можете приобрести любой тип услуги, любого нашего продукта, пройти верификацию как НКО или Юр лицо и получить акт оказанных услуг, так же только в рамках PenaHub вам доступна кастомная настройка тарифа (Мой Тариф).",
            ],
            [
              "Как у вас формируется скидка?",
              `У нашей скидочной системы 4 уровня: \n 1 уровень. Тариф: чем объемнее тариф, тем больше скидка. \n2 уровень Продукт: если вы через “Мой тариф” берете больше одного вида тарифов,то в зависимости от итоговой суммы в рамках одного продукта, вы получаете увеличение итоговой скидки.\n3 уровень. Корзина: Чем больше товаров в корзине,не важно каких,то в зависимости от суммы получаете увеличенную скидку на разовую покупку.\n4 уровень. Лояльность: считается сумма покупок за всю историю вашего аккаунта и появляется скидка за лояльность,она действует на любые тарифы экосистемы независимо от остальных уровней скидки.`,
            ],
            [
              "Как у вас формируется скидка для НКО?",
              "Скидка для НКО всегда равна 60%, так как мы проводим политику социальной ответственности.",
            ],
            ["Где можно пройти верификацию как НКО или юр лицо?", "Вкладка профиль."],
            [
              "Хочу предложить крутую идею или предложение о сотрудничестве, как это сделать?",
              "Напишите в техническую поддержку, и ваша заявка будет 100% передана руководству",
            ],
            [
              "Хочу заказать у вас разработку, как это сделать?",
              "Заказать разработку полного цикла или отдельные услуги по web-разработке можно по адресу pena.digital",
            ],
          ]}
        />
      </TabPanel>
      <TabPanel value={tabIndex} index={1} mt={upMd ? "27px" : "10px"}>
        <AccordionWrapper
          content={[
            ["Placeholder", "Placeholder"],
            ["Placeholder", "Placeholder"],
            ["Placeholder", "Placeholder"],
            ["Placeholder", "Placeholder"],
            ["Placeholder", "Placeholder"],
            ["Placeholder", "Placeholder"],
          ]}
        />
      </TabPanel>
      <TabPanel value={tabIndex} index={2} mt={upMd ? "27px" : "10px"}>
        <AccordionWrapper
          content={[
            ["Placeholder", "Placeholder"],
            ["Placeholder", "Placeholder"],
            ["Placeholder", "Placeholder"],
            ["Placeholder", "Placeholder"],
            ["Placeholder", "Placeholder"],
            ["Placeholder", "Placeholder"],
          ]}
        />
      </TabPanel>
      <TabPanel value={tabIndex} index={3} mt={upMd ? "27px" : "10px"}>
        <AccordionWrapper
          content={[
            ["Placeholder", "Placeholder"],
            ["Placeholder", "Placeholder"],
            ["Placeholder", "Placeholder"],
            ["Placeholder", "Placeholder"],
            ["Placeholder", "Placeholder"],
            ["Placeholder", "Placeholder"],
          ]}
        />
      </TabPanel>
      <TabPanel value={tabIndex} index={4} mt={upMd ? "27px" : "10px"}>
        <AccordionWrapper
          content={[
            ["Placeholder", "Placeholder"],
            ["Placeholder", "Placeholder"],
            ["Placeholder", "Placeholder"],
            ["Placeholder", "Placeholder"],
            ["Placeholder", "Placeholder"],
            ["Placeholder", "Placeholder"],
          ]}
        />
      </TabPanel>
      <TabPanel value={tabIndex} index={5} mt={upMd ? "27px" : "10px"}>
        <AccordionWrapper
          content={[
            ["Placeholder", "Placeholder"],
            ["Placeholder", "Placeholder"],
            ["Placeholder", "Placeholder"],
            ["Placeholder", "Placeholder"],
            ["Placeholder", "Placeholder"],
            ["Placeholder", "Placeholder"],
          ]}
        />
      </TabPanel>
      <TabPanel value={tabIndex} index={6} mt={upMd ? "27px" : "10px"}>
        <AccordionWrapper
          content={[
            ["Placeholder", "Placeholder"],
            ["Placeholder", "Placeholder"],
            ["Placeholder", "Placeholder"],
            ["Placeholder", "Placeholder"],
            ["Placeholder", "Placeholder"],
            ["Placeholder", "Placeholder"],
          ]}
        />
      </TabPanel>
    </SectionWrapper>
  );
}

interface TabPanelProps {
  index: number;
  value: number;
  children?: React.ReactNode;
  mt: string;
}

function TabPanel({ index, value, children, mt }: TabPanelProps) {
  return (
    <Box hidden={index !== value} sx={{ mt }}>
      {children}
    </Box>
  );
}
