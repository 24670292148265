import { Box, Typography, useMediaQuery, useTheme } from "@mui/material"
import PromoCard from "./PromoCard"
import cardPagesBackground1 from "../../assets/card-background/card-pages-background1.png"
import cardPagesBackground2 from "../../assets/card-background/card-pages-background2.png"
import cardPagesBackground3 from "../../assets/card-background/card-pages-background3.png"
import cardPagesBackground4 from "../../assets/card-background/card-pages-background4.png"
import cardPagesBackground5 from "../../assets/card-background/card-pages-background5.png"
import cardPagesBackground6 from "../../assets/card-background/card-pages-background6.png"
import SectionWrapper from "@components/SectionWrapper"
import { PenaLink } from "@frontend/kitui"
import { Link as RouterLink } from "react-router-dom"

export default function Section3() {
	const theme = useTheme()
	const upMd = useMediaQuery(theme.breakpoints.up("md"))
	const downXs = useMediaQuery(theme.breakpoints.down("sm"))
	const isTablet = useMediaQuery(theme.breakpoints.down(1000))
	const isMobile = useMediaQuery(theme.breakpoints.down(600))

	return (
		<SectionWrapper
			component="section"
			maxWidth="lg"
			outerContainerSx={{
				backgroundColor: theme.palette.bg.main,
			}}
			sx={{
				display: "flex",
				pt: upMd ? "185px" : "155px",
				pb: upMd ? "90px" : "70px",
				px: isTablet ? (upMd ? "40px" : "18px") : "20px",
				// width: "fit-content",
				margin: "auto",
				flexDirection: isTablet ? "column" : "row",
				flexWrap: "wrap",
				rowGap: upMd ? "40px" : "30px",
				columnGap: "13.8%",
				justifyContent: "space-between",
			}}
		>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					alignItems: "start",
					maxWidth: "500px",
					width: isTablet ? undefined : "43.1%",
					mb: isTablet ? "20px" : "10px",
				}}
			>
				<Typography
					variant="h4"
					sx={{
						mb: upMd ? "67px" : "30px",
						width: isMobile ? "340px" : "562px"
					}}
				>
          Не знаете, подходят ли наши продукты для вашего бизнеса?
				</Typography>
				<Box
					sx={{
						mb: upMd ? "20px" : "30px",
					}}
				>
					<Typography>Зарегистрируйтесь и напишите в тех поддержку, </Typography>
					<Typography>наши опытные операторы проконсультируют </Typography>
					<Typography>вас по всем вопросам, в том числе и как мы можем увеличить эффективность вашего бизнеса</Typography>
				</Box>
			</Box>
			<PromoCard
				width={isTablet ? (upMd ? "87%" : "100%") : "43.1%"}
				headerText="Оплата в один клик"
				text="Формируй корзину сколько удобно, плати за минуту"
				textOrientation="column"
				small={downXs}
				backgroundSize={isTablet ? "contain" : "100% 300px"}
				backgroundImage={downXs ? cardPagesBackground6 : cardPagesBackground1}
				backgroundPosition={isTablet ? (isMobile ? "15% 72%" : "bottom right -1px") : "bottom right -165px"}
				sx={{
					alignSelf: "center",
					maxWidth: isTablet ? "100%" : "500px",
					marginRight: isTablet ? (upMd ? "120px" : "0") : "0",
				}}
				sxHeader={{maxWidth: "166px"}}
			/>
			<PromoCard
				width={isTablet ? (upMd ? "87%" : "100%") : "43.1%"}
				headerText="Собери свой тариф сам"
				text="Только вы знаете реальные потребности своего бизнеса, не нужно переплачивать"
				textOrientation="row"
				small={downXs}
				backgroundSize={"contain"}
				backgroundImage={downXs ? cardPagesBackground6 : cardPagesBackground2}
				backgroundPosition={isTablet ? (isMobile ? "15% 72%" : "bottom right -1px") : "bottom right 0px"}
				sx={{
					alignSelf: "center",
					marginLeft: isTablet ? (upMd ? "120px" : "0") : "0",
					mt: isTablet ? null : "-82px",
					maxWidth: isTablet ? "100%" : "500px",
				}}
				sxBoxText={{display: "flex", flexDirection: isMobile ? "column" : "row"}}
				sxHeader={{maxWidth: "190px"}}
				sxText={{maxWidth: "241px"}}
			/>
			<PromoCard
				width={isTablet ? (upMd ? "85%" : "100%") : "43.1%"}
				headerText="Все продукты в одном месте"
				text="Больше не нужно искать и покупать на разных сайтах"
				textOrientation="column"
				small={downXs}
				backgroundSize={isTablet ? "contain" : "100% 300px"}
				backgroundImage={downXs ? cardPagesBackground6 : cardPagesBackground3}
				backgroundPosition={isTablet ? (isMobile ? "15% 72%" : "bottom right -1px") : "bottom right -165px"}
				sx={{
					mt: isTablet ? null : "82px",
					alignSelf: "center",
					maxWidth: isTablet ? "100%" : "500px",
					marginRight: isTablet ? (upMd ? "100px" : "0") : "0",
				}}
				sxText={{maxWidth: "183px"}}
				sxHeader={{whiteSpace: "normal"}}
			/>
		</SectionWrapper>
	)
}
