import { Box, Button, Modal, Typography, useMediaQuery, useTheme } from "@mui/material"


interface Props {
    type: "email" | "password" | "all" | "";
    ok: () => void;
    cancel: () => void;
}

export const OnChangeLoginPassword = ({
    type,
    ok,
    cancel
}: Props) => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down(600))

    return (
        <Modal
            open={Boolean(type)}
        >
            <Box sx={{
                position: 'absolute' as 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                bgcolor: 'background.paper',
                borderRadius: "7px",
                boxShadow: 24,
                p: "20px 30px",
                width: isMobile ? "320px" : "487px"
            }}>
                <Typography sx={{ textAlign: "center" }} variant="h6" component="h2">
                    Вы уверены, что хотите изменить
                    {type === "all" || type === "email" ? " почту " : ""}
                    {type === "all" ? "и" : ""}
                    {type === "all" || type === "password" ? " пароль " : ""}
                    ?

                </Typography>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        mt: "35px",
                        flexWrap: isMobile ? "wrap" : "nowrap"
                    }}
                >
                    <Button sx={{ m: "15px" }} variant="pena-contained-dark" onClick={cancel}>отмена</Button>
                    <Button sx={{ m: "15px" }} variant="pena-contained-dark" onClick={ok}>изменить</Button>
                </Box>
            </Box>
        </Modal>
    )
}