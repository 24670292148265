export const deleteEmptyKeys = <T>(object: T): T => {
	const cleanObject = { ...object }

	for (const key of Object.keys(cleanObject as keyof T)) {
		if (!cleanObject[key as keyof T]) {
			delete cleanObject[key as keyof T]
		}
	}

	return cleanObject
}
