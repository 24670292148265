import { Box } from "@mui/material"

interface Props {
  color: string;
  bgcolor: string;
}

export default function CustomIcon({ color, bgcolor }: Props) {
	return (
		<Box
			sx={{
				bgcolor,
				height: "36px",
				width: "36px",
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				borderRadius: "6px",
			}}
		>
			<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
				<path
					d="M7.12695 10.5045C8.99091 10.5045 10.502 8.99351 10.502 7.12955C10.502 5.26559 8.99091 3.75455 7.12695 3.75455C5.26299 3.75455 3.75195 5.26559 3.75195 7.12955C3.75195 8.99351 5.26299 10.5045 7.12695 10.5045Z"
					stroke={color}
					strokeWidth="1.5"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M16.877 10.5045C18.7409 10.5045 20.252 8.99351 20.252 7.12955C20.252 5.26559 18.7409 3.75455 16.877 3.75455C15.013 3.75455 13.502 5.26559 13.502 7.12955C13.502 8.99351 15.013 10.5045 16.877 10.5045Z"
					stroke={color}
					strokeWidth="1.5"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M7.12695 20.2545C8.99091 20.2545 10.502 18.7435 10.502 16.8795C10.502 15.0156 8.99091 13.5045 7.12695 13.5045C5.26299 13.5045 3.75195 15.0156 3.75195 16.8795C3.75195 18.7435 5.26299 20.2545 7.12695 20.2545Z"
					stroke={color}
					strokeWidth="1.5"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M16.877 14.2545V19.5045"
					stroke={color}
					strokeWidth="1.5"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M19.502 16.8795H14.252"
					stroke={color}
					strokeWidth="1.5"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</svg>
		</Box>
	)
}
