import { useState, useRef, useEffect } from "react"
import {
	Box,
	Badge,
	Drawer,
	IconButton,
	useTheme,
	useMediaQuery,
} from "@mui/material"
import { Link } from "react-router-dom"

import SectionWrapper from "../SectionWrapper"
import { NotificationsModal } from "../NotificationsModal"
import { NavbarPanel } from "./NavbarPanel"

import { useUserStore } from "@root/stores/user"
import {
	useTicketStore
} from "@root/stores/tickets"

import { ReactComponent as CartIcon } from "@root/assets/Icons/cart.svg"
import { ReactComponent as BellIcon } from "@root/assets/Icons/bell.svg"

import DialogMenu from "./DialogMenu"
import PenaLogo from "../PenaLogo"
import CloseIcon from "../icons/CloseIcons"
import MenuIcon from "@mui/icons-material/Menu"

import type { ReactNode } from "react"

interface Props {
  children: ReactNode;
}

export default function NavbarCollapsed({ children }: Props) {
	const [open, setOpen] = useState(false)
	const [openNotificationsModal, setOpenNotificationsModal] =
    useState<boolean>(false)
	const bellRef = useRef<HTMLButtonElement | null>(null)
	const userAccount = useUserStore((state) => state.userAccount)
	const tickets = useTicketStore((state) => state.tickets)
	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down(550))

	const handleClose = () => {
		setOpen(false)
	}

	const notificationsCount = tickets.filter(
		({ user, top_message }) =>
			user !== top_message.user_id && top_message.shown.me !== 1
	).length

	useEffect(() => {
		if (open) {
			document.body.style.overflow = "hidden"

			return
		}

		document.body.style.overflow = "unset"
	}, [open])

	return (
		<SectionWrapper
			component="nav"
			maxWidth="lg"
			outerContainerSx={{
				backgroundColor: theme.palette.bg.main,
			}}
			sx={{ height: isMobile ? "51px" : "80px", padding: "0" }}
		>
			<Box sx={{ height: "100%" }}>
				<Box
					sx={{
						zIndex: 2,
						position: "fixed",
						top: 0,
						left: 0,
						width: "100%",
						display: "flex",
						columnGap: "10px",
						alignItems: "center",
						height: isMobile ? "51px" : "80px",
						padding: "0 18px",
						background: "#FFFFFF",
					}}
				>
					<IconButton
						onClick={() => setOpen((isOpened) => !isOpened)}
						sx={{
							p: 0,
							width: "30px",
							color: theme.palette.primary.main,
						}}
					>
						{open ? (
							<CloseIcon />
						) : (
							<MenuIcon sx={{ height: "30px", width: "30px" }} />
						)}
					</IconButton>
					{isMobile && (
						<>
							<Link to="/cart">
								<IconButton
									aria-label="cart"
									sx={{
										width: "30px",
										height: "30px",
										background: theme.palette.background.default,
										borderRadius: "6px",
										"&:hover": {
											background: theme.palette.purple.main,
											"& .MuiBadge-badge": {
												background: theme.palette.background.default,
												color: theme.palette.purple.main,
											},
											"& svg > path:nth-of-type(1)": { fill: "#FFFFFF" },
											"& svg > path:nth-of-type(2)": { fill: "#FFFFFF" },
											"& svg > path:nth-of-type(3)": { stroke: "#FFFFFF" },
										},
									}}
								>
									<Badge
										badgeContent={userAccount?.cart.length}
										sx={{
											"& .MuiBadge-badge": {
												display: userAccount?.cart.length ? "flex" : "none",
												color: "#FFFFFF",
												background: theme.palette.purple.main,
												transform: "scale(0.7) translate(50%, -50%)",
												top: "2px",
												right: "3px",
												fontWeight: 400,
											},
										}}
									>
										<CartIcon />
									</Badge>
								</IconButton>
							</Link>
							<IconButton
								ref={bellRef}
								onClick={() =>
									setOpenNotificationsModal((isOpened) => !isOpened)
								}
								aria-label="cart"
								sx={{
									width: "30px",
									height: "30px",
									background: theme.palette.background.default,
									borderRadius: "6px",
									"&:hover": {
										background: theme.palette.purple.main,
										"& .MuiBadge-badge": {
											background: theme.palette.background.default,
											color: theme.palette.purple.main,
										},
										"& svg > path:first-of-type": { fill: "#FFFFFF" },
										"& svg > path:last-child": { stroke: "#FFFFFF" },
									},
								}}
							>
								<Badge
									badgeContent={notificationsCount}
									sx={{
										"& .MuiBadge-badge": {
											display: notificationsCount ? "flex" : "none",
											color: "#FFFFFF",
											background: theme.palette.purple.main,
											transform: "scale(0.7) translate(50%, -50%)",
											top: "3px",
											right: "3px",
											fontWeight: 400,
										},
									}}
								>
									<BellIcon />
								</Badge>
							</IconButton>
						</>
					)}
					<NotificationsModal
						open={openNotificationsModal}
						setOpen={setOpenNotificationsModal}
						anchorElement={bellRef.current}
						notifications={tickets
							.filter(({ user, top_message }) => user !== top_message.user_id)
							.map((ticket) => ({
								text: "У вас новое сообщение от техподдержки",
								date: new Date(ticket.updated_at).toLocaleDateString(),
								url: `/support/${ticket.id}`,
								watched: ticket.top_message.shown.me === 1,
							}))}
					/>
					<Link to="/" style={{ marginLeft: isMobile ? "auto" : 0 }}>
						<PenaLogo width={isMobile ? 100 : 124} color="black" />
					</Link>
					{!isMobile && <NavbarPanel />}
				</Box>
			</Box>
			<Box sx={{ display: "flex", overflow: open ? "hidden" : "unset" }}>
				<Drawer
					sx={{
						width: 210,
						position: "relative",
						zIndex: open ? "none" : "-1",
						"& .MuiDrawer-paper": {
							position: "fixed",
							top: "0",
							width: 210,
							height: "100%",
							marginTop: isMobile ? "51px" : "80px",
						},
					}}
					variant="persistent"
					anchor="left"
					open={open}
				>
					<DialogMenu handleClose={handleClose} />
				</Drawer>
				<Box
					sx={{
						width: "100%",
						minWidth: "100%",
						minHeight: `calc(100vh - ${isMobile ? 51 : 80}px)`,
						flexGrow: 1,
						transition: theme.transitions.create("margin", {
							easing: theme.transitions.easing.sharp,
							duration: theme.transitions.duration.leavingScreen,
						}),
						marginLeft: `-${210}px`,
						...(open && {
							transition: theme.transitions.create("margin", {
								easing: theme.transitions.easing.easeOut,
								duration: theme.transitions.duration.enteringScreen,
							}),
							marginLeft: 0,
						}),
					}}
				>
					{children}
				</Box>
			</Box>
		</SectionWrapper>
	)
}
