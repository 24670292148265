import { Box, Button, Divider, Typography, useMediaQuery, useTheme } from "@mui/material"
import PenaLogo from "./PenaLogo"
import SectionWrapper from "./SectionWrapper"
import { Link } from "react-router-dom"

export default function Footer() {
	const theme = useTheme()
	const upMd = useMediaQuery(theme.breakpoints.up("md"))

	return (
		<SectionWrapper
			component="footer"
			maxWidth="lg"
			outerContainerSx={{
				backgroundColor: theme.palette.bg.dark,
			}}
			sx={{
				pt: upMd ? "100px" : "80px",
				pb: upMd ? "75px" : "24px",
				px: upMd ? "40px" : "20px",
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				gap: upMd ? "90px" : undefined,
			}}
		>
			<Box
				sx={{
					display: "flex",
					flexDirection: upMd ? "row" : "column",
					width: "100%",
					justifyContent: "space-between",
					alignItems: "center"
				}}
			>
				<Box
					sx={{
						flexBasis: upMd ? "98px" : "98px",
					}}
				>
					<PenaLogo width={124} color="white" />
				</Box>
				<Box
					sx={{
						display: "flex",
						flexDirection: upMd ? "row" : "column",
						flexWrap: "wrap",
						flexGrow: 1,
						flexShrink: 1,
						flexBasis: upMd ? "600px" : undefined,
						alignItems: upMd ? undefined : "start",
						mb: upMd ? undefined : "30px",
						mx: upMd ? "70px" : undefined,
						gap: upMd ? "10px 0" : "18px",
						justifyContent: upMd ? "space-between" : undefined,
						"& > *": {
							width: upMd ? "50%" : undefined,
						},
					}}
				>
					<Link to="https://hub.pena.digital/docs/oferta" target="_blank">
						<Button variant="pena-navitem-dark" sx={{whiteSpace: "break-spaces", textAlign: "start"}}>Оферта</Button>
					</Link>
					<Link to="https://hub.pena.digital/docs/privacy" target="_blank">
						<Button variant="pena-navitem-dark" sx={{whiteSpace: "break-spaces", textAlign: "start"}}>Политика конфиденциальности</Button>
					</Link>
					<Link to="https://hub.pena.digital/docs/ofertaQuizDevelop" target="_blank">
						<Button variant="pena-navitem-dark" sx={{whiteSpace: "break-spaces", textAlign: "start"}}>Оферта на услугу "Разработка Квиза"</Button>
					</Link>

				</Box>
				<Typography
					variant="body2"
					sx={{
						flexBasis: upMd ? "300px" : undefined,
						pr: "5%",
						mb: upMd ? undefined : "36px",
					}}
				>
          ООО Пена© 2023 {new Date().getFullYear() === 2023 ? "" : " - " + new Date().getFullYear()}
				</Typography>
			</Box>
		</SectionWrapper>
	)
}
