import { Privilege } from "@frontend/kitui";
import { create } from "zustand"
import { devtools } from "zustand/middleware"


interface PrivilegeStore {
    privileges: Privilege[];
}

const initialState: PrivilegeStore = {
	privileges: [],
}

const usePrivilegeStore = create<PrivilegeStore>()(
	devtools(
		(get, set) => initialState,
		{
			name: "Privileges",
			enabled: process.env.NODE_ENV === "development",
		}
	)
)

export const setPrivileges = (privileges: PrivilegeStore["privileges"]) => usePrivilegeStore.setState({ privileges })
