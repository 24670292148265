import { payCart } from "@root/api/cart";
import { calcTimeOfReadyPayCart, cancelPayCartProcess, setNotEnoughMoneyAmount, setSiteReadyPayCart, startPayCartProcess, useNotEnoughMoneyAmount } from "@root/stores/notEnoughMoneyAmount";
import { useUserStore } from "@root/stores/user";
import moment from "moment";
import { enqueueSnackbar } from "notistack";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { RSCOpen } from "@root/stores/requestSquizCreate";
import { useCartTariffs } from "./useCartTariffs";

export const useAfterPay = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const userId = useUserStore(store => store.userId)
  const userAccount = useUserStore(state => state.userAccount);
  const siteReadyPayCart = useNotEnoughMoneyAmount(state => state.siteReadyPayCart);
   const cartTariffs = useCartTariffs();
  console.log("cartTariffs")
  // console.log(cartTariffs)
  // const isCC = cartTariffs !== null && cartTariffs !== undefined && cartTariffs.length > 0 && cartTariffs.some(t => t.privileges[0].privilegeId === "quizManual")

  const purpose = searchParams.get("purpose");
  const from = searchParams.get("from");
  const action = searchParams.get("action");
  const paymentUserId = searchParams.get("userid");


  useEffect(() => {
    //Звёзды сошлись, будем оплачивать корзину
    if (from !== "quiz" && paymentUserId && paymentUserId === userId) {
      //Чистим url адрес от параметров. (Если нет action. Если есть - значит мы пришли из квиза)
      if (action === null) setSearchParams({}, { replace: true })
      // navigate(`/tariffs`, {
      //   replace: true,
      // });

      if (purpose === "paycart") {
        (async () => {

          //Проверяем можем ли мы оплатить корзину здесь и сейчас
          const [, payCartError] = await payCart();

          if (payCartError) {
            //Не получилось купить корзину. Ставим флаг, что сайт в состоянии ожидания пополнения счёта для оплаты
            startPayCartProcess(paymentUserId)
          } else {
            enqueueSnackbar("Товары успешно приобретены")
            cancelPayCartProcess()
            if (true) RSCOpen()
          }
        })()
      }
    }
  }, [purpose, from, paymentUserId])

  useEffect(() => {
    if (userId !== null && siteReadyPayCart !== null && siteReadyPayCart[userId] !== undefined) {
      const deadline = siteReadyPayCart[userId]
      if (calcTimeOfReadyPayCart(deadline)) {

        //Время ещё не вышло. У нас стоит флаг покупать корзину если время не вышло.
        (async () => {
          const [, payCartError] = await payCart();

          if (!payCartError) {
            enqueueSnackbar("Товары успешно приобретены")
            cancelPayCartProcess()
          }
        })()
      }
    }
  }, [userAccount, userId, siteReadyPayCart])
}