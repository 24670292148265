import { CustomPrivilegeWithAmount } from "@frontend/kitui";
import {
    Badge,
    Box,
    Button,
    Divider,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import {
    createAndSendTariff,
    useCustomTariffsStore,
} from "@root/stores/customTariffs";
import { updateTariffs } from "@root/stores/tariffs";
import { addTariffToCart } from "@root/stores/user";
import { currencyFormatter } from "@root/utils/currencyFormatter";
import { cardShadow } from "@root/utils/theme";
import { enqueueSnackbar } from "notistack";
import TariffPrivilegeSlider from "./TariffItem";

interface Props {
  serviceKey: string;
  privileges: CustomPrivilegeWithAmount[];
}

export default function CustomTariffCard({ serviceKey, privileges }: Props) {
	const theme = useTheme()
	const upMd = useMediaQuery(theme.breakpoints.up("md"))
	const summaryPriceBeforeDiscounts = useCustomTariffsStore(
		(state) => state.summaryPriceBeforeDiscountsMap
	)
	const summaryPriceAfterDiscounts = useCustomTariffsStore(
		(state) => state.summaryPriceAfterDiscountsMap
	)

	const priceBeforeDiscounts = summaryPriceBeforeDiscounts[serviceKey] ?? 0
	const priceAfterDiscounts = summaryPriceAfterDiscounts[serviceKey] ?? 0

	async function handleConfirmClick() {
		const [createTariffResponse, createTariffError] = await createAndSendTariff(
			serviceKey
		)

		if (createTariffError) {
			return enqueueSnackbar(createTariffError)
		}

		if (createTariffResponse) {
			updateTariffs([createTariffResponse])

			await addTariffToCart(createTariffResponse._id)

			enqueueSnackbar("Тариф добавлен в корзину")
		}
	}

	return (
		<Box
			sx={{
				backgroundColor: "white",
				width: "100%",
				display: "flex",
				flexDirection: upMd ? "row" : "column",
				borderRadius: "12px",
				boxShadow: cardShadow,
			}}
		>
			<Box
				sx={{
					p: "20px",
					pr: upMd ? "35px" : undefined,
					display: "flex",
					flexBasis: 0,
					flexGrow: 2.37,
					flexWrap: "wrap",
					flexDirection: "column",
					gap: "25px",
				}}
			>
				{privileges.map((privilege) => {
					if (privilege.privilegeId !== "quizManual") return (
					<TariffPrivilegeSlider key={privilege._id} privilege={privilege} />
				)})}
			</Box>
			{!upMd && (
				<Divider
					sx={{ mx: "20px", my: "10px", borderColor: theme.palette.gray.main }}
				/>
			)}
			<Box
				sx={{
					display: "flex",
					flexBasis: 0,
					flexGrow: 1,
					flexDirection: "column",
					justifyContent: "space-between",
					alignItems: "start",
					color: theme.palette.gray.dark,
					p: "20px",
					pl: upMd ? "33px" : undefined,
					borderLeft: upMd ? `1px solid ${theme.palette.gray.main}` : undefined,
				}}
			>
				<Box
					sx={{
						display: "flex",
						justifyContent: "space-between",
						gap: "15%",
						mb: "auto",
						width: "100%",
					}}
				>
					<Typography>
            Чем больше пакеты, тем дешевле подписки и опции{" "}
					</Typography>
				</Box>
				<Typography mb="20px" mt="18px">
          Сумма с учетом скидки
				</Typography>
        <Badge
 badgeContent={
                    priceBeforeDiscounts - priceAfterDiscounts ? (
                      <span
                        style={{
                          backgroundColor: "#ff4904",
                          color: "white",
                          fontSize: "14px",
                        }}
                      >
                        -
                        {((priceBeforeDiscounts - priceAfterDiscounts))?`${((priceBeforeDiscounts - priceAfterDiscounts)/priceBeforeDiscounts*100).toFixed(0)}%`:null}
                      </span>
                    ) : null
                  }
                  color="success"
                  sx={{
                    "& .MuiBadge-dot": {
                      backgroundColor: "#ff4904",
                      width: "10px",
                      height: "10px",
                    },
                    "& .MuiBadge-anchorOriginTopRightRectangle": {
                      backgroundColor: "#ff4904",
                      top: "5px",
                      right: "5px",
                    },

                    "& .MuiBadge-anchorOriginTopRightRectangular": {
                      backgroundColor: "#ff4904",
                      height: "31px",
                      padding: "5px 10px",
                      right: "-25px",
                    },
                  }}>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						gap: "20px",
						mb: "30px",
					}}
				>
					<Typography variant="price">
						{currencyFormatter.format(priceAfterDiscounts / 100)}
					</Typography>
          {(priceAfterDiscounts - priceBeforeDiscounts) ? 
					<Typography variant="oldPrice" pt="3px">
						{currencyFormatter.format(priceBeforeDiscounts / 100)}
					</Typography>:null
          }
				</Box>
        </Badge>
				<Button
					disabled={!priceBeforeDiscounts}
					variant="pena-contained-dark"
					onClick={handleConfirmClick}
				>
          Выбрать
				</Button>
			</Box>
		</Box>
	)
}
