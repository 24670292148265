import { devlog } from "@frontend/kitui"

import { verification } from "@root/api/verification"
import {
	setVerificationStatus,
	setVerificationType,
	setComment,
	setDocumentUrl,
} from "@root/stores/user"
import { VerificationStatus } from "@root/model/account"

import { DOCUMENT_TYPE_MAP } from "@root/constants/documentTypeMap"

import type { Verification } from "@root/model/auth"

const updateVerificationStatus = (verification: Verification) => {
	if (!verification) {
		setVerificationStatus(VerificationStatus.NOT_VERIFICATED)

		return
	}

	if (verification.accepted) {
		setVerificationStatus(VerificationStatus.VERIFICATED)

		return
	}

	if (
		(!verification.accepted && !verification.files?.length) ||
    (!verification.accepted && !verification.comment)
	) {
		setVerificationStatus(VerificationStatus.NOT_VERIFICATED)

		return
	}

	if (verification.files?.length && !verification.comment) {
		setVerificationStatus(VerificationStatus.WAITING)

		return
	}

	setVerificationStatus(VerificationStatus.NOT_VERIFICATED)
}

export const verify = async (id: string | null) => {
	if (id !== null && id.length > 0) {
	const [verificationResult, verificationError] = await verification(id)

	if (verificationError) {

		if (verificationError === "нет данных") return

		setVerificationStatus(VerificationStatus.NOT_VERIFICATED)

		devlog("Error fetching user", verificationError)

		return
	}

	if (verificationResult) {
		updateVerificationStatus(verificationResult)
		setVerificationType(verificationResult.status)
		setComment(verificationResult.comment)
		verificationResult.files.forEach((file) =>
			setDocumentUrl(DOCUMENT_TYPE_MAP[file.name], file.url)
		)

		devlog("User", verificationResult)
	}

	}
}
