import {
  Box,
  Typography,
  FormControl,
  InputBase,
  useMediaQuery,
  useTheme,
  Button,
} from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import { cardShadow } from "@root/utils/theme";
import { createTicket } from "@api/ticket";

export default function CreateTicket() {
  const theme = useTheme();
  const upMd = useMediaQuery(theme.breakpoints.up("md"));
  const navigate = useNavigate();
  const [ticketNameField, setTicketNameField] = useState<string>("");
  const [ticketBodyField, setTicketBodyField] = useState<string>("");

  async function handleCreateTicket() {
    if (!ticketBodyField || !ticketNameField) return;

    const [createTicketresult, createTicketerror] = await createTicket(
      ticketNameField,
      ticketBodyField
    );

    if (createTicketerror) {
      return enqueueSnackbar(createTicketerror);
    }

    navigate(`/support/${createTicketresult?.Ticket}`);
  }

  return (
    <Box
      sx={{
        backgroundColor: upMd ? "white" : undefined,
        display: "flex",
        flexDirection: upMd ? "row" : "column",
        maxHeight: upMd ? "443px" : undefined,
        borderRadius: "12px",
        p: upMd ? "20px" : undefined,
        gap: upMd ? "40px" : "20px",
        boxShadow: upMd ? cardShadow : undefined,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "start",
          flexDirection: "column",
          flexGrow: 1,
        }}
      >
        <Typography variant={upMd ? "h5" : "body2"} mb={upMd ? "30px" : "20px"}>
          Написать обращение
        </Typography>
        <FormControl sx={{ width: "100%" }}>
          <InputBase
            value={ticketNameField}
            fullWidth
            placeholder="Заголовок обращения"
            id="ticket-header"
            sx={{
              backgroundColor: theme.palette.background.default,
              border: `1px solid ${theme.palette.gray.main}`,
              borderRadius: "10px",
              p: 0,
            }}
            inputProps={{
              sx: {
                borderRadius: "10px",
                fontWeight: 400,
                fontSize: "16px",
                lineHeight: "19px",
                pt: "10px",
                pb: "10px",
                px: "19px",
              },
            }}
            onChange={(e) => setTicketNameField(e.target.value)}
          />
        </FormControl>
        <FormControl sx={{ width: "100%" }}>
          <Box
            sx={{
              overflow: "hidden",
              mt: "16px",
              backgroundColor: theme.palette.background.default,
              border: `1px solid ${theme.palette.gray.main}`,
              borderRadius: "10px",
            }}
          >
            <InputBase
              value={ticketBodyField}
              fullWidth
              placeholder="Текст обращения"
              id="ticket-body"
              multiline
              sx={{
                p: 0,
                height: "284px",
                alignItems: "start",
                overflow: "auto",
              }}
              inputProps={{
                sx: {
                  borderRadius: "10px",
                  fontWeight: 400,
                  fontSize: "16px",
                  lineHeight: "19px",
                  pt: "13px",
                  pb: "13px",
                  px: "19px",
                  height: "300px",
                },
              }}
              onChange={(e) => setTicketBodyField(e.target.value)}
            />
          </Box>
        </FormControl>
      </Box>
      <Box sx={{ alignSelf: upMd ? "end" : "start" }}>
        <Button
          variant="pena-contained-dark"
          onClick={handleCreateTicket}
          disabled={!ticketBodyField || !ticketNameField}
        >
          Отправить
        </Button>
      </Box>
    </Box>
  );
}
