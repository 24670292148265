import { Link, useNavigate } from "react-router-dom";
import { Box, Container, Typography, useTheme } from "@mui/material";
import Drawers from "../Drawers";
import PenaLogo from "../PenaLogo";
import Menu from "../Menu";
import { logout } from "@root/api/auth";
import { enqueueSnackbar } from "notistack";
import { clearUserData, useUserStore } from "@root/stores/user";
import {
  AvatarButton,
  LogoutButton,
  WalletButton,
  clearAuthToken,
} from "@frontend/kitui";
import { clearCustomTariffs } from "@root/stores/customTariffs";
import { currencyFormatter } from "@root/utils/currencyFormatter";
import { clearTickets } from "@root/stores/tickets";

import type { ReactNode } from "react";
import {setNotEnoughMoneyAmount} from "@stores/notEnoughMoneyAmount"

interface Props {
  children: ReactNode;
}

export default function NavbarFull({ children }: Props) {
  const theme = useTheme();
  const navigate = useNavigate();
  const cash = useUserStore((state) => state.userAccount?.wallet.cash) ?? 0;
  const initials = useUserStore((state) => state.initials);

  async function handleLogoutClick() {
    clearAuthToken();
    clearUserData();
    clearCustomTariffs();
    clearTickets();
    setNotEnoughMoneyAmount(0)
    navigate("/");

    const [_, logoutError] = await logout();

    if (logoutError) {
      return enqueueSnackbar(logoutError);
    }
  }

  return (
    <Box>
      <Container
        component="nav"
        disableGutters
        maxWidth={false}
        sx={{
          zIndex: 1,
          position: "fixed",
          top: "0",
          px: "16px",
          display: "flex",
          height: "80px",
          alignItems: "center",
          gap: "60px",
          bgcolor: "white",
          borderBottom: "1px solid #E3E3E3",
        }}
      >
        <Link to="/">
          <PenaLogo width={124} color="black" />
        </Link>
        <Menu />
        <Box sx={{ display: "flex", ml: "auto" }}>
          <Drawers />
          <WalletButton component={Link} to="/wallet" sx={{ ml: "20px" }} />
          <Box sx={{ ml: "8px", whiteSpace: "nowrap" }}>
            <Typography
              sx={{
                fontSize: "12px",
                lineHeight: "14px",
                color: theme.palette.gray.dark,
              }}
            >
              Мой баланс
            </Typography>
            <Typography variant="body2" color={theme.palette.purple.main}>
              {currencyFormatter.format(cash / 100)}
            </Typography>
          </Box>
          <AvatarButton component={Link} to="/settings" sx={{ ml: "27px" }}>
            {initials}
          </AvatarButton>
          <LogoutButton onClick={handleLogoutClick} sx={{ ml: "20px" }} />
        </Box>
      </Container>
      <Box>{children}</Box>
    </Box>
  );
}
