import { useEffect, useState } from "react";
import { ApologyPage } from "../ApologyPage";
import { redirect, useNavigate } from "react-router-dom";
import {
  clearAuthToken,
  getMessageFromFetchError,
  setAuthToken,
  useUserAccountFetcher,
  useUserFetcher,
  getAuthToken,
} from "@frontend/kitui";
import {
  clearUserData,
  setUser,
  setUserAccount,
  setUserId,
  useUserStore,
} from "@root/stores/user";
import { logout } from "@root/api/auth";
import { clearCustomTariffs } from "@root/stores/customTariffs";
import { clearTickets } from "@root/stores/tickets";
import { setNotEnoughMoneyAmount, startPayCartProcess } from "@stores/notEnoughMoneyAmount"

const params = new URLSearchParams(window.location.search);
let action = params.get("action");
let dif = params.get("dif");
let token = params.get("data");
let userId = params.get("userid");
let currentCC = params.get("cc");

let first = true;

export default function QuizPayment() {
  const navigate = useNavigate();
  const [message, setMessage] = useState("Идёт загрузка");
  const user = useUserStore((state) => state.user);
  const currentUserId = useUserStore((state) => state.user?._id);
  const fromSquiz = params.get("action");

  useEffect(() => {
    if (!first && userId !== null && currentUserId === userId) {
      if (fromSquiz === "squizpay") {
        setNotEnoughMoneyAmount(Number(params.get("dif") || 0));//Сколько нехватило на квизе
        startPayCartProcess(userId)
        let link = `/payment?action=${action}&dif=${dif}&user=${userId}`
        if (currentCC) link = link + "&cc=true"
        navigate(link, {
          replace: true,
        });
      }
    }
  },
    [currentUserId]
  );


  if (first) {
    navigate(`/quizpayment`, {
      replace: true,
    });
    try {
      first = false;

      if (user?._id === userId) {
        return;
      }

      if (action && dif && token) {
        (async () => {
          if (getAuthToken()) {
            clearAuthToken();
            clearUserData();
            clearCustomTariffs();
            clearTickets();
            setNotEnoughMoneyAmount(0)
            logout();
          }


          setUserId(userId);
          setAuthToken(token);

          return;
        })();
      } else {
        var link = document.createElement("a");
        link.href = "https://quiz.pena.digital/tariffs";
        document.body.appendChild(link);
        link.click();
      }
    } catch (e) {
      setMessage("Произошла ошибка");
      var link = document.createElement("a");
      link.href = "https://quiz.pena.digital/tariffs";
      document.body.appendChild(link);
      link.click();
    }
  }

  return <ApologyPage message={message} />;
}
