import { UserAccount } from "@frontend/kitui";
import makeRequest from "@api/makeRequest";
import Cart from "@root/pages/Cart/Cart";

import { parseAxiosError } from "@root/utils/parse-error";

const API_URL = `${process.env.REACT_APP_DOMAIN}/customer/v1.0.1`;

export const patchCart = async (
  tariffId: string
): Promise<[string[], string?]> => {
  try {
    const patchCartResponse = await makeRequest<never, UserAccount>({
      method: "PATCH",
      url: `${API_URL}/cart?id=${tariffId}`,
      useToken: true,
    });

    return [patchCartResponse.cart];
  } catch (nativeError) {
    let [error, status] = parseAxiosError(nativeError);
    if (status === 400 && error.indexOf("invalid id") !== -1)
      error = "Данный тариф более недоступен";

    return [[], `Не удалось добавить товар в корзину. ${error}`];
  }
};

export const deleteCart = async (
  tariffId: string
): Promise<[string[], string?]> => {
  try {
    const deleteCartResponse = await makeRequest<never, UserAccount>({
      method: "DELETE",
      url: `${API_URL}/cart?id=${tariffId}`,
      useToken: true,
    });

    return [deleteCartResponse.cart];
  } catch (nativeError) {
    const [error] = parseAxiosError(nativeError);

    return [[], `Не удалось удалить товар из корзины. ${error}`];
  }
};

export const payCart = async (): Promise<[UserAccount | null, string?]> => {
  try {
    const payCartResponse = await makeRequest<never, UserAccount>({
      method: "POST",
      url: `${API_URL}/cart/pay`,
      useToken: true,
    });

    return [payCartResponse];
  } catch (nativeError) {
    const [error] = parseAxiosError(nativeError);

    return [null, `Не удалось оплатить товар из корзины. ${error}`];
  }
};

export const patchCurrency = async (
  currency: string
): Promise<[UserAccount | null, string?]> => {
  try {
    const patchCurrencyResponse = await makeRequest<
      { currency: string },
      UserAccount
    >({
      method: "PATCH",
      url: `${API_URL}/wallet`,
      useToken: true,
      body: { currency },
    });

    return [patchCurrencyResponse];
  } catch (nativeError) {
    const [error] = parseAxiosError(nativeError);

    return [null, `Не удалось изменить валюту. ${error}`];
  }
};
