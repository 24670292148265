import { Box, Button, Dialog, IconButton, Typography } from "@mui/material"
import { enqueueSnackbar } from "notistack"
import CloseSmallIcon from "@root/components/icons/CloseSmallIcon"
import {
	closeDocumentsDialog,
	setDocument,
	useUserStore,
} from "@root/stores/user"
import DocumentUploadItem from "./DocumentUploadItem"
import DocumentItem from "./DocumentItem"
import { VerificationStatus } from "@root/model/account"
import { sendDocuments, updateDocuments } from "@root/api/verification"
import { readFile } from "@root/utils/readFile"
import { deleteEmptyKeys } from "@root/utils/deleteEmptyKeys"
import { verify } from "../helper"
import { theme } from "@root/utils/theme"

const dialogContainerStyle = {
	height: "100%",
	overflowY: "scroll",
}

export default function JuridicalDocumentsDialog() {
	const isOpen = useUserStore((state) => state.isDocumentsDialogOpen)
	const verificationStatus = useUserStore((state) => state.verificationStatus)
	const documents = useUserStore((state) => state.documents)//загруженные юзером файлы
	const documentsUrl = useUserStore((state) => state.documentsUrl)//ссылки с бекенда
	const userId = useUserStore((state) => state.userId) ?? ""

	const sendUploadedDocuments = async () => {

		if (documents["ИНН"].file && documents["Устав"].file && !documentsUrl["ИНН"] && !documentsUrl["Устав"]) {
			closeDocumentsDialog()
			//Пользователь заполнил все поля и на беке пусто
			const inn = await readFile(documents["ИНН"].file, "binary")
			const rule = await readFile(documents["Устав"].file, "binary")

			const [_, sendDocumentsError] = await sendDocuments({
				status: "org",
				inn,
				rule,
			})

			if (sendDocumentsError) {
				enqueueSnackbar(sendDocumentsError)
				return
			}
			if (_ === "OK") {
				enqueueSnackbar("Информация доставлена")
			}
    
			setDocument("ИНН", null)
			setDocument("Устав", null)
    
			await verify(userId)
		} else { //Пользователь заполнил не все, или на беке что-то есть
			if ((documents["ИНН"].file || documents["Устав"].file) && (documentsUrl["ИНН"] || documentsUrl["Устав"])) { //минимум 1 поле заполнено на фронте и минимум 1 поле на беке записано
				closeDocumentsDialog()
				const inn = documents["ИНН"].file
					? await readFile(documents["ИНН"].file, "binary")
					: undefined
				const rule = documents["Устав"].file
					? await readFile(documents["Устав"].file, "binary")
					: undefined

				const [_, updateDocumentsError] = await updateDocuments(
					deleteEmptyKeys({
						status: "org",
						inn,
						rule,
					})
				)

				if (updateDocumentsError) {
					enqueueSnackbar(updateDocumentsError)

					return
				}
				if (_ === "OK") {
					enqueueSnackbar("Информация доставлена")
				}
      
				setDocument("ИНН", null)
				setDocument("Устав", null)
      
				await verify(userId)
			}
		}
	}

	const disbutt = () => {
		if (documents["ИНН"].file && documents["Устав"].file && !documentsUrl["ИНН"] && !documentsUrl["Устав"]) { //post
			//все поля заполнены и на беке пусто
			return false
		} else {//patch
			if (documents["ИНН"].file || documents["Устав"].file) {
				//минимум одно поле заполнено
				return false
			}
			return true
		}
	}

	const documentElements =
    verificationStatus === VerificationStatus.VERIFICATED ? (
    	<>
    		<DocumentItem
    			text="1. Скан ИНН организации НКО (выписка из ЕГЮРЛ)"
    			documentUrl={documentsUrl["ИНН"]}
				keyName="inn"
				
    		/>
    		<DocumentItem
    			text="2. Устав организации"
    			documentUrl={documentsUrl["Устав"]}
				keyName="rule"
    		/>
    	</>
    ) : (
    	<>
    		<DocumentUploadItem
    			document={documents["ИНН"]}
    			documentUrl={documentsUrl["ИНН"]}
    			text="1. Скан ИНН организации (выписка из ЕГЮРЛ)"
    			accept="application/pdf"
    			onFileChange={(e) => setDocument("ИНН", e.target?.files?.[0] || null)}
    		/>
    		<DocumentUploadItem
    			document={documents["Устав"]}
    			documentUrl={documentsUrl["Устав"]}
    			text="2. Устав организации"
    			accept="application/pdf"
    			onFileChange={(e) =>
    				setDocument("Устав", e.target?.files?.[0] || null)
    			}
    		/>
    	</>
    )

	return (
		<Dialog
			open={isOpen}
			onClose={closeDocumentsDialog}
			PaperProps={{
				sx: {
					width: "600px",
					maxWidth: "600px",
					backgroundColor: "white",
					position: "relative",
					display: "flex",
					flexDirection: "column",
					gap: "20px",
					borderRadius: "12px",
					boxShadow: "none",
					overflowY: "hidden",
				},
			}}
			slotProps={{
				backdrop: { style: { backgroundColor: "rgb(0 0 0 / 0.7)" } },
			}}
		>
			<IconButton
				onClick={closeDocumentsDialog}
				sx={{
					position: "absolute",
					right: "7px",
					top: "7px",
				}}
			>
				<CloseSmallIcon />
			</IconButton>
			<Box sx={{ p: "40px", pb: "70px", overflowY: "scroll" }}>
				<Typography variant="h5" lineHeight="100%">
					{verificationStatus === VerificationStatus.VERIFICATED
						? "Ваши документы"
						: "Загрузите документы"}
				</Typography>
				<Typography
					sx={{
						fontWeight: 400,
						fontSize: "16px",
						lineHeight: "100%",
						mt: "12px",
					}}
				>
          для верификации юридических лиц в формате PDF
				</Typography>
				{Boolean(!documentsUrl["ИНН"] && !documentsUrl["Устав"]) && <Typography
					sx={{
						fontWeight: 400,
						fontSize: "16px",
						lineHeight: "100%",
						mt: "12px",
						color: theme.palette.purple.main
					}}
				>
          Пожалуйста, заполните все поля!
				</Typography>}
        
				<Box sx={dialogContainerStyle}>
					<Box
						sx={{
							maxHeight: "200px",
							mt: "30px",
							display: "flex",
							flexDirection: "column",
							gap: "25px",
						}}
					>
						{documentElements}
					</Box>
				</Box>
			</Box>
			{verificationStatus === VerificationStatus.NOT_VERIFICATED && (
				<Button
					sx={{ position: "absolute", bottom: "20px", right: "20px" }}
					onClick={sendUploadedDocuments}
					variant="pena-contained-dark"
					disabled={disbutt()}
				>
          Отправить
				</Button>
			)}
		</Dialog>
	)
}
