import type { Attachment } from "@root/model/attachment"
import { transliterate } from 'transliteration';

type KeyValue<T = string> = {
  [key: string]: T;
};

const convertBinaryStringToFile = ({
	name,
	content,
	mimetype,
}: Attachment): File => {
	const bytes = new Uint8Array(content.length)

	for (let index = 0; index < content.length; index += 1) {
		bytes[index] = content.charCodeAt(index)
	}

	const blob = new Blob([bytes], { type: mimetype })

	return new File([blob], name, { type: blob.type })
}

export const jsonToFormdata = (
	json: KeyValue<string | Attachment>
): FormData => {
	const formData = new FormData()
	if (json.egrule !== undefined) delete json.egrule
	console.log("json")
	console.log(json)

	for (const key in json) {
		if (!key) continue

		const value = json[key]
		if (typeof value !== "string") value.name = transliterate(value.name.replace(/\s/g, '_'))

		if (typeof value !== "string") {
			formData.append(key, convertBinaryStringToFile(value))

			continue
		}

		formData.append(key, value)
	}
console.log("formData")
console.log(formData)
	return formData
}
