import { Menu, Box, useTheme } from "@mui/material";
import { Link } from "react-router-dom";

import type { MenuItem } from "./Menu";

type DropDownMenuProps = {
  anchorElement: HTMLElement | null,
  setAnchorElement: (element: null) => void,
  items: MenuItem[],
};

export const DropDownMenu = ({ anchorElement, setAnchorElement, items = [] }: DropDownMenuProps) => {
  const theme = useTheme();

  return (
    <Menu
      anchorEl={anchorElement}
      open={!!anchorElement}
      onClose={() => setAnchorElement(null)}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      sx={{
        "&.MuiMenu-root.MuiModal-root": { zIndex: "1" },
        "& .MuiPaper-root.MuiMenu-paper": {
          padding: "0 10px",
          borderRadius: "8px",
          background: theme.palette.bg.dark,
          margin: "0",
          width: "600px",
        },
      }}
    >
      {items.map(({ name, url }, index) => (
        <Box
          sx={{
            "& a:hover": { background: theme.palette.bg.main },
          }}
        >
          <Link
            key={name}
            style={{
              fontSize: "16px",
              lineHeight: "20px",
              display: "block",
              textDecoration: "none",
              color: theme.palette.common.white,
              padding: "20px 10px 0",
              borderRadius: "8px",
              transition: ".2s",
            }}
            to={url}
            target="_blank"
          >
            {name}
            <span
              style={{
                width: "100%",
                display: "block",
                paddingBottom: "20px",
                borderBottom: index === items.length - 1 ? "none" : `1px solid ${theme.palette.bg.main}`,
              }}
            />
          </Link>
        </Box>
      ))}
    </Menu>
  );
};
