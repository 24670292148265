import {
    Box,
    Button,
    Collapse,
    TextField,
    useMediaQuery,
    useTheme
} from "@mui/material";
import SimpleArrowDown from "@root/components/icons/SimpleArrowDown";
import { useState } from "react";


interface Props {
    fieldValue: string;
    onFieldChange: (value: string) => void;
    onPromocodeApply: () => void;
}

export default function CollapsiblePromocodeField({ fieldValue, onFieldChange, onPromocodeApply }: Props) {
    const theme = useTheme();
    const upSm = useMediaQuery(theme.breakpoints.up("sm"));
    const [isPromocodeFieldOpen, setIsPromocodeFieldOpen] = useState<boolean>(false);

    return (
        <Box sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
        }}>
            <Button
                variant="pena-text"
                onClick={() => setIsPromocodeFieldOpen(p => !p)}
                sx={{
                    fontWeight: 400,
                    fontSize: "18px",
                    lineHeight: "21.33px",
                    textUnderlineOffset: "1px",
                    ".MuiButton-endIcon": {
                        mr: 0,
                        ml: "15px",
                    },
                }}
                endIcon={
                    <SimpleArrowDown sx={{
                        transform: isPromocodeFieldOpen ? "scaleY(-1)" : undefined,
                        transition: "transform 0.3s ease",
                    }} />
                }
            >У меня есть промокод</Button>
            <Collapse
                in={isPromocodeFieldOpen}
                sx={{
                    width: "100%",
                }}
            >
                <Box
                    sx={{
                        width: upSm ? "407px" : "100%",
                        mt: "20px",
                        display: "flex",
                        flexDirection: upSm ? "row" : "column",
                    }}
                >
                    <TextField
                        fullWidth
                        placeholder="Введите промокод"
                        value={fieldValue}
                        onChange={e => onFieldChange(e.target.value)}
                        sx={{
                            height: "48px",
                            "& .MuiInputBase-root": {
                                height: "100%",
                                borderRadius: "8px",
                                border: "1px solid #9A9AAF",
                                ...(upSm ? {
                                    borderTopRightRadius: 0,
                                    borderBottomRightRadius: 0,
                                } : {
                                    borderBottomLeftRadius: 0,
                                    borderBottomRightRadius: 0,
                                }),
                            },
                        }}
                        inputProps={{
                            sx: {
                                color: "#7E2AEA",
                                backgroundColor: "#7E2AEA1A",
                                height: "100%",
                                boxSizing: "border-box",
                                borderRadius: "8px",
                                py: 0,
                                ...(upSm ? {
                                    borderTopRightRadius: 0,
                                    borderBottomRightRadius: 0,
                                } : {
                                    borderBottomLeftRadius: 0,
                                    borderBottomRightRadius: 0,
                                }),
                            },
                        }}
                    />
                    <Button
                        variant="pena-contained-dark"
                        onClick={onPromocodeApply}
                        sx={{
                            px: "25px",
                            minWidth: 0,
                            ...(upSm ? {
                                borderTopLeftRadius: 0,
                                borderBottomLeftRadius: 0,
                            } : {
                                borderTopLeftRadius: 0,
                                borderTopRightRadius: 0,
                            }),
                        }}
                    >Применить</Button>
                </Box>
            </Collapse>
        </Box>
    );
}
