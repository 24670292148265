import { Ticket, UserAccount, UserName, getAuthToken, useSSESubscription } from "@frontend/kitui"
import { setCart, setNewNames, setUserStatus, setWallet, useUserStore } from "@root/stores/user";
import { useSSETab } from "./useSSETab";
import { cancelPayCartProcess } from "@root/stores/notEnoughMoneyAmount";

type Ping = [{ event: "ping" }]

type SomeChange = [{
  "id": UserAccount["_id"],
  "userId": UserAccount["userId"],
  "cart": UserAccount["cart"],
  "wallet": {
    "cash": UserAccount["wallet"]["cash"],
    "currency": UserAccount["wallet"]["currency"],
    "spent": UserAccount["wallet"]["spent"],
    "purchasesAmount": UserAccount["wallet"]["purchasesAmount"],
    "money": UserAccount["wallet"]["money"],
    "lastPaymentId": string;
  },
  "name": UserName,
  "status": UserAccount["status"],
  "isDeleted": UserAccount["isDeleted"],
  "createdAt": UserAccount["createdAt"];
  "updatedAt": UserAccount["updatedAt"];
  "from": string;
  "partner": string;
}]

type PipeMessage = Ping | SomeChange

export const usePipeSubscriber = () => {
  const token = getAuthToken();
  const userId = useUserStore((state) => state.userId);
  const { isActiveSSETab, updateSSEValue } = useSSETab<Ticket[]>("pipe");

  useSSESubscription({
    enabled: Boolean(token) && Boolean(userId) && isActiveSSETab,
    url:
      process.env.REACT_APP_DOMAIN +
      `/customer/v1.0.1/account/pipe?Authorization=${token}`,
    onNewData: (data) => {
      let message = data[0] as PipeMessage
      updateSSEValue(message)

      //Пропускаем пингование
      if ('event' in message && message.event === "ping") return


      if ('cart' in message) {
        setCart(message.cart as string[])
        cancelPayCartProcess()
      }
      else if ('wallet' in message) {
        setWallet(message.wallet as UserAccount["wallet"])
        cancelPayCartProcess()
      }
      else if ('name' in message) {
        setNewNames(message.name as UserName)
      }
      else if ('status' in message) {
        setUserStatus(message.status as UserAccount["status"])
      }
    },
    marker: "pipe",
  });
}