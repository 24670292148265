import { useState } from "react";
import {
  Box,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import SectionWrapper from "@root/components/SectionWrapper";
import { Select } from "@root/components/Select";
import { Tabs } from "@root/components/Tabs";

import AccordionWrapper from "./AccordionWrapper";
import { useHistoryTracker } from "@root/utils/hooks/useHistoryTracker";
import { ErrorBoundary } from "react-error-boundary";
import { handleComponentError } from "@root/utils/handleComponentError";
import { useHistoryStore } from "@root/stores/history";
import { enqueueSnackbar } from "notistack";
import {
  HistoryRecord,
  HistoryRecord2,
  sendReportById,
} from "@root/api/history";
import AccordionWrapper2 from "./AccordionWrapper2";

const subPages = ["Платежи"];
// const subPages = ["Платежи", "Покупки тарифов", "Окончания тарифов"]

export default function History() {
  const [selectedItem, setSelectedItem] = useState<number>(0);

  const theme = useTheme();
  const upMd = useMediaQuery(theme.breakpoints.up("md"));
  const isMobile = useMediaQuery(theme.breakpoints.down(600));
  const isTablet = useMediaQuery(theme.breakpoints.down(1000));
  const historyData = useHistoryStore((state) => state.history);
  const handleCustomBackNavigation = useHistoryTracker();

  const extractDateFromString = (tariffName: string) => {
    const dateMatch = tariffName.match(/\d{4}-\d{2}-\d{2}/);
    return dateMatch ? dateMatch[0] : "";
  };

  async function handleHistoryResponse(tariffId: string) {
    const [, sendReportError] = await sendReportById(tariffId);

    if (sendReportError) {
      return enqueueSnackbar(sendReportError);
    }

    enqueueSnackbar("Запрос отправлен");
  }

  return (
    <SectionWrapper
      maxWidth="lg"
      sx={{
        mt: upMd ? "25px" : "20px",
        mb: upMd ? "70px" : "37px",
        px: isTablet ? (isTablet ? "18px" : "40px") : "20px",
      }}
    >
      <Box
        sx={{
          mt: "20px",
          mb: isTablet ? "38px" : "20px",
          display: "flex",
          alignItems: "center",
          gap: "10px",
        }}
      >
        {isMobile && (
          <IconButton
            onClick={handleCustomBackNavigation}
            sx={{ p: 0, height: "28px", width: "28px", color: "black" }}
          >
            <ArrowBackIcon />
          </IconButton>
        )}
        <Typography
          sx={{
            fontSize: isMobile ? "24px" : "36px",
            fontWeight: "500",
          }}
        >
          История
        </Typography>
      </Box>
      {isMobile ? (
        <Select
          items={subPages}
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
        />
      ) : (
        <Tabs
          items={subPages}
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
        />
      )}
      <ErrorBoundary
        fallback={<Typography mt="8px">Ошибка загрузки истории</Typography>}
        onError={handleComponentError}
      >
        {historyData?.length === 0 && (
          <Typography textAlign="center">Нет данных</Typography>
        )}
        {/* Для ненормального rawDetails */}
        {historyData
          ?.filter((e): e is HistoryRecord => {
            e.createdAt = extractDateFromString(e.createdAt);
            return (
              !e.isDeleted &&
              e.key === "payCart" &&
              Array.isArray(e.rawDetails) &&
              Array.isArray(e.rawDetails[0].Value)
            );
          })
          .map((e, index) => {
            return (
              <Box key={index} sx={{ mt: index === 0 ? "27px" : "0px" }}>
                <AccordionWrapper
                  first={index === 0}
                  last={index === historyData?.length - 1}
                  content={(e as HistoryRecord).rawDetails}
                  mainId={(e as HistoryRecord).id}
                  key={e.id}
                  createdAt={e.createdAt}
                  onClickMail={(event: any) => {
                    event.stopPropagation();
                    handleHistoryResponse(e.id);
                  }}
                />
              </Box>
            );
          })}
        {/* Для нормального rawDetails */}
        {historyData
          ?.filter((e): e is HistoryRecord2 => {
            e.createdAt = extractDateFromString(e.createdAt);
            return (
              !e.isDeleted &&
              e.key === "payCart" &&
              !Array.isArray(e.rawDetails) &&
              !!e.rawDetails.tariffs[0]
            );
          })
          .map((e, index) => {
            return (
              <Box key={index} sx={{ mt: index === 0 ? "27px" : "0px" }}>
                <AccordionWrapper2
                  key={e.id}
                  first={index === 0}
                  last={index === historyData?.length - 1}
                  mainId={(e as HistoryRecord2).id}
                  createdAt={e.createdAt}
                  tariff={e.rawDetails.tariffs[0]}
                  price={e.rawDetails.price / 100}
                />
              </Box>
            );
          })}
      </ErrorBoundary>
    </SectionWrapper>
  );
}
