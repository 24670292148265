import { Box } from "@mui/material"


export default function EyeIcon() {

	return (
		<Box sx={{
			width: "24px",
			height: "24px",
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			flexShrink: 0,
		}}>
			<svg width="22" height="15" viewBox="0 0 22 15" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M10.9502 14C16.3669 14 19.6169 9.66667 20.7002 7.5C19.6169 5.33333 16.3669 1 10.9502 1C5.53353 1 2.28353 5.33333 1.2002 7.5C2.64464 9.66667 5.53353 14 10.9502 14Z" stroke="#7E2AEA" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
				<circle cx="10.9495" cy="7.50033" r="3.58333" stroke="#7E2AEA" strokeWidth="1.5" />
			</svg>
		</Box>
	)
}