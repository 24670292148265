import { Box } from "@mui/material"


interface Props {
    isUp?: boolean;
}
export default function CircleDoubleDown({ isUp = false }: Props) {

	return (
		<Box sx={{
			width: "32px",
			height: "32px",
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			flexShrink: 0,
			transform: isUp ? "scale(1, -1)" : undefined,
		}}>
			<svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M16.9004 4C10.273 4 4.90039 9.37258 4.90039 16C4.90039 22.6274 10.273 28 16.9004 28C23.5278 28 28.9004 22.6274 28.9004 16C28.9004 9.37258 23.5278 4 16.9004 4Z" stroke="#252734" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
				<path d="M12.9004 21L16.9004 17L20.9004 21" stroke="#252734" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
				<path d="M12.9004 14L16.9004 10L20.9004 14" stroke="#252734" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
			</svg>
		</Box>
	)
}