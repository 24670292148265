import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useState } from "react";
import ExpandIcon from "./icons/ExpandIcon";
import type { ReactNode } from "react";

interface Props {
  header: ReactNode;
  text: ReactNode;
  divide?: boolean;
  price?: number;
  last?: boolean;
  first?: boolean;
}

export default function CustomAccordion({ header, text, divide = false, price, last, first }: Props) {
  const theme = useTheme();
  const upMd = useMediaQuery(theme.breakpoints.up("md")); //900
  const upXs = useMediaQuery(theme.breakpoints.up("xs")); //300
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  return (
    <Box
      sx={{
        backgroundColor: "white",
        borderTopLeftRadius: first ? "12px" : "0",
        borderTopRightRadius: first ? "12px" : "0",
        borderBottomLeftRadius: last ? "12px" : "0",
        borderBottomRightRadius: last ? "12px" : "0",
        borderBottom: `1px solid ${theme.palette.gray.main}`,

        ...(last && { borderBottom: "none" }),
      }}
    >
      <Box
        onClick={() => setIsExpanded((prev) => !prev)}
        sx={{
          minHeight: "72px",
          px: "20px",
          display: "flex",
          alignItems: "stretch",
          justifyContent: "space-between",
          cursor: "pointer",
          userSelect: "none",
          rowGap: "10px",
          flexDirection: upXs ? undefined : "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            fontSize: upMd ? undefined : "16px",
            lineHeight: upMd ? undefined : "19px",
            p: !upMd ? "17px 0 20px" : undefined,
            fontWeight: 500,
            color: theme.palette.gray.dark,
            px: 0,
          }}
        >
          {header}
        </Box>
        <Box
          sx={{
            pl: "20px",
            width: "52px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderLeft: divide ? "1px solid #000000" : "none",
          }}
        >
          <ExpandIcon isExpanded={isExpanded} />
        </Box>
      </Box>
      {isExpanded && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            px: "20px",
            py: upMd ? "25px" : undefined,
            pt: upMd ? undefined : "15px",
            pb: upMd ? undefined : "25px",
            backgroundColor: "#F1F2F6",
          }}
        >
          <Typography
            sx={{
              fontSize: upMd ? undefined : "16px",
              lineHeight: upMd ? undefined : "19px",
              color: theme.palette.gray.dark,
            }}
          >
            {text}
          </Typography>
          <Typography sx={{ display: price ? "block" : "none", fontSize: "18px", mr: "120px" }}>
            {price} руб.
          </Typography>
        </Box>
      )}
    </Box>
  );
}
