import { FC } from "react"
import { enqueueSnackbar } from "notistack"

import { Box, Button, Typography, useMediaQuery, useTheme } from "@mui/material"

import { addTariffToCart } from "@root/stores/user"
import { Privilege, getMessageFromFetchError } from "@frontend/kitui"
import CustomSaveAccordion from "../../components/CustomSaveAccordion"

interface Props {
  privileges: Privilege[];
  tariffId: string;
  createdAt: string | undefined;
  first?: boolean;
  last?: boolean;
}

const SaveWrapper: FC<Props> = ({ privileges, createdAt, tariffId, first, last }) => {
	const theme = useTheme()
	const upMd = useMediaQuery(theme.breakpoints.up("md"))
	const upSm = useMediaQuery(theme.breakpoints.up("sm"))
	const isMobile = useMediaQuery(theme.breakpoints.down(560))

	function handleTariffItemClick(tariffId: string) {
		addTariffToCart(tariffId)
			.then(() => {
				enqueueSnackbar("Тариф добавлен в корзину")
			})
			.catch((error) => {
				const message = getMessageFromFetchError(error)
				if (message) enqueueSnackbar(message)
			})
	}

	const extractDateFromString = (tariffName: string) => {
		const dateMatch = tariffName.match(/\d{4}-\d{2}-\d{2}/)
		return dateMatch ? dateMatch[0] : null
	}

	const tariffPrice = privileges.reduce((acc, { price, amount }) => {
		return acc + amount * price
	}, 0)

	return (
		<Box
			sx={{
				borderRadius: "12px",
			}}
		>
			<CustomSaveAccordion
				first={first}
				last={last}
				divide
				header={
					<Box
						sx={{
							paddingTop: isMobile ? "10px" : null,
							paddingBottom: isMobile ? "10px" : null,
							display: "flex",
							width: "100%",
							alignItems: upSm ? "center" : undefined,
							justifyContent: "space-between",
							flexDirection: upSm ? undefined : "column",
							gap: upMd ? "40px" : "10px",
						}}
					>
						<Typography
							sx={{
								width: "100%",
								maxWidth: "200px",
								fontSize: upMd ? "18px" : "16px",
								lineHeight: upMd ? undefined : "19px",
								fontWeight: 500,
								color: theme.palette.purple.main,
							}}
						>
							{extractDateFromString(createdAt ? createdAt : "time")}
						</Typography>
						<Typography
							sx={{
								width: "100%",
								maxWidth: "200px",
								fontSize: upMd ? "18px" : "16px",
								lineHeight: upMd ? undefined : "19px",
								fontWeight: 500,
								px: 0,
							}}
						>
              				Мой тариф
						</Typography>
						<Typography
							sx={{
								width: "100%",
								maxWidth: "120px",
								textAlign: "right",
								display: upMd ? undefined : "none",
								fontSize: upMd ? "18px" : "16px",
								lineHeight: upMd ? undefined : "19px",
								fontWeight: 500,
								px: 0,
							}}
						>
							{new Intl.NumberFormat("ru-RU").format(tariffPrice / 100)} руб.
						</Typography>
						<Button
							onClick={(event) => {
								event.stopPropagation()
								handleTariffItemClick(tariffId)
							}}
							variant="pena-contained-white1"
							sx={{
								mr: "25px",
								width: "180px",
							}}
						>
              Купить
						</Button>
					</Box>
				}
				privilege={privileges.map(({ description, price, amount, privilegeId }) => (
					<Box
						key={privilegeId}
						sx={{
							display: "flex",
							justifyContent: "space-between",
							px: "20px",
							py: upMd ? "25px" : undefined,
							pt: upMd ? undefined : "15px",
							pb: upMd ? undefined : "25px",
							backgroundColor: "#F1F2F6",
						}}
					>
						<Typography
							sx={{
								fontSize: upMd ? undefined : "16px",
								lineHeight: upMd ? undefined : "19px",
								color: theme.palette.gray.dark,
							}}
						>
							{description}
						</Typography>
						<Typography sx={{ display: price ? "block" : "none", fontSize: "18px", mr: "120px" }}>
							{new Intl.NumberFormat("ru-RU").format((amount * price) / 100)} руб.
						</Typography>
					</Box>
				))}
			/>
		</Box>
	)
}

export default SaveWrapper
