import {
	Popover,
	List,
	ListItem,
	Typography,
	useTheme,
	useMediaQuery,
} from "@mui/material"
import { Link } from "react-router-dom"

type Notification = {
  text: string;
  date: string;
  url: string;
  watched?: boolean;
};

type NotificationsModalProps = {
  open: boolean;
  setOpen: (isOpen: boolean) => void;
  anchorElement: Element | null;
  notifications: Notification[];
};

export const NotificationsModal = ({
	open,
	setOpen,
	anchorElement,
	notifications,
}: NotificationsModalProps) => {
	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down(650))

	return (
		<Popover
			open={open}
			anchorEl={anchorElement}
			onClose={() => setOpen(false)}
			anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
			transformOrigin={{ vertical: "top", horizontal: "right" }}
			sx={{
				"& .MuiPopover-paper": {
					maxWidth: isMobile ? "calc(100vw - 30px)" : 600,
					width: "100%",
					maxHeight: "310px",
					borderRadius: "8px",
					boxShadow:
            "0px 3px 18px rgba(49, 28, 77, 0.1), 0px 3px 34px rgba(49, 28, 77, 0.15)",
				},
			}}
		>
			<List sx={{ width: "100%", padding: "5px" }}>
				{notifications.length ? (
					<>
						{notifications.map(({ text, date, url, watched = true }) => (
							<Link
								key={url}
								to={url}
								onClick={() => setOpen(false)}
								style={{
									textDecoration: "none",
									color: "inherit",
								}}
							>
								<ListItem
									key={text + date}
									sx={{
										display: "flex",
										alignItems: isMobile ? "normal" : "center",
										justifyContent: "space-between",
										flexDirection: isMobile ? "column-reverse" : "unset",
										borderBottom: "1px solid #F2F3F7",
										padding: "20px 10px",
										background: watched ? "none" : "#F0F0F6",
										borderRadius: watched ? "0" : "8px",
										"&:first-of-type": {
											borderTop: "1px solid #F2F3F7",
										},
									}}
								>
									<Typography
										sx={{
											position: "relative",
											fontSize: "16px",
											lineHeight: "19px",
											paddingLeft: watched ? "0" : "35px",
											fontWeight: watched ? "normal" : "bold",
											"&::before": {
												content: "\"\"",
												display: watched ? "none" : "block",
												position: "absolute",
												left: "10px",
												top: isMobile ? "5px" : "50%",
												transform: isMobile ? "none" : "translateY(-50%)",
												height: "8px",
												width: "8px",
												borderRadius: "50%",
												background: theme.palette.purple.main,
											},
										}}
									>
										{text}
									</Typography>
									<Typography
										sx={{
											fontSize: "14px",
											lineHeight: "19px",
											color: "#9A9AAF",
											fontWeight: watched ? "normal" : "bold",
											paddingLeft: isMobile ? (watched ? "0" : "35px") : "0",
											marginBottom: isMobile ? "5px" : "0",
										}}
									>
										{date}
									</Typography>
								</ListItem>
							</Link>
						))}
					</>
				) : (
					<Typography
						sx={{
							textAlign: "center",
							color: "#9A9AAF",
							padding: "30px 0",
						}}
					>
            Нет оповещений
					</Typography>
				)}
			</List>
		</Popover>
	)
}
