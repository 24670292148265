import { useMediaQuery, useTheme } from "@mui/material"
import SectionWrapper from "@components/SectionWrapper"
import Infographics from "./Infographics"

export default function Section4() {
	const theme = useTheme()
	const upMd = useMediaQuery(theme.breakpoints.up("md"))
	const isTablet = useMediaQuery(theme.breakpoints.down(1000))

	const itemsFlex = upMd ? " 0 0 31.1%" : "0 0 25.1%"

	return (
		<SectionWrapper
			component="section"
			maxWidth="lg"
			outerContainerSx={{
				backgroundColor: theme.palette.bg.dark,
			}}
			sx={{
				display: "flex",
				justifyContent: isTablet ? "space-around" : "space-between",
				columnGap: upMd ? undefined : "20px",
				flexWrap: "wrap",

				rowGap: isTablet ? "85px" : "65px",
				pt: upMd ? "90px" : "70px",
				pb: upMd ? "112px" : "76px",
			}}
		>
			<Infographics flex={itemsFlex} bigText="4" text="года на рынке" />
			<Infographics flex={itemsFlex} bigText="+103 %" text="к эффективности вашего бизнеса" />
			<Infographics flex={itemsFlex} bigText="5 467" text="клиентов с нами" />
			<Infographics flex={itemsFlex} bigText="1" text="минута на подключение" />
			<Infographics flex={itemsFlex} bigText="24/7" text="с вами служба поддержки" />
			<Infographics flex={itemsFlex} bigText="500" text="рублей в месяц минимальный тариф" />
		</SectionWrapper>
	)
}
