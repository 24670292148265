import makeRequest from "@api/makeRequest";
import { SendPaymentRequest, SendPaymentResponse } from "@root/model/wallet";
import { parseAxiosError } from "@root/utils/parse-error";

const isStaging = (() => {
  const host = window.location.hostname;
  return host.includes("s") ? "s" : "";
})();
const isLocalhost = (() => {
  const host = window.location.hostname;
  return host.includes("localhost");
})();

const API_URL = `${process.env.REACT_APP_DOMAIN}/customer/v1.0.1`;

interface PaymentBody {
  type: string;
  amount: number;
}

export const sendPayment = async ({
  userId,
  body,
  fromSquiz,
  paymentPurpose,
  cc
}: {
  userId: string;
  body: PaymentBody;
  fromSquiz: boolean;
  paymentPurpose: "paycart" | "replenishwallet";
  cc?: boolean
}): Promise<[SendPaymentResponse | null, string?]> => {

  let returnLink = `${isLocalhost ? "localhost:3000" : `https://${isStaging}hub.pena.digital`}/afterpay?from=${
    fromSquiz ? "quiz" : "hub"
  }&purpose=${paymentPurpose}&userid=${userId}`
  if (cc) returnLink = returnLink + "&cc=true"

  const reqeustBody = {
    currency: "RUB",
    bankCard: {
      number: "RUB",
      expiryYear: "2021",
      expiryMonth: "05",
      csc: "05",
      cardholder: "IVAN IVANOV",
    },
    phoneNumber: "79000000000",
    login: "login_test",
    returnUrl: returnLink,
    ...body,
  };

  try {
    const sendPaymentResponse = await makeRequest<
      SendPaymentRequest,
      SendPaymentResponse
    >({
      method: "POST",
      url: `${API_URL}/wallet`,
      body: reqeustBody,
      contentType: true,
      useToken: true,
      withCredentials: false,
    });

    return [sendPaymentResponse];
  } catch (nativeError) {
    const [error] = parseAxiosError(nativeError);

    return [null, `Ошибка оплаты. ${error}`];
  }
};

export const sendRSPayment = async (
  money: number
): Promise<[string | null, string?]> => {
  try {
    const sendRSPaymentResponse = await makeRequest<{ money: number }, string>({
      method: "POST",
      url: `${API_URL}/wallet/rspay`,
      body: { money },
      useToken: true,
      withCredentials: false,
    });

    return [sendRSPaymentResponse];
  } catch (nativeError) {
    const [error] = parseAxiosError(nativeError);

    return [null, `Ошибка оплаты. ${error}`];
  }
};
