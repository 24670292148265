import React from "react";
import { Box } from "@mui/material";

import CustomAccordion from "@components/CustomAccordion";
import { cardShadow } from "@root/utils/theme";

interface Props {
  content: [string, string][];
}

export default function AccordionWrapper({ content }: Props) {
  return (
    <Box
      sx={{
        overflow: "hidden",
        borderRadius: "12px",
        boxShadow: cardShadow,
      }}
    >
      {content.map((accordionItem, index) => (
        <CustomAccordion
          key={index}
          header={accordionItem[0]}
          text={accordionItem[1].split("\n").map((line, index) => (
            <React.Fragment key={index}>
              {line}
              <br />
            </React.Fragment>
          ))}
        />
      ))}
    </Box>
  );
}
