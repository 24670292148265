import { Box, Typography, Tooltip, SxProps, Theme, Button, Badge, useTheme, useMediaQuery, IconButton, } from "@mui/material";
import { MouseEventHandler, ReactNode } from "react";
import { cardShadow } from "@root/utils/theme";
import { relative } from "path";
import NotebookWithPencil from "@root/assets/Icons/NotebookWithPencil copy";

interface Props {
  icon: ReactNode;
  headerText: string;
  discount?: string;
  text: string;
  sx?: SxProps<Theme>;
  buttonProps?: {
    sx?: SxProps<Theme>;
    onClick?: MouseEventHandler<HTMLButtonElement>;
    text?: string;
  };
  price?: ReactNode;
  sendRequestToCreate?: () => void;
}

export default function TariffCard({ icon, headerText, text, sx, price, buttonProps, discount, sendRequestToCreate }: Props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(600));
  return (
    <Box
      sx={{
        width: "100%",
        minHeight: "250px",
        bgcolor: "white",
        borderRadius: "12px",
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        p: "20px",

        boxShadow: cardShadow,
        ...sx,
      }}
    >
      <Box
        sx={{
          position: "relative",
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "16px",
        }}
      >
        {icon}
        {price && (
          <Box
            sx={{
              display: "flex",
              alignItems: "baseline",
              flexWrap: "wrap",
              columnGap: "10px",
              rowGap: 0,
              flexDirection: isMobile ? "column-reverse" : undefined,
            }}
          >
            {price}
          </Box>
        )}

        {discount && discount !== "0%" && (
          <Box
            sx={{
              padding: "5px 10px",
              position: "absolute",
              left: "50px",
              background: "#ff4904",
              borderRadius: "8px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography color="white">-{discount}</Typography>
          </Box>
        )}
      </Box>
      {/* <Tooltip title={<Typography>{headerText}</Typography>} placement="top"> */}
      <Typography
        variant="h5"
        sx={{
          mt: "14px",
          mb: "10px",
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
          width: "100%",
        }}
      >
        {headerText}
      </Typography>
      {/* </Tooltip>
      <Tooltip
        title={text.map((line, index) => (
          <Typography key={index}>{line}</Typography>
        ))}
        placement="top"
      > */}
      <Box
        sx={{
          width: "100%",
          mb: "auto",
        }}
      >
        <Typography sx={{ overflow: "hidden", textOverflow: "ellipsis" }}>
          {text}
        </Typography>
      </Box>
      {/* </Tooltip> */}
      <Box

        sx={{
          display: "flex",
          width: "100%",
          alignItems: "center",
        }}>
        {buttonProps && (
          <Button
            onClick={buttonProps.onClick}
            variant="pena-outlined-purple"
            sx={{
              mt: "10px",
              ...buttonProps.sx,
            }}
          >
            {buttonProps.text}
          </Button>
        )}
        {Boolean(sendRequestToCreate) && (
          <Box
            sx={{
              ml: isMobile ? "10px" : "20px",
              bgcolor: "#F2F3F7",
              borderRadius: "8px",
            }}
          >
            {isMobile ?
              <IconButton
              onClick={sendRequestToCreate}
              sx={{
                p:"12px",
              }}
              >
                <NotebookWithPencil />
              </IconButton>
              :
              <Button
                sx={{
                  color: "#7E2AEA",
                  p: "10px 14px",
                }}
                onClick={sendRequestToCreate}
              >
                Запросить
              </Button>
            }
          </Box>
        )}
      </Box>
    </Box>
  );
}
