import { ChangeEvent, useEffect, useRef, useState } from "react"
import axios from "axios"
import { Document, Page, pdfjs } from "react-pdf"
import { Box, SxProps, Theme, Typography } from "@mui/material"
import { Buffer } from "buffer"

import UnderlinedButtonWithIcon from "@root/components/UnderlinedButtonWithIcon"
import PaperClipIcon from "@root/components/icons/PaperClipIcon"

import { UserDocument } from "@root/model/user"

import { readFile } from "@root/utils/readFile"
import { downloadFileToDevice } from "@root/utils/downloadFileToDevice"

interface Props {
	text: string;
	document: UserDocument;
	documentUrl?: string;
	onFileChange: (event: ChangeEvent<HTMLInputElement>) => void;
	sx?: SxProps<Theme>;
	accept?: string;
}

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

export default function DocumentUploadItem({
	text,
	document,
	documentUrl = "",
	onFileChange,
	sx,
	accept = "image/*",
}: Props) {
	const urlOrFile = document.file || documentUrl
	const fileInputRef = useRef<HTMLInputElement>(null)

	function handleChooseFileClick() {
		fileInputRef.current?.click()
	}

	const [readyShowDocument, setReadyShowDocument] = useState(false);

	useEffect(() => {
		if (typeof urlOrFile === 'string') {
			if (!urlOrFile.includes("pena.digital")) {
				fetch(documentUrl)
					.then(e => {
						setReadyShowDocument(true)
					})
					.catch(e => console.log(e))
			}
		} else {
			setReadyShowDocument(true)
		}
	}, [])

	const downloadFile = async () => {
		if (!document.file && documentUrl) {
			const { data } = await axios.get<ArrayBuffer>(documentUrl, {
				responseType: "arraybuffer",
			})

			if (!data) {
				return
			}

			downloadFileToDevice("document.pdf", Buffer.from(data))

			return
		}

		if (document.file) {
			const fileArrayBuffer = await readFile(document.file, "array")

			downloadFileToDevice(document.file.name, Buffer.from(fileArrayBuffer))
		}
	}

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				alignItems: "start",
				gap: "10px",
				...sx,
			}}
		>
			<Typography
				sx={{
					color: "#4D4D4D",
					fontWeight: 500,
					fontVariantNumeric: "tabular-nums",
				}}
			>
				{text}
			</Typography>
			<UnderlinedButtonWithIcon
				icon={<PaperClipIcon />}
				onClick={handleChooseFileClick}
			>
				{document.file ? document.file.name : "Выберите файл"}
			</UnderlinedButtonWithIcon>
			<input
				ref={fileInputRef}
				style={{ display: "none" }}
				onChange={onFileChange}
				type="file"
				id="image-file"
				multiple
				accept={accept}
			/>
			{urlOrFile && readyShowDocument &&
				<Document file={urlOrFile}>
					<Page
						pageNumber={1}
						width={80}
						renderTextLayer={false}
						renderAnnotationLayer={false}
						onClick={downloadFile}
					/>
				</Document>}
		</Box>
	)
}
