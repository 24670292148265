import { useEffect, useLayoutEffect, useRef } from "react";
import { devlog } from "@frontend/kitui";

import { ServiceKeyToPrivilegesMap } from "@root/model/privilege";
import { getCustomTariffs } from "@root/api/tariff";

export function useCustomTariffs({
  onError,
  onNewUser,
}: {
  onNewUser: (response: ServiceKeyToPrivilegesMap) => void;
  onError: (error: any) => void;
}) {
  const onNewUserRef = useRef(onNewUser);
  const onErrorRef = useRef(onError);

  useLayoutEffect(() => {
    onNewUserRef.current = onNewUser;
    onErrorRef.current = onError;
  });

  useEffect(() => {
    const controller = new AbortController();

    const getCustomTariffsRequest = async () => {
      const [customTariffs, customTariffsError] = await getCustomTariffs(
        controller.signal
      );

      if (customTariffsError) {
        devlog("Error fetching custom tariffs", customTariffsError);
        onErrorRef.current(customTariffsError);

        return;
      }
      if (customTariffs) {
        onNewUserRef.current(customTariffs);
      }
    };

    getCustomTariffsRequest();

    return () => controller.abort();
  }, []);
}
