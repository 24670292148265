import {
  Box,
  Dialog,
  IconButton,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import InputTextfield from "@components/InputTextfield";
import PenaLogo from "@components/PenaLogo";
import { enqueueSnackbar } from "notistack";
import { Link as RouterLink } from "react-router-dom";
import { object, string } from "yup";
import { useEffect, useState } from "react";
import { setUserId, useUserStore } from "@root/stores/user";
import { cardShadow } from "@root/utils/theme";
import PasswordInput from "@root/components/passwordInput";
import AmoButton from "./AmoButton";
import { login } from "@root/api/auth";

interface Values {
  email: string;
  password: string;
}

const initialValues: Values = {
  email: "",
  password: "",
};

const validationSchema = object({
  email: string()
    .required("Поле обязательно")
    .email("Введите корректный email"),
  password: string().required("Поле обязательно").min(8, "Минимум 8 символов"),
});

export default function SigninDialog() {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(true);
  const user = useUserStore((state) => state.user);
  const theme = useTheme();
  const upMd = useMediaQuery(theme.breakpoints.up("md"));
  const navigate = useNavigate();
  const location = useLocation();
  const formik = useFormik<Values>({
    initialValues,
    validationSchema,
    onSubmit: async (values, formikHelpers) => {
      const [loginResponse, loginError] = await login(
        values.email.trim(),
        values.password.trim()
      );

      formikHelpers.setSubmitting(false);

      if (loginError) {
        return enqueueSnackbar(loginError);
      }

      if (loginResponse) {
        setUserId(loginResponse._id);
      }
    },
  });

  useEffect(
    function redirectIfSignedIn() {
      if (user) navigate("/tariffs", { replace: true });
    },
    [navigate, user]
  );

  function handleClose() {
    setIsDialogOpen(false);
    setTimeout(() => navigate("/"), theme.transitions.duration.leavingScreen);
  }

  return (
    <Dialog
      open={isDialogOpen}
      onClose={handleClose}
      PaperProps={{
        sx: {
          width: "600px",
          maxWidth: "600px",
        },
      }}
      slotProps={{
        backdrop: {
          style: {
            backgroundColor: "rgb(0 0 0 / 0.7)",
          },
        },
      }}
    >
      <Box
        component="form"
        onSubmit={formik.handleSubmit}
        noValidate
        sx={{
          position: "relative",
          backgroundColor: "white",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          p: upMd ? "50px" : "18px",
          pb: upMd ? "40px" : "30px",
          gap: "15px",
          borderRadius: "12px",
          boxShadow: cardShadow,
          "& .MuiFormHelperText-root.Mui-error, & .MuiFormHelperText-root.Mui-error.MuiFormHelperText-filled":
            {
              position: "absolute",
              top: "46px",
              margin: "0",
            },
        }}
      >
        <IconButton
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: "7px",
            top: "7px",
          }}
        >
          <CloseIcon sx={{ transform: "scale(1.5)" }} />
        </IconButton>
        <Box>
          <PenaLogo width={upMd ? 233 : 196} color="black" />
        </Box>
        <Typography
          sx={{
            color: theme.palette.gray.dark,
            mt: "5px",
            mb: upMd ? "10px" : "33px",
          }}
        >
          Вход в личный кабинет
        </Typography>
        <InputTextfield
          TextfieldProps={{
            value: formik.values.email,
            placeholder: "username",
            onBlur: formik.handleBlur,
            error: formik.touched.email && Boolean(formik.errors.email),
            helperText: formik.touched.email && formik.errors.email,
          }}
          onChange={formik.handleChange}
          color="#F2F3F7"
          id="email"
          label="Email"
          gap={upMd ? "10px" : "10px"}
        />
        <PasswordInput
          TextfieldProps={{
            value: formik.values.password,
            placeholder: "Не менее 8 символов",
            onBlur: formik.handleBlur,
            error: formik.touched.password && Boolean(formik.errors.password),
            helperText: formik.touched.password && formik.errors.password,
            type: "password",
          }}
          onChange={formik.handleChange}
          color="#F2F3F7"
          id="password"
          label="Пароль"
          gap={upMd ? "10px" : "10px"}
        />
        <Button
          variant="pena-contained-dark"
          fullWidth
          type="submit"
          disabled={formik.isSubmitting}
          sx={{
            py: "12px",
            "&:hover": {
              backgroundColor: theme.palette.purple.dark,
            },
            "&:active": {
              color: "white",
              backgroundColor: "black",
            },
          }}
        >
          Войти
        </Button>
        <Link
          component={RouterLink}
          to="/recover"
          sx={{ color: theme.palette.gray.dark, mb: "15px" }}
        >
          Забыли пароль?
        </Link>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "10px",
            mt: "auto",
          }}
        >
          <Typography
            sx={{ color: theme.palette.purple.main, textAlign: "center" }}
          >
            Вы еще не присоединились?
          </Typography>
          <Link
            component={RouterLink}
            to="/signup"
            state={{ backgroundLocation: location.state.backgroundLocation }}
            sx={{ color: theme.palette.purple.main }}
          >
            Регистрация
          </Link>
        </Box>
        <AmoButton />
      </Box>
    </Dialog>
  );
}
