import {
	Box,
	Button,
	Dialog,
	IconButton,
	Link,
	Typography,
	useMediaQuery,
	useTheme,
} from "@mui/material"
import { useLocation, useNavigate } from "react-router-dom"
import { useFormik } from "formik"
import CloseIcon from "@mui/icons-material/Close"
import InputTextfield from "@components/InputTextfield"
import PenaLogo from "@components/PenaLogo"
import { enqueueSnackbar } from "notistack"
import { Link as RouterLink } from "react-router-dom"
import { object, ref, string } from "yup"
import { useEffect, useState } from "react"
import { setUserId, useUserStore } from "@root/stores/user"
import { cardShadow } from "@root/utils/theme"
import PasswordInput from "@root/components/passwordInput"
import AmoButton from "./AmoButton"
import { register } from "@root/api/auth"

interface Values {
  email: string;
  password: string;
  repeatPassword: string;
}

const initialValues: Values = {
	email: "",
	password: "",
	repeatPassword: "",
}

const validationSchema = object({
	email: string()
		.required("Поле обязательно")
		.email("Введите корректный email"),
	password: string()
		.min(8, "Минимум 8 символов")
		.matches(/^[.,:;\-_+!&()*<>\[\]\{\}`@"#$\%\^\=?\d\w]+$/, "Некорректные символы")
		.required("Поле обязательно"),
	repeatPassword: string()
		.oneOf([ref("password"), undefined], "Пароли не совпадают")
		.required("Повторите пароль"),
})

export default function SignupDialog() {
	const [isDialogOpen, setIsDialogOpen] = useState<boolean>(true)
	const user = useUserStore((state) => state.user)
	const theme = useTheme()
	const upMd = useMediaQuery(theme.breakpoints.up("md"))
	const navigate = useNavigate()
	const location = useLocation()
	const formik = useFormik<Values>({
		initialValues,
		validationSchema,
		onSubmit: async (values, formikHelpers) => {
			const [registerResponse, registerError] = await register(
				values.email.trim(),
				values.password.trim(),
				"+7"
			)

			formikHelpers.setSubmitting(false)

			if (registerError) {
				return enqueueSnackbar(registerError)
			}

			if (registerResponse) {
				setUserId(registerResponse._id)
			}
		},
	})

	useEffect(
		function redirectIfSignedIn() {
			if (user) navigate("/tariffs", { replace: true })
		},
		[navigate, user]
	)

	function handleClose() {
		setIsDialogOpen(false)
		setTimeout(() => navigate("/"), theme.transitions.duration.leavingScreen)
	}

	return (
		<Dialog
			open={isDialogOpen}
			onClose={handleClose}
			PaperProps={{
				sx: {
					width: "600px",
					maxWidth: "600px",
				},
			}}
			slotProps={{
				backdrop: {
					style: {
						backgroundColor: "rgb(0 0 0 / 0.7)",
					},
				},
			}}
		>
			<Box
				component="form"
				onSubmit={formik.handleSubmit}
				noValidate
				sx={{
					position: "relative",
					backgroundColor: "white",
					display: "flex",
					alignItems: "center",
					flexDirection: "column",
					p: upMd ? "50px" : "18px",
					pb: upMd ? "40px" : "30px",
					gap: "15px",
					borderRadius: "12px",
					boxShadow: cardShadow,
					"& .MuiFormHelperText-root.Mui-error, & .MuiFormHelperText-root.Mui-error.MuiFormHelperText-filled":
            {
            	position: "absolute",
            	top: "46px",
            	margin: "0",
            },
				}}
			>
				<IconButton
					onClick={handleClose}
					sx={{
						position: "absolute",
						right: "7px",
						top: "7px",
					}}
				>
					<CloseIcon sx={{ transform: "scale(1.5)" }} />
				</IconButton>
				<Box sx={{ mt: upMd ? undefined : "62px" }}>
					<PenaLogo width={upMd ? 233 : 196} color="black" />
				</Box>
				<Typography
					sx={{
						color: theme.palette.gray.dark,
						mt: "5px",
						mb: upMd ? "35px" : "33px",
					}}
				>
          Регистрация
				</Typography>
				<InputTextfield
					TextfieldProps={{
						value: formik.values.email,
						placeholder: "username",
						onBlur: formik.handleBlur,
						error: formik.touched.email && Boolean(formik.errors.email),
						helperText: formik.touched.email && formik.errors.email,
					}}
					onChange={formik.handleChange}
					color="#F2F3F7"
					id="email"
					label="Email"
					gap={upMd ? "10px" : "10px"}
				/>
				<PasswordInput
					TextfieldProps={{
						value: formik.values.password,
						placeholder: "Не менее 8 символов",
						onBlur: formik.handleBlur,
						error: formik.touched.password && Boolean(formik.errors.password),
						helperText: formik.touched.password && formik.errors.password,
						autoComplete: "new-password",
					}}
					onChange={formik.handleChange}
					color="#F2F3F7"
					id="password"
					label="Пароль"
					gap={upMd ? "10px" : "10px"}
				/>
				<PasswordInput
					TextfieldProps={{
						value: formik.values.repeatPassword,
						placeholder: "Не менее 8 символов",
						onBlur: formik.handleBlur,
						error:
              formik.touched.repeatPassword &&
              Boolean(formik.errors.repeatPassword),
						helperText:
              formik.touched.repeatPassword && formik.errors.repeatPassword,
						autoComplete: "new-password",
					}}
					onChange={formik.handleChange}
					color="#F2F3F7"
					id="repeatPassword"
					label="Повторить пароль"
					gap={upMd ? "10px" : "10px"}
				/>
				<Button
					variant="pena-contained-dark"
					fullWidth
					type="submit"
					disabled={formik.isSubmitting}
					sx={{
						py: "12px",
						"&:hover": {
							backgroundColor: theme.palette.purple.dark,
						},
						"&:active": {
							color: "white",
							backgroundColor: "black",
						},
					}}
				>
          Зарегистрироваться
				</Button>
				<Link
					component={RouterLink}
					to="/signin"
					state={{ backgroundLocation: location.state.backgroundLocation }}
					sx={{
						color: theme.palette.purple.main,
						mt: "auto",
					}}
				>
          Вход в личный кабинет
				</Link>
			</Box>
		</Dialog>
	)
}
