import { useState } from "react";
import { Box, Typography, Button, useTheme } from "@mui/material";
import { Link, useLocation } from "react-router-dom";

import { DropDownMenu } from "./DropDownMenu";

export type MenuItem = {
  name: string;
  url: string;
  subMenu?: MenuItem[];
};

export default function Menu() {
  const [activeSubMenu, setActiveSubMenu] = useState<MenuItem[]>([]);
  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null);
  const [dropDownItems, setDropDownItems] = useState<MenuItem[]>([]);
  const theme = useTheme();
  const location = useLocation();

  const color = location.pathname === "/" ? "white" : "black";

  const arrayMenu: MenuItem[] =
    location.pathname === "/"
      ? [
          {
            name: "Наши продукты",
            url: "",
            subMenu: [{ name: "PenaQuiz", url: "https://quiz.pena.digital" }],
          },
          {
            name: "Наши услуги",
            url: "",
            subMenu: [
              { name: "Все услуги", url: "https://pena.digital" },
              {
                name: "Разработка Landing Page",
                url: "https://pena.digital/landings",
              },
              {
                name: "Разработка мобильных приложений",
                url: "https://pena.digital/mobileapps",
              },
              {
                name: "Разработка корпоративных сайтов",
                url: "https://pena.digital/corporatesites",
              },
              {
                name: "DevOps & CloudOps",
                url: "https://pena.digital/devops",
              },
              {
                name: "Разработка Cloud-Native приложений",
                url: "https://pena.digital/cloudnative",
              },
              {
                name: "UX/UI дизайн",
                url: "https://pena.digital/design",
              },
              {
                name: "Разработка CRM-систем",
                url: "https://pena.digital/crmsystems",
              },
            ],
          },
        ]
      : [
          {
            name: "Тарифы",
            url: "/tariffs",
            subMenu: [
              { name: "Тарифы на время", url: "/tariffs/time" },
              { name: "Тарифы на объём", url: "/tariffs/volume" },
              { name: "Мой тариф", url: "/tariffconstructor" },
            ],
          },
          { name: "FAQ", url: "/faq" },
          { name: "Корзина", url: "/cart" },
          { name: "Поддержка", url: "/support" },
          { name: "История", url: "/history" },
        ];

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        height: "100%",
        gap: "30px",
        ml: "51px",
      }}
    >
      {location.pathname !== "/"
        ? arrayMenu.map(({ name, url, subMenu = [] }) => (
            <Link
              key={name}
              style={{
                textDecoration: "none",
                height: "100%",
                display: "flex",
                alignItems: "center",
              }}
              to={url}
              target={location.pathname !== "/" ? "_self" : "_blank"}
              onMouseEnter={() => setActiveSubMenu(subMenu)}
              state={{ previousUrl: location.pathname }}
            >
              <Typography
                color={location.pathname === url ? theme.palette.purple.main : color}
                variant="body2"
                sx={{
                  whiteSpace: "nowrap",
                }}
              >
                {name}
              </Typography>
            </Link>
          ))
        : arrayMenu.map(({ name, url, subMenu = [] }, index) => (
            <Button
              sx={{
                whiteSpace: "nowrap",
                color: location.pathname === url ? theme.palette.purple.main : color,
              }}
              onClick={({ currentTarget }) => {
                setAnchorElement(currentTarget);
                setDropDownItems(subMenu);
              }}
            >
              {name}
            </Button>
          ))}
      <DropDownMenu anchorElement={anchorElement} setAnchorElement={setAnchorElement} items={dropDownItems} />
      <Box
        sx={{
          zIndex: "10",
          position: "absolute",
          top: "80px",
          left: 0,
          backgroundColor: theme.palette.background.paper,
          width: "100%",
        }}
        onMouseLeave={() => setActiveSubMenu([])}
      >
        {location.pathname !== "/" &&
          activeSubMenu.map(({ name, url }) => (
            <Link key={name} style={{ textDecoration: "none" }} to={url}>
              <Typography
                color={location.pathname === url ? theme.palette.purple.main : color}
                variant="body2"
                sx={{
                  padding: "15px",
                  whiteSpace: "nowrap",
                  paddingLeft: "185px",
                  "&:hover": {
                    color: theme.palette.purple.main,
                    background: theme.palette.background.default,
                  },
                }}
              >
                {name}
              </Typography>
            </Link>
          ))}
      </Box>
    </Box>
  );
}
