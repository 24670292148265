import { Tab } from "@mui/material"
import { styled } from "@mui/material"


export const CustomTab = styled(Tab)(({ theme }) => ({
	...theme.typography.body2,
	color: theme.palette.primary.main,
	padding: "15px",
	minWidth: 0,
	"&.Mui-selected": {
		color: theme.palette.purple.main,
		textUnderlinePosition: "under",
		textDecoration: "underline",
		textUnderlineOffset: "3px",
	},
	"&:first-of-type": {
		paddingLeft: 0,
	}
}))
