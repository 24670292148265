import React from "react"

import { Box, useMediaQuery, useTheme, Button, SwipeableDrawer, IconButton } from "@mui/material"
import { Outlet, useNavigate} from "react-router-dom"


export default function Component() {
	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down("md"))
	const navigate = useNavigate()
	const [valueButton, setValueButton] = React.useState("")

	const [drawer, setDrawer] = React.useState(false)
	React.useEffect(() => {
		document.getElementsByTagName("html")[0].scrollTop = 0
	}, [])

	return (
		<>
			{isMobile && (
				<Button component="button" sx={{ position: "fixed", backgroundColor: "#434657", color: "#ffffff", width: "100%", "&:hover": {color: "#000000"}  }} variant="gray" onClick={() => setDrawer(true)}>
          меню
				</Button>
			)}
			<Box
				sx={{
					height: "100vh",
					backgroundColor: "#ffffff",
					display: "flex",
					width: "100%",
				}}
			>
				{!isMobile && (
					<Box width="20vw" backgroundColor="#434657">
						<Box
							sx={{
								backgroundColor: "#434657",
								borderRight: 1,
								borderColor: "#434657",
								width: "100%",
								marginTop: "50px"
							}}>
							<Button
								variant={"text"}

								onClick={()=>{
									navigate("/docs/oferta")
									setValueButton("oferta")
								}}

								sx={{
									color: "#ffffff",
									fontSize: "16px",
									width: "100%",
									backgroundColor: valueButton === "oferta" ? "#252734" : undefined,
								}}>
                          Оферта
							</Button>
							<Button
								variant={"text"}
								onClick={()=>{
									navigate("/docs/ofertaQuizDevelop")
									setValueButton("ofertaQuizDevelop")
								}}
								sx={{
									color: "#ffffff",
									fontSize: "16px",
									width: "100%",
									backgroundColor: valueButton === "ofertaQuizDevelop" ? "#252734" : undefined,
								}}>
								Пользовательское соглашение на услугу "Разработка Квиза"
							</Button>

							<Button
								value={"privacy"}
								onClick={()=>{
									navigate("/docs/privacy")
									setValueButton("privacy")
								}}
								sx={{
									color: "#ffffff",
									fontSize: "16px",
									width: "100%",
									backgroundColor: valueButton === "privacy" ? "#252734" : undefined,
								}}>
                          Политика конфиденциальности

							</Button>
						</Box>
					</Box>
				)}
				<Box
					sx={{
						overflow: "auto",
						width: "100%",
						maxHeight: "100vh",
						paddingTop: "20px",
					}}
				>
					<Outlet/>
				</Box>
			</Box>
			<SwipeableDrawer
				anchor="left"
				open={drawer}
				onClose={() => setDrawer(false)}
				onOpen={() => setDrawer(true)}
				sx={{
					"& .MuiPaper-root": {
						width: "50vw",
						backgroundColor: "#434657",
					},
				}}
			>
				<Box
					sx={{
						backgroundColor: "#434657",
						borderRight: 1,
						borderColor: "#434657",
						width: "100%",
						marginTop: "50px"
					}}>
					<Button
						variant={"text"}

						onClick={()=>{
							navigate("/docs/oferta")
							setValueButton("oferta")
						}}

						sx={{
							color: "#ffffff",
							fontSize: "16px",
							width: "100%",
							backgroundColor: valueButton === "oferta" ? "#252734" : undefined,
						}}>
                  Оферта
					</Button>
					<Button
						value={"privacy"}
						onClick={()=>{
							navigate("/docs/privacy")
							setValueButton("privacy")
						}}
						sx={{
							color: "#ffffff",
							fontSize: "16px",
							width: "100%",
							backgroundColor: valueButton === "privacy" ? "#252734" : undefined,
						}}>
                  Политика конфиденциальности

					</Button>
				</Box>
			</SwipeableDrawer>
		</>
	)
}
