import { Tabs as MuiTabs } from "@mui/material"
import { CustomTab } from "@root/components/CustomTab"

type TabsProps = {
  items: string[];
  selectedItem: number;
  setSelectedItem: (num: number) => void;
};

export const Tabs = ({ items, selectedItem, setSelectedItem }: TabsProps) => (
	<MuiTabs
		TabIndicatorProps={{ sx: { display: "none" } }}
		value={selectedItem}
		onChange={(event, newValue: number) => setSelectedItem(newValue)}
		variant="scrollable"
		scrollButtons={false}
	>
		{items.map((item, index) => (
			<CustomTab key={item + index} value={index} label={item} />
		))}
	</MuiTabs>
)
