import { SxProps, Theme, Typography, useTheme } from "@mui/material"


interface Props {
    text1: string;
    text2?: string;
    sx?: SxProps<Theme>;
}

export default function ComplexHeader({ text1, text2, sx }: Props) {
	const theme = useTheme()

	return (
		<Typography variant="h4" sx={sx}>
			{text1}
			{text2 &&
                <Typography
                	component="span"
                	sx={{
                		fontWeight: "inherit",
                		fontSize: "inherit",
                		lineHeight: "inherit",
                		color: theme.palette.purple.main,
                	}}
                >
                	{text2}
                </Typography>
			}
		</Typography>
	)
}
