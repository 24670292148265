import { PrivilegeValueType } from "@frontend/kitui"


function declension(number: number, declensions: string[], cases = [2, 0, 1, 1, 1, 2]) {
	return declensions[
		number % 100 > 4 && number % 100 < 20
			? 2
			: cases[number % 10 < 5 ? number % 10 : 5]
	]
}

export function getDeclension(number: number, word: PrivilegeValueType | "месяц" | "год" | string): string {
	switch (word) {
	case "шаблон":
		return declension(number, ["шаблон", "шаблона", "шаблонов"])
	case "день":
		return declension(number, ["день", "дня", "дней"])
	case "месяц":
		return declension(number, ["месяц", "месяца", "месяцев"])
	case "год":
		return declension(number, ["год", "года", "лет"])
	case "МБ":
		return "МБ"
	default:
		return "ед."
	}
}
