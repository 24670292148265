import makeRequest from "@api/makeRequest";

import { parseAxiosError } from "@utils/parse-error";

const API_URL = `${process.env.REACT_APP_DOMAIN}/codeword/promocode`;

export const activatePromocode = async (
  promocode: string
): Promise<[string | null, string?]> => {
  try {
    const response = await makeRequest<
      { codeword: string } | { fastLink: string },
      { greetings: string }
    >({
      method: "POST",
      url: `${API_URL}/activate`,
      body: { codeword: promocode },
      contentType: true,
    });

    return [response.greetings];
  } catch (nativeError) {
    const [error] = parseAxiosError(nativeError);

    return [null, error];
  }
};
