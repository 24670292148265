import { Button, Typography, useMediaQuery, useTheme } from "@mui/material";

interface Props {
  label: string;
  image: string;
  isSelected?: boolean;
  unpopular?: boolean;
  onClick: () => void;
}

export default function PaymentMethodCard({
  label,
  image,
  isSelected,
  unpopular,
  onClick,
}: Props) {
  const theme = useTheme();
  const upSm = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <Button
      sx={{
        width: upSm ? "237px" : "100%",
        p: "20px",
        pr: "10px",
        display: "flex",
        justifyContent: "start",
        borderRadius: "8px",
        filter: unpopular ? "saturate(0.6) brightness(0.85)" : null,
        backgroundColor: theme.palette.background.default,
        border: isSelected
          ? `1px solid ${theme.palette.purple.main}`
          : `1px solid ${theme.palette.gray.main}`,
        gap: "15px",
        alignItems: "center",
        flexWrap: "wrap",
        boxShadow: isSelected
          ? `0 0 0 1.5px ${theme.palette.purple.main};`
          : "none",
        "&:hover": {
          backgroundColor: theme.palette.purple.main,
          border: `1px solid ${theme.palette.purple.main}`,
          "& > p": {
            color: "white",
          },
        },
      }}
      onClick={onClick}
    >
      <img src={image} alt="payment method" />
      <Typography sx={{ color: theme.palette.gray.dark, maxWidth: "130px", textAlign: "start" }}>{label}</Typography>
    </Button>
  );
}
