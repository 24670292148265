import { CustomPrivilegeWithAmount, Discount, Tariff, calcCustomTariffPrice } from "@frontend/kitui";
import { createTariff } from "@root/api/tariff";
import { CustomTariffUserValuesMap, ServiceKeyToPriceMap } from "@frontend/kitui";
import { ServiceKeyToPrivilegesMap } from "@root/model/privilege";
import { produce } from "immer";
import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { useUserStore } from "./user";


interface CustomTariffsStore {
    privilegeByService: ServiceKeyToPrivilegesMap;
    userValuesMap: CustomTariffUserValuesMap;
    summaryPriceBeforeDiscountsMap: ServiceKeyToPriceMap;
    summaryPriceAfterDiscountsMap: ServiceKeyToPriceMap;
}

const initialState: CustomTariffsStore = {
    privilegeByService: {},
    userValuesMap: {},
    summaryPriceBeforeDiscountsMap: {},
    summaryPriceAfterDiscountsMap: {},
};

export const useCustomTariffsStore = create<CustomTariffsStore>()(
    persist(
        devtools(
            (set, get) => initialState,
            {
                name: "Custom tariffs",
                enabled: process.env.NODE_ENV === "development",
            }),
        {
            name: "customTariffs",
            partialize: state => ({
                userValuesMap: state.userValuesMap,
                summaryPriceBeforeDiscountsMap: state.summaryPriceBeforeDiscountsMap,
                summaryPriceAfterDiscountsMap: state.summaryPriceAfterDiscountsMap,
            }),
            migrate: () => initialState,
        }
    )
);

export const setCustomTariffs = (customTariffs: ServiceKeyToPrivilegesMap) => useCustomTariffsStore.setState({ privilegeByService: customTariffs });

export const setCustomTariffsUserValue = (
    cartTariffs: Tariff[],
    discounts: Discount[],
    serviceKey: string,
    privilegeId: string,
    value: number,
    purchasesAmount: number,
    userId: string,
) => useCustomTariffsStore.setState(
    produce<CustomTariffsStore>(state => {
        state.userValuesMap[serviceKey] ??= {};
        state.userValuesMap[serviceKey][privilegeId] = value;
        const isUserNko = useUserStore.getState().userAccount?.status === "nko";

        const { priceBeforeDiscounts, priceAfterDiscounts } = calcCustomTariffPrice(
            state.userValuesMap[serviceKey],
            state.privilegeByService[serviceKey],
            cartTariffs,
            discounts,
            purchasesAmount,
            isUserNko,
            userId,
        );

        state.summaryPriceBeforeDiscountsMap[serviceKey] = priceBeforeDiscounts;
        state.summaryPriceAfterDiscountsMap[serviceKey] = priceAfterDiscounts;
    })
);

export const createAndSendTariff = (serviceKey: string) => {
    const state = useCustomTariffsStore.getState();

    const privileges: CustomPrivilegeWithAmount[] = [];

    Object.entries(state.userValuesMap[serviceKey]).forEach(([privilegeId, userValue]) => {
        if (userValue === 0) return;

        const privilegeWithoutAmount = state.privilegeByService[serviceKey].find(p => p._id === privilegeId);
        if (!privilegeWithoutAmount) throw new Error(`Privilege not found: ${privilegeId}`);

        const privilege: CustomPrivilegeWithAmount = {
            ...privilegeWithoutAmount,
            privilegeId: privilegeWithoutAmount.privilegeId,
            amount: userValue,
        };

        privileges.push(privilege);
    });

    const name = [...privileges.map(p => p.name), new Date().toISOString()].join(", ");

    const tariff = {
        name,
        isCustom: true,
        privileges,
    };

    return createTariff(tariff);
};

export const clearCustomTariffs = () => useCustomTariffsStore.setState({ ...initialState });
