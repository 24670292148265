import { useMemo } from "react"
import { Link as RouterLink } from "react-router-dom"
import { Box, Button, Typography, useMediaQuery, useTheme } from "@mui/material"

import { cardShadow } from "@root/utils/theme"

import ExclamationPointIcon from "@root/assets/Icons/exclamation_point.svg"

import type { Ticket } from "@frontend/kitui"

type TicketCardProps = {
    ticket: Ticket;
};

export default function TicketCard({ ticket }: TicketCardProps) {
	const theme = useTheme()
	const upMd = useMediaQuery(theme.breakpoints.up("md"))
	const adminReplied = ticket.top_message.user_id !== ticket.user

	const timeText = useMemo(
		() => (
			<Typography
				sx={{
					fontWeight: 400,
					fontSize: "14px",
					lineHeight: "17px",
					color: theme.palette.gray.main,
					mt: "2px",
					mb: "5px",
				}}
			>
				{new Date(ticket.updated_at).toLocaleDateString()}
			</Typography>
		),
		[theme.palette.gray.main, ticket]
	)

	return (
		<Box
			sx={{
				width: "100%",
				p: "20px",
				backgroundColor: "white",
				borderRadius: "12px",
				display: "flex",
				flexDirection: upMd ? "row" : "column",
				justifyContent: "space-between",
				gap: upMd ? "40px" : "20px",
				boxShadow: cardShadow,
			}}
		>
			{!upMd && <Typography>{timeText}</Typography>}
			<Box>
				{adminReplied && (
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							columnGap: "10px",
							marginTop: !upMd ? "-20px" : null,
							marginBottom: "20px",
						}}
					>
						<img src={ExclamationPointIcon} alt="ExclamationPoint" />
						<Typography sx={{ color: theme.palette.orange.main }}>Вам ответили</Typography>
					</Box>
				)}
				<Typography
					sx={{
						mb: "20px",
						fontSize: "18px",
						lineHeight: "21px",
						fontWeight: 500,
					}}
				>
					{ticket.title}
				</Typography>
				<Typography color={theme.palette.gray.dark}>
					{ticket.top_message.message}
				</Typography>
			</Box>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					alignItems: upMd ? "end" : "start",
					justifyContent: "space-between",
					gap: "10px",
				}}
			>
				{upMd && timeText}
				<Button
					variant="pena-outlined-purple"
					component={RouterLink}
					to={`/support/${ticket.id}`}
				>
                    Перейти
				</Button>
			</Box>
		</Box>
	)
}
