import { Modal, Box, Typography, Button, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";

type SorryModalProps = {
  open: boolean;
  setOpen: (isOpen: boolean) => void;
};

export const SorryModal = ({ open, setOpen }: SorryModalProps) => {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          margin: "10px",
          padding: "25px",
          maxWidth: "600px",
          borderRadius: "5px",
          textAlign: "center",
          background: theme.palette.background.default,
        }}
      >
        <Box>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Данный метод оплаты в стадии подключения, после подключения мы вас оповестим на e-mail.
          </Typography>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            С уважением команда Pena.
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexWrap: "wrap",
            gap: "20px",
            marginTop: "15px",
          }}
        >
          <Button variant="pena-outlined-purple" onClick={() => setOpen(false)}>
            OK
          </Button>
          </Box>
      </Box>
    </Modal>
  );
};
