import { Box } from "@mui/material"


interface Props {
    color: string;
    bgcolor: string;
}

export default function PieChartIcon({ color, bgcolor }: Props) {

	return (
		<Box
			sx={{
				bgcolor,
				height: "36px",
				width: "36px",
				minWidth: "36px",
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				borderRadius: "6px",
			}}
		>
			<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
				<path d="M10.0024 10.0045V1.00455" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
				<path d="M17.7932 5.50455L2.21191 14.5045" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
				<path d="M1.15253 11.6545C1.05021 11.1106 0.999987 10.5581 1.00253 10.0045C1.00135 8.14323 1.57789 6.32742 2.6526 4.80771C3.72731 3.288 5.24721 2.13932 7.00253 1.52017V8.27017L1.15253 11.6545Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
				<path d="M10.0025 1.00455C11.5795 1.00473 13.1288 1.41928 14.4952 2.2067C15.8616 2.99411 16.9971 4.12674 17.7879 5.49112C18.5788 6.85551 18.9973 8.40375 19.0014 9.98078C19.0056 11.5578 18.5953 13.1082 17.8117 14.4768C17.028 15.8453 15.8985 16.9839 14.5363 17.7785C13.1741 18.5732 11.627 18.9959 10.05 19.0044C8.47303 19.0129 6.92147 18.6069 5.55077 17.827C4.18007 17.0472 3.03836 15.9208 2.23999 14.5608" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
			</svg>
		</Box>
	)
}
