import { useState } from "react"
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material"
import ExpandIcon from "@components/icons/ExpandIcon"
import { currencyFormatter } from "@root/utils/currencyFormatter"
import { removeTariffFromCart } from "@root/stores/user"
import { enqueueSnackbar } from "notistack"
import {
	CloseButton,
	TariffCartData,
	getMessageFromFetchError,
} from "@frontend/kitui"

import type { MouseEvent } from "react"

interface Props {
  tariffCartData: TariffCartData;
}

export default function CustomTariffAccordion({ tariffCartData }: Props) {
	const theme = useTheme()
	const upMd = useMediaQuery(theme.breakpoints.up("md"))
	const upSm = useMediaQuery(theme.breakpoints.up("sm"))
	const [isExpanded, setIsExpanded] = useState<boolean>(false)

	function handleDeleteClick(event: MouseEvent<HTMLButtonElement>) {
		event.stopPropagation()

		removeTariffFromCart(tariffCartData.id)
			.then(() => {
				enqueueSnackbar("Тариф удален")
			})
			.catch((error) => {
				const message = getMessageFromFetchError(error)
				if (message) enqueueSnackbar(message)
			})
	}

	return (
		<Box
			sx={{
				overflow: "hidden",
			}}
		>
			<Box
				sx={{
					backgroundColor: "white",
				}}
			>
				<Box
					onClick={() => setIsExpanded((prev) => !prev)}
					sx={{
						height: "72px",
						pr: "20px",
						pl: "30px",
						display: "flex",
						gap: "15px",
						alignItems: "center",
						justifyContent: "space-between",
						cursor: "pointer",
						userSelect: "none",
					}}
				>
					<Box
						sx={{
							width: "50px",
							height: "100%",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<ExpandIcon isExpanded={isExpanded} />
					</Box>
					<Typography
						sx={{
							width: "100%",
							fontSize: upMd ? "20px" : "16px",
							lineHeight: upMd ? undefined : "19px",
							fontWeight: 400,
							color: theme.palette.text.secondary,
							px: 0,
							overflow: "hidden",
							textOverflow: "ellipsis",
						}}
					>
            Мой тариф
					</Typography>
					<Box
						sx={{
							display: "flex",
							justifyContent: "flex-end",
							height: "100%",
							alignItems: "center",
							gap: upSm ? "111px" : "17px",
						}}
					>
						<Typography
							sx={{
								color: theme.palette.gray.dark,
								fontSize: upSm ? "20px" : "16px",
								fontWeight: 500,
							}}
						>
							{currencyFormatter.format(tariffCartData.price / 100)}
						</Typography>
					</Box>
					<CloseButton
						style={{ height: "22 px", width: "22px" }}
						onClick={handleDeleteClick}
					/>
				</Box>
				{isExpanded &&
          tariffCartData.privileges.map((privilege) => (
          	<Box
          		key={privilege.privilegeId}
          		sx={{
          			px: "50px",
          			py: upMd ? "15px" : undefined,
          			pt: upMd ? undefined : "15px",
          			pb: upMd ? undefined : "25px",
          			backgroundColor: "#F1F2F6",
          			display: "flex",
          			justifyContent: "space-between",
          			alignItems: "center",
          			gap: "15px",
          		}}
          	>
          		<Typography
          			sx={{
          				fontSize: upMd ? undefined : "16px",
          				lineHeight: upMd ? undefined : "19px",
          				color: theme.palette.gray.dark,
          				width: "100%",
          			}}
          		>
          			{privilege.description}
          		</Typography>
          		<Box
          			sx={{
          				width: upSm ? "140px" : "123px",
          				marginRight: upSm ? "65px" : 0,
          			}}
          		>
          			<Typography
          				sx={{
          					color: theme.palette.gray.dark,
          					fontSize: upSm ? "20px" : "16px",
          					fontWeight: 500,
          				}}
          			>
          				{currencyFormatter.format(privilege.price / 100)}
          			</Typography>
          		</Box>
          	</Box>
          ))}
			</Box>
		</Box>
	)
}
