import {
	FormControl,
	InputLabel,
	SxProps,
	TextField,
	TextFieldProps,
	Theme,
	useMediaQuery,
	useTheme,
} from "@mui/material"

import "./text-input.css"

interface Props {
  id: string;
  label?: string;
  bold?: boolean;
  gap?: string;
  color?: string;
  FormInputSx?: SxProps<Theme>;
  TextfieldProps: TextFieldProps;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  onBlur?: () => void
  onFocus?: () => void
}

export default function InputTextfield({
	id,
	label,
	bold = false,
	gap = "10px",
	onChange,
	TextfieldProps,
	color,
	FormInputSx,
	onBlur = ()=>{},
	onFocus = ()=>{}
}: Props) {
	const theme = useTheme()
	const upMd = useMediaQuery(theme.breakpoints.up("md"))

	const labelFont = upMd
		? bold
			? theme.typography.p1
			: { ...theme.typography.body1, fontWeight: 500 }
		: theme.typography.body2

	const placeholderFont = upMd ? undefined : { fontWeight: 400, fontSize: "16px", lineHeight: "19px" }

	return (
		<FormControl
		
			fullWidth
			variant="standard"
			sx={{
				gap,
				// mt: "10px",
				...FormInputSx,
			}}
		>
			{label && (
				<InputLabel
					shrink
					htmlFor={id}
					sx={{
						position: "inherit",
						color: "black",
						transform: "none",
						...labelFont,
					}}
				>
					{label}
				</InputLabel>
			)}
			<TextField
			onBlur={(e) => {
				onBlur()
			}}
			onFocus={(e) => {
				onFocus()
			}}
				{...TextfieldProps}
				fullWidth
				id={id}
				sx={{
					"& .MuiInputBase-root": {
						height: "48px",
						borderRadius: "8px",
					},
				}}
				inputProps={{
					sx: {
						boxSizing: "border-box",
						backgroundColor: color,
						border: "1px solid" + theme.palette.gray.main,
						borderRadius: "8px",
						height: "48px",
						py: 0,
						color: "black",
						...placeholderFont,
					},
				}}
				onChange={onChange}
			/>
		</FormControl>
	)
}
