import { useState } from "react"
import { Button, useMediaQuery, useTheme } from "@mui/material"
import { Link, useLocation } from "react-router-dom"

import SectionWrapper from "../SectionWrapper"

import PenaLogo from "../PenaLogo"
import DialogMenu from "./DialogMenu"
import { BurgerButton } from "@frontend/kitui"

interface Props {
    isLoggedIn: boolean;
}

export default function NavbarCollapsed({ isLoggedIn }: Props) {
	const [open, setOpen] = useState(false)
	const theme = useTheme()

	return (
		<SectionWrapper
			component="nav"
			maxWidth="lg"
			outerContainerSx={{
				zIndex: "999",
				position: "fixed",
				top: "0",
				backgroundColor: theme.palette.bg.main,
				borderBottom: "1px solid #E3E3E3",
			}}
			sx={{
				height: "51px",
				py: "6px",
				display: "flex",
				justifyContent: "space-between",
				alignItems: "center",
			}}
		>
			<Link to="/">
				<PenaLogo width={100} color="white" />
			</Link>

			<BurgerButton onClick={() => setOpen(!open)} sx={{ color: "white" }} />
			<DialogMenu open={open} handleClose={() => setOpen(false)} />
		</SectionWrapper>
	)
}
