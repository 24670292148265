import { CartData, calcCart } from "@frontend/kitui";
import { useUserStore } from "@root/stores/user";
import { useMemo } from "react";
import { useCartTariffs } from "./useCartTariffs";
import { useDiscounts } from "@root/api/price";


export function useCart(): CartData {
    const cartTariffs = useCartTariffs();
    const purchasesAmount = useUserStore((state) => state.userAccount?.wallet.spent) ?? 0;
    const isUserNko = useUserStore(state => state.userAccount?.status) === "nko";
    const userId = useUserStore(state => state.user?._id) ?? "";
    const discounts = useDiscounts(userId);

    const cart = useMemo(() => {
        return calcCart(cartTariffs ?? [], discounts ?? [], purchasesAmount, userId, isUserNko);
    }, [cartTariffs, discounts, purchasesAmount, userId, isUserNko]);

    console.log("cart ", cart)

    return cart;
}
