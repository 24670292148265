import {
  Box,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CustomAccordion from "@components/CustomAccordion";
import File from "@components/icons/File";
import { getDeclension } from "@utils/declension";
import { enqueueSnackbar } from "notistack";
import { addTariffToCart, useUserStore } from "@root/stores/user";
import ForwardToInboxOutlinedIcon from "@mui/icons-material/ForwardToInboxOutlined";
import { KeyValue, RawDetails, sendReport } from "@api/history";
import { useNavigate } from "react-router-dom";
import { VerificationStatus } from "@root/model/account";

export type History = {
  title: string;
  date: string;
  info: string;
  description: string;
  payMethod?: string;
  expired?: boolean;
};

interface AccordionWrapperProps {
  content: [RawDetails, KeyValue];
  last?: boolean;
  first?: boolean;
  createdAt: string;
  onClickMail?: any;
  mainId: string;
}

export default function AccordionWrapper({
  content,
  last,
  first,
  createdAt,
  onClickMail,
  mainId,
}: AccordionWrapperProps) {
  const theme = useTheme();
  const upMd = useMediaQuery(theme.breakpoints.up("md"));
  const upSm = useMediaQuery(theme.breakpoints.up("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down(900));
  const isMobile = useMediaQuery(theme.breakpoints.down(560));
  const navigate = useNavigate();
  const verificationStatus = useUserStore((state) => state.verificationStatus);
  const OrgName = useUserStore((state) => state.userAccount?.name.orgname);

  const valuesByKey: any = {};
  if (Array.isArray(content[0].Value) && Array.isArray(content[0].Value[0])) {
    (content[0].Value[0] as KeyValue[]).forEach((item: KeyValue) => {
      valuesByKey[item.Key] = item.Value;
    });
  }
  const extractDateFromString = (tariffName: string) => {
    const dateMatch = tariffName.match(/\d{4}-\d{2}-\d{2}/);
    return dateMatch ? dateMatch[0] : null;
  };

  async function handleTariffItemClick(tariffId: string) {
    const { patchCartError } = await addTariffToCart(tariffId);
    if (patchCartError) {
      enqueueSnackbar(patchCartError);
    } else {
      enqueueSnackbar("Тариф добавлен в корзину");
    }
  }

  async function sendBillByEmail(logId: string) {
    if (verificationStatus === VerificationStatus.VERIFICATED && OrgName) {
      const [, sendReportError] = await sendReport(logId);

      if (!sendReportError) {
        return enqueueSnackbar(
          "Акт будет отправлен на почту, указанную при регистрации"
        );
      }

      enqueueSnackbar("Извините, произошла ошибка");
    }
    navigate("/settings");
    if (verificationStatus !== VerificationStatus.VERIFICATED && !OrgName) {
      enqueueSnackbar("Пройдите верификацию и заполните название организации");
    } else if (!OrgName) {
      enqueueSnackbar("Заполните поле название организации");
    } else if (verificationStatus !== VerificationStatus.VERIFICATED) {
      enqueueSnackbar("Пройдите верификацию");
    }
  }

  return (
    <Box
      sx={{
        borderRadius: "12px",
      }}
    >
      <CustomAccordion
        last={last}
        first={first}
        divide
        text={valuesByKey.privileges.map((e: KeyValue[]) => (
          <Typography key={valuesByKey.id}>
            {e[1].Value} - {e[5].Value}{" "}
            {getDeclension(Number(e[5].Value), e[7].Value.toString())}
          </Typography>
        ))}
        header={
          <>
            <Box
              sx={{
                width: "100%",
                height: upMd ? "72px" : undefined,
                padding: "20px 20px 20px 0",
                display: "flex",
                justifyContent: "space-between",
                cursor: "pointer",
                userSelect: "none",
                gap: "20px",
                alignItems: upSm ? "center" : undefined,
                flexDirection: upSm ? undefined : "column",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: upSm ? "center" : undefined,
                  justifyContent: "space-between",
                  flexDirection: upSm ? undefined : "column",
                  gap: upMd ? "51px" : "10px",
                }}
              >
                <Typography
                  sx={{
                    width: "110px",
                    fontSize: upMd ? "20px" : "18px",
                    lineHeight: upMd ? undefined : "19px",
                    fontWeight: 500,
                    color: valuesByKey.expired
                      ? theme.palette.text.disabled
                      : theme.palette.text.secondary,
                    px: 0,
                    whiteSpace: "nowrap",
                  }}
                >
                  {createdAt}
                </Typography>

                <Typography
                  title={valuesByKey.iscustom ? "Мой тариф" : valuesByKey.name}
                  sx={{
                    fontSize: upMd ? "18px" : "16px",
                    lineHeight: upMd ? undefined : "19px",
                    fontWeight: 500,
                    color: valuesByKey.expired
                      ? theme.palette.text.disabled
                      : theme.palette.gray.dark,
                    px: 0,
                    width: "200px",
                    maxWidth: "200px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {valuesByKey.iscustom ? "Мой тариф" : valuesByKey.name}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  flexFlow: "1",
                  flexBasis: "60%",
                }}
              >
                <Box display="flex" width="100%" justifyContent="space-between">
                  <Typography
                    sx={{
                      display: upMd ? undefined : "none",
                      fontSize: upMd ? "18px" : "16px",
                      lineHeight: upMd ? undefined : "19px",
                      fontWeight: 400,
                      color: valuesByKey.expired
                        ? theme.palette.text.disabled
                        : theme.palette.gray.dark,
                      px: 0,
                    }}
                    title={`>Способ оплаты: ${valuesByKey.payMethod}</Typography>}`}
                  >
                    {valuesByKey.payMethod && (
                      <Typography
                        sx={{
                          maxWidth: "300px",
                          width: "300px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        Способ оплаты: {valuesByKey.payMethod}
                      </Typography>
                    )}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      height: "100%",
                      alignItems: "center",
                      gap: upSm ? "111px" : "17px",
                      width: "100%",
                      maxWidth: isTablet ? null : "160px",
                    }}
                  >
                    <Typography
                      sx={{
                        marginLeft: isTablet
                          ? isMobile
                            ? null
                            : "auto"
                          : null,
                        color: valuesByKey.expired
                          ? theme.palette.text.disabled
                          : theme.palette.gray.dark,
                        fontSize: upSm ? "20px" : "16px",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {Number(content[1].Value) / 100
                        ? Number(content[1].Value) / 100
                        : "nodata"}{" "}
                      руб.
                    </Typography>
                  </Box>
                </Box>
                {!isMobile && (
                  <>
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        sendBillByEmail(mainId);
                      }}
                      sx={{
                        ml: "20px",
                        bgcolor: "#EEE4FC",
                        color: "#7E2AEA",
                        borderRadius: 2,
                        "&:hover": {
                          bgcolor: "#7E2AEA",
                          color: "white",
                        },
                        "&:active": {
                          bgcolor: "black",
                          color: "white",
                        },
                      }}
                    >
                      <ForwardToInboxOutlinedIcon
                        fontSize={"medium"}
                        sx={{ opacity: 0.9 }}
                      />
                    </IconButton>
                    <IconButton
                      title="Добавить в корзину тариф"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleTariffItemClick(valuesByKey.id);
                      }}
                      sx={{
                        ml: "20px",
                        bgcolor: "#EEE4FC",
                        stroke: "#7E2AEA",
                        borderRadius: 2,
                        "&:hover": {
                          bgcolor: "#7E2AEA",
                          stroke: "white",
                        },
                        "&:active": {
                          bgcolor: "black",
                          stroke: "white",
                        },
                      }}
                    >
                      <File></File>
                    </IconButton>
                  </>
                )}
              </Box>
            </Box>
            {isMobile && (
              <>
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    sendBillByEmail(mainId);
                  }}
                  sx={{
                    m: "0 10px",
                    bgcolor: "#EEE4FC",
                    color: "#7E2AEA",
                    borderRadius: 2,
                    "&:hover": {
                      bgcolor: "#7E2AEA",
                      color: "white",
                    },
                    "&:active": {
                      bgcolor: "black",
                      color: "white",
                    },
                  }}
                >
                  <ForwardToInboxOutlinedIcon
                    fontSize={"medium"}
                    sx={{ opacity: 0.9 }}
                  />
                </IconButton>
                <IconButton
                  title="Добавить в корзину тариф"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleTariffItemClick(valuesByKey.id);
                  }}
                  sx={{
                    mr: "10px",
                    bgcolor: "#EEE4FC",
                    stroke: "#7E2AEA",
                    borderRadius: 2,
                    "&:hover": {
                      bgcolor: "#7E2AEA",
                      stroke: "white",
                    },
                    "&:active": {
                      bgcolor: "black",
                      stroke: "white",
                    },
                  }}
                >
                  <File></File>
                </IconButton>
              </>
            )}
          </>
        }
      />
    </Box>
  );
}
