import { Box, useMediaQuery, useTheme } from "@mui/material"
import NavbarCollapsed from "./NavbarCollapsed"
import NavbarFull from "./NavbarFull"

import type { ReactNode } from "react"

interface Props {
    children: ReactNode;
}
export default function Navbar({ children }: Props) {
	const theme = useTheme()
	const upMd = useMediaQuery(theme.breakpoints.up(1075))

	return (
		<Box
			sx={{
				paddingTop: upMd ? "80px" : 0,
				width: "100%",
			}}
		>
			{upMd ? (
				<NavbarFull>{children}</NavbarFull>
			) : (
				<NavbarCollapsed>{children}</NavbarCollapsed>
			)}
		</Box>
	)
}
