import { HistoryRecord, HistoryRecord2 } from "@root/api/history";
import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";


type HistoryStore = {
    history: HistoryRecord[] | HistoryRecord2[] | null;
};

const initialState: HistoryStore = {
    history: null,
};

export const useHistoryStore = create<HistoryStore>()(
    persist(
        devtools(
            () => initialState,
            {
                name: "HistoryStore",
            }
        ),
        {
            name: "HistoryStore",
        }
    )
);

export const setHistory = (history: HistoryStore["history"]) =>  useHistoryStore.setState({ history });
