import { Box, Button, Typography, useMediaQuery, useTheme } from "@mui/material"
import SectionWrapper from "@components/SectionWrapper"
import mainShapeVideo from "../../assets/animations/main.webm"
import previewMain from "../../assets/animations/preview_main.png"

declare module "react" {
  interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
    pip?: string;
  }
}

export default function Section1() {
	const theme = useTheme()
	const upMd = useMediaQuery(theme.breakpoints.up("md"))
	const isTablet = useMediaQuery(theme.breakpoints.up(1000))

	return (
		<SectionWrapper
			component="section"
			maxWidth="lg"
			outerContainerSx={{
				backgroundColor: theme.palette.bg.main,
			}}
			sx={{
				display: "flex",
				pt: upMd ? "20px" : "20px",
				px: !isTablet ? (upMd ? "40px" : "18px") : "20px",
				pb: "10px",
				flexDirection: upMd ? "row" : "column",
			}}
		>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					flexGrow: 1,
					order: upMd ? 1 : 2,
					maxWidth: "700px",
					mt: upMd ? "85px" : "40px",
					mb: upMd ? "104px" : "70px",
					alignItems: "start",
				}}
			>
				<Typography sx={{ maxWidth: !isTablet ? "500px" : "100%" }} variant="h2">
          Сервисы прокачки маркетинга и бизнеса
				</Typography>

				<Typography mt="35px" maxWidth="380px">
          Автоматизация и новые источники трафика в единой экосистеме
				</Typography>
				{/*<Typography mt="11.5px">И все это в едином кабинете</Typography>*/}
				{/*<Button variant="pena-contained-dark" sx={{ mt: "40px" }}>*/}
				{/*  Подробнее*/}
				{/*</Button>*/}
			</Box>
			<Box
				sx={{
					ml: upMd ? "-100px" : undefined,
					mb: "-40px",
					flexShrink: 1,
					textAlign: "center",
					order: upMd ? 2 : 1,
					alignSelf: "center",
					aspectRatio: "1 / 1",
					width: upMd ? undefined : "100%",
					maxHeight: upMd ? "550px" : "300px",
				}}
			>
				<video
					autoPlay
					loop
					pip="false"
					muted
					playsInline
					poster={previewMain}
					style={{
						display: "block",
						width: "100%",
						height: "100%",
					}}
				>
					<source src={mainShapeVideo} type="video/webm" />
          Your browser doesn"t support HTML5 video tag.,
				</video>
			</Box>
		</SectionWrapper>
	)
}
