import { Box, SxProps, Theme } from "@mui/material";


type Props = {
    sx?: SxProps<Theme>;
};

export default function SimpleArrowDown({ sx }: Props) {

    return (
        <Box sx={{
            width: "14px",
            height: "9px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexShrink: 0,
            ...sx,
        }}>
            <svg width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13 1.48535L7 7.48535" stroke="#7E2AEA" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M1 1.48535L7 7.48535" stroke="#7E2AEA" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        </Box>
    );
}
