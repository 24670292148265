import { useUserStore } from "@root/stores/user"
import NkoDocumentsDialog from "./NkoDocumentsDialog"
import JuridicalDocumentsDialog from "./JuridicalDocumentsDialog"


export default function DocumentsDialog() {
	switch(useUserStore(state => state.dialogType)) {
	case "juridical":
		return <JuridicalDocumentsDialog />
      
	case "nko":
		return <NkoDocumentsDialog />
      
	default:
		return <></>
	}
}