import { penaMuiTheme } from "@frontend/kitui"
import { ThemeOptions, createTheme } from "@mui/material"
import { deepmerge } from "@mui/utils"


const themeOverrides: ThemeOptions = {
	// override theme here
}

export const theme = createTheme(deepmerge(penaMuiTheme, themeOverrides))

export const cardShadow = "0px 15px 80px rgb(210 208 225 / 70%)"
