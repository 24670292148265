import { ErrorInfo } from "react";

interface ComponentError {
  timestamp: number;
  message: string;
  callStack: string | undefined;
  componentStack: string | null | undefined;
}

export function handleComponentError(error: Error, info: ErrorInfo) {
  const componentError: ComponentError = {
    timestamp: Math.floor(Date.now() / 1000),
    message: error.message,
    callStack: error.stack,
    componentStack: info.componentStack,
  };

  queueErrorRequest(componentError);
}

let errorsQueue: ComponentError[] = [];
let timeoutId: ReturnType<typeof setTimeout>;

function queueErrorRequest(error: ComponentError) {
  errorsQueue.push(error);

  clearTimeout(timeoutId);
  timeoutId = setTimeout(() => {
    sendErrorsToServer();
  }, 1000);
}

async function sendErrorsToServer() {
  errorsQueue = [];
}
