import { TransitionProps } from "@mui/material/transitions";
import logotip from "../../assets/Icons/logoPenaHab.svg";
import logotipBlack from "../../assets/Icons/black_logo_PenaHab.svg";
import CustomAvatar from "./Avatar";
import React, { useState } from "react";
import { Box, Button, Dialog, List, ListItem, Slide, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { useUserStore } from "@root/stores/user";
import { currencyFormatter } from "@root/utils/currencyFormatter";

const subMenu = [
  { name: "Все услуги", url: "https://pena.digital" },
  {
    name: "Разработка Landing Page",
    url: "https://pena.digital/landings",
  },
  {
    name: "Разработка мобильных приложений",
    url: "https://pena.digital/mobileapps",
  },
  {
    name: "Разработка корпоративных сайтов",
    url: "https://pena.digital/corporatesites",
  },
  {
    name: "DevOps & CloudOps",
    url: "https://pena.digital/devops",
  },
  {
    name: "Разработка Cloud-Native приложений",
    url: "https://pena.digital/cloudnative",
  },
  {
    name: "UX/UI дизайн",
    url: "https://pena.digital/design",
  },
  {
    name: "Разработка CRM-систем",
    url: "https://pena.digital/crmsystems",
  },
];

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },

  ref: React.Ref<null>
) {
  return <Slide direction={"left"} ref={ref} {...props} />;
});

interface DialogMenuProps {
  open: boolean;
  handleClose: () => void;
}

export default function DialogMenu({ open, handleClose }: DialogMenuProps) {
  const theme = useTheme();
  const location = useLocation();
  const isTablet = useMediaQuery(theme.breakpoints.down(900));
  const isTabletMenu = useMediaQuery(theme.breakpoints.down(1000));
  const user = useUserStore((state) => state.user);
  const cash = useUserStore((state) => state.userAccount?.wallet.cash) ?? 0;

  const [subMenuOpen, setSubMenuOpen] = useState(false);
  const [subMenuProdOpen, setSubMenuProdOpen] = useState(false)

  const isMobileHeight = useMediaQuery("(max-height: 400px)");

  return (
    <Dialog
      fullScreen
      sx={{
        width: isTablet ? "100%" : "320px",
        ml: "auto",
        mt: isTablet ? "50px" : "80px",
        ".MuiBackdrop-root.MuiModal-backdrop": {
          background: "transparent",
        },
        ".MuiPaper-root.MuiPaper-rounded": {
          background: "#333647",
        },
      }}
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <List
        sx={{
          background: location.pathname === "/" ? "#333647" : "#FFFFFF",
          height: "58%",
          overflow: "scroll",
          p: "0",
          paddingTop: "20px",
        }}
      >
        <ListItem
          sx={{
            pl: "40px",
            flexDirection: "column",
            alignItems: isTablet ? "start" : "end",
          }}
        >
          <Button
            onClick={() => setSubMenuProdOpen(!subMenuProdOpen)}
            disableRipple
            variant="text"
            sx={{
              fontWeight: "500",
              // color: location.pathname === url ? theme.palette.purple.main : location.pathname === "/" ? "white" : "black",
              color: "white",
              height: "fit-content",
              textTransform: "none",
              marginBottom: "19px",
              fontSize: "16px",
              "&:hover": {
                background: "none",
                color: theme.palette.purple.main,
              },
            }}
          >
            Наши продукты
          </Button>
            <Box
                sx={{
                    ml: "20px",
                    display: "flex",
                    alignItems: isTablet ? "flex-start" : "flex-end",
                    flexDirection: "column",
                }}
            >
                {subMenuProdOpen ?
                    <Button
                        component={Link}
                        to={"https://quiz.pena.digital/"}
                        state={user ? undefined : {backgroundLocation: location}}
                        disableRipple
                        variant="text"
                        target="_blank"
                        sx={{
                            fontWeight: "500",
                            // color: location.pathname === url ? theme.palette.purple.main : location.pathname === "/" ? "white" : "black",

                            color: "white",
                            height: "fit-content",
                            textTransform: "none",
                            marginBottom: "19px",
                            fontSize: "16px",
                            "&:hover": {
                                background: "none",
                                color: theme.palette.purple.main,
                            },
                        }}
                    >
                        PenaQuiz
                    </Button>
                    : null
                }
            </Box>

          <Button
            onClick={() => setSubMenuOpen(!subMenuOpen)}
            disableRipple
            variant="text"
            sx={{
              fontWeight: "500",
              // color: location.pathname === url ? theme.palette.purple.main : location.pathname === "/" ? "white" : "black",
              color: "white",
              height: "20px",
              textTransform: "none",
              marginBottom: "25px",
              fontSize: "16px",
              "&:hover": {
                background: "none",
                color: theme.palette.purple.main,
              },
            }}
          >
            Наши услуги
          </Button>
          <Box
            sx={{
              ml: "20px",
              display: "flex",
              alignItems: isTablet ? "flex-start" : "flex-end",
              flexDirection: "column",
            }}
          >
            {subMenuOpen
              ? subMenu.map(({ name, url }, index) => (
                  <Button
                    key={index}
                    component={Link}
                    to={url}
                    state={user ? undefined : { backgroundLocation: location }}
                    disableRipple
                    target="_blank"
                    variant="text"
                    sx={{
                      fontWeight: "500",
                      // color: location.pathname === url ? theme.palette.purple.main : location.pathname === "/" ? "white" : "black",
                      textAlign: isTablet ? "start" : "end",
                      color: "white",
                      height: "fit-content",
                      textTransform: "none",
                      marginBottom: "19px",
                      fontSize: "16px",
                      "&:hover": {
                        background: "none",
                        color: theme.palette.purple.main,
                      },
                    }}
                  >
                    {name}
                  </Button>
                ))
              : null}
          </Box>
        </ListItem>
      </List>
      {isTablet ? (
        location.pathname === "/" ? (
          <Button
            component={Link}
            to={user ? "/tariffs" : "/signin"}
            state={user ? undefined : { backgroundLocation: location }}
            variant="pena-contained-dark"
            sx={{ px: "30px", ml: "40px", width: "245px", mt: "50px" }}
          >
            {user ? "Личный кабинет" : "Регистрация / Войти"}
          </Button>
        ) : (
          <Box
            sx={{
              width: "100%",
              height: "72px",
              background: "#F2F3F7",
              display: "flex",
              alignItems: "center",
              position: "absolute",
              bottom: "0",
            }}
          >
            <CustomAvatar />
            <Box sx={{ ml: "8px", whiteSpace: "nowrap" }}>
              <Typography
                sx={{
                  fontSize: "12px",
                  lineHeight: "14px",
                  color: theme.palette.gray.dark,
                }}
              >
                Мой баланс
              </Typography>
              <Typography variant="body2" color={theme.palette.purple.main}>
                {currencyFormatter.format(cash / 100)}
              </Typography>
            </Box>
          </Box>
        )
      ) : (
        <>
          <Box
            sx={{
              position: "absolute",
              right: "40px",
              bottom: "60px",
            }}
          >
            <img src={location.pathname === "/" ? logotip : logotipBlack} alt="icon" />
          </Box>
        </>
      )}
    </Dialog>
  );
}
