import { Tariff } from "@frontend/kitui"
import { create } from "zustand"
import { devtools } from "zustand/middleware"


interface TariffStore {
    tariffs: Tariff[];
}

export const useTariffStore = create<TariffStore>()(
	devtools(
		(set, get) => ({
			tariffs: [],
		}),
		{
			name: "Tariffs",
			enabled: process.env.NODE_ENV === "development",
			trace: true,
		}
	)
)

export const updateTariffs = (tariffs: TariffStore["tariffs"]) => useTariffStore.setState(
	state => {
		const tariffMap: Record<string, Tariff> = {};

		[...state.tariffs, ...tariffs].forEach(tariff => tariffMap[tariff._id] = tariff)

		const sortedTariffs = Object.values(tariffMap).sort(sortTariffsByCreatedAt)

		return { tariffs: sortedTariffs }
	},
	false,
	{
		type: "updateTariffs",
		tariffs: tariffs,
	}
)

function sortTariffsByCreatedAt(tariff1: Tariff, tariff2: Tariff) {
	if (!tariff1.createdAt || !tariff2.createdAt) throw new Error("Trying to sort tariffs without createdAt field")

	const date1 = new Date(tariff1.createdAt).getTime()
	const date2 = new Date(tariff2.createdAt).getTime()
	return date1 - date2
}
