import { useState } from "react"
import { InputAdornment, TextField, Typography, useTheme } from "@mui/material"

import type { ChangeEvent } from "react"
import { CustomPrivilege } from "@frontend/kitui"

interface Props {
    id: string
    value: number
    adornmentText: string
    privilege: CustomPrivilege
    onChange: (value: number) => void
}

const sliderSettingsByType = {
    день: { max: 365, min: 0 },
    шаблон: { max: 5000, min: 0 },
    МБ: { max: 5000, min: 0 },
    заявка: { max: 5000, min: 0 }
}

export default function NumberInputWithUnitAdornment({ id, value, adornmentText, privilege, onChange }: Props) {
    const theme = useTheme()
    const [changed, setChanged] = useState<boolean>(false)

    return (
        <TextField
            type="number"
            size="small"
            placeholder="Введите вручную"
            id={id}
            onBlur={(e) => {
                e.target.value = String(Number(String(e.target.value).replace(/^0+(?=\d\.)/, "")))
            }}
            value={changed ? (value !== sliderSettingsByType[privilege.value]?.min ? parseInt(String(value), 10) : sliderSettingsByType[privilege.value]?.min) : ""}
            onChange={({ target }: ChangeEvent<HTMLInputElement>) => {
                if (!changed) {
                    setChanged(true)
                }

                if (Number(target.value) > 999999) {
                    target.value = "999999"
                }

                const newNumber = parseInt(target.value)


                if (!isFinite(newNumber) || newNumber < 0) {
                    onChange(sliderSettingsByType[privilege.value]?.min)

                    return
                }

                onChange(newNumber)
            }}
            sx={{
                maxWidth: "200px",
                minWidth: "200px",
                ".MuiInputBase-root": {
                    display: "flex",
                    pr: 0,
                    height: "48px",
                    borderRadius: "8px",
                    backgroundColor: "#F2F3F7",
                    fieldset: {
                        border: "1px solid" + theme.palette.gray.main,
                    },
                    "&.Mui-focused fieldset": {
                        borderColor: theme.palette.purple.main,
                    },
                    input: {
                        height: "31px",
                        borderRight: !changed ? "none" : "1px solid #9A9AAF",
                    },
                    "&.Mui-focused input": {
                        borderRight: "1px solid #9A9AAF",
                    },
                    "&:not(.Mui-focused) .MuiInputAdornment-root": {
                        display: !changed ? "none" : undefined,
                    },
                    "&.Mui-focused ::-webkit-input-placeholder": {
                        color: "transparent",
                    },

                    // Hiding arrows
                    "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button": {
                        WebkitAppearance: "none",
                        margin: 0,
                    },
                    "input[type = number]": {
                        MozAppearance: "textfield",
                    },
                },
            }}
            InputProps={{
                endAdornment: (
                    <InputAdornment
                        position="end"
                        sx={{
                            userSelect: "none",
                            pointerEvents: "none",
                            pl: "2px",
                            pr: "13px",
                        }}
                    >
                        <Typography variant="body2" color="#4D4D4D">
                            {adornmentText}
                        </Typography>
                    </InputAdornment>
                ),
            }}
        />
    )
}
