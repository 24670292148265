import { Box, Button, IconButton, Typography, useMediaQuery, useTheme } from "@mui/material"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import WalletIcon from "@components/icons/WalletIcon"
import SectionWrapper from "@components/SectionWrapper"
import { cardShadow } from "@root/utils/theme"
import { currencyFormatter } from "@root/utils/currencyFormatter"
import { useUserStore } from "@root/stores/user"
import { Link } from "react-router-dom"

import { useHistoryTracker } from "@root/utils/hooks/useHistoryTracker"

export default function Wallet() {
	const handleCustomBackNavigation = useHistoryTracker()
	const theme = useTheme()
	const upMd = useMediaQuery(theme.breakpoints.up("md"))
	const isTablet = useMediaQuery(theme.breakpoints.down(1000))
	const isMobile = useMediaQuery(theme.breakpoints.down(600))
	const cash = useUserStore((state) => state.userAccount?.wallet.cash) ?? 0

	const footnotes = (
		<Box
			component="ol"
			sx={{
				color: theme.palette.gray.main,
				pt: "10px",
				pl: "25px",

				mt: 0,
				mb: upMd ? "3px" : "73px",
			}}
		>
			<Typography
				component="li"
				sx={{
					fontSize: "16px",
					lineHeight: "20px",
					fontWeight: 400,
				}}
			>
        Текст для сносок: текст-заполнитель — это текст, который имеет текст-заполнитель — это текст, который имеет
			</Typography>
			<Typography
				component="li"
				sx={{
					fontSize: "16px",
					lineHeight: "20px",
					fontWeight: 400,
				}}
			>
        Текст для сносок: тель — это текст, который имеет текст-заполнитель — это текст, который имеет
			</Typography>
		</Box>
	)

	return (
		<SectionWrapper
			maxWidth="lg"
			sx={{
				mt: "25px",
				mb: "70px",
				px: isTablet ? (isMobile ? "18px" : "40px") : "20px",
			}}
		>
			<Box
				sx={{
					mt: "20px",
					mb: "40px",
					display: "flex",
					gap: "10px",
				}}
			>
				{!upMd && (
					<IconButton onClick={handleCustomBackNavigation} sx={{ p: 0, height: "28px", width: "28px", color: "black" }}>
						<ArrowBackIcon />
					</IconButton>
				)}
				<Typography variant="h4">Мой кошелёк</Typography>
			</Box>
			<Box
				sx={{
					backgroundColor: "white",
					display: "flex",
					flexDirection: upMd ? "row" : "column",
					gap: "9%",
					borderRadius: "12px",
					mb: "40px",
					boxShadow: cardShadow,
				}}
			>
				<Box
					sx={{
						width: upMd ? "59.5%" : undefined,
						p: "20px",
						pb: upMd ? undefined : "10px",
					}}
				>
					<Typography sx={{ color: theme.palette.gray.dark, mb: "30px" }}>Текущий баланс</Typography>
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							gap: "22px",
							pb: "40px",
							borderBottom: `1px solid ${theme.palette.gray.main}`,
						}}
					>
						<WalletIcon bgcolor="#FEDFD0" color={theme.palette.orange.main} />
						<Typography variant="h5">{currencyFormatter.format(cash / 100)}</Typography>
					</Box>
					{/*{upMd && footnotes}*/}
				</Box>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						alignItems: "start",
						color: theme.palette.gray.dark,
						width: upMd ? "31.5%" : undefined,
						p: "20px",
						pl: upMd ? "33px" : undefined,
						borderLeft: upMd ? `1px solid ${theme.palette.gray.main}` : undefined,
					}}
				>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							gap: "22px",
							maxWidth: "85%",
							mb: "32px",
						}}
					>
						<Typography>Это ваш внутренний кошелёк, вы можете заранее его пополнить на любую сумму от 1000р и в любой момент использовать баланс.</Typography>
						<Typography>Вывод баланса с кошелька не предусмотрен</Typography>
					</Box>
					<Button variant="pena-contained-dark" component={Link} to="/payment" sx={{ mt: "auto" }}>
            Пополнить
					</Button>
				</Box>
			</Box>
			{/*{!upMd && footnotes}*/}
		</SectionWrapper>
	)
}
