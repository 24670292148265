import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Container,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import SectionWrapper from "../SectionWrapper";
import LogoutIcon from "../icons/LogoutIcon";
import DialogMenu from "./DialogMenu";
import WalletIcon from "../icons/WalletIcon";
import CustomAvatar from "./Avatar";
import Drawers from "../Drawers";
import PenaLogo from "../PenaLogo";
import Menu from "../Menu";
import { logout } from "@root/api/auth";
import { enqueueSnackbar } from "notistack";
import { clearUserData, useUserStore } from "@root/stores/user";
import {
  BurgerButton,
  clearAuthToken,
  getMessageFromFetchError,
} from "@frontend/kitui";
import { currencyFormatter } from "@root/utils/currencyFormatter";
import { clearCustomTariffs } from "@root/stores/customTariffs";
import { clearTickets } from "@root/stores/tickets";
import {setNotEnoughMoneyAmount} from "@stores/notEnoughMoneyAmount"

interface Props {
  isLoggedIn: boolean;
}

export default function NavbarFull({ isLoggedIn }: Props) {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const user = useUserStore((state) => state.user);
  const cash = useUserStore((state) => state.userAccount?.wallet.cash) ?? 0;
  const isTablet = useMediaQuery(theme.breakpoints.up(1000));
  const [open, setOpen] = useState(false);

  async function handleLogoutClick() {
    clearAuthToken();
    clearUserData();
    clearCustomTariffs();
    clearTickets();
    setNotEnoughMoneyAmount(0)
    navigate("/");

    const [_, logoutError] = await logout();

    if (logoutError) {
      return enqueueSnackbar(logoutError);
    }
  }

  return isLoggedIn ? (
    <Container
      component="nav"
      disableGutters
      maxWidth={false}
      sx={{
        zIndex: "1",
        px: "16px",
        display: "flex",
        height: "79px",
        alignItems: "center",
        gap: "60px",
        bgcolor: "white",
        borderBottom: "1px solid #E3E3E3",
      }}
    >
      <Link to="/">
        <PenaLogo width={124} color="white" />
      </Link>
      <Menu />
      <Box
        sx={{
          display: "flex",
          ml: "auto",
        }}
      >
        <Drawers />
        <IconButton
          sx={{ p: 0, ml: "8px" }}
          onClick={() => navigate("/wallet")}
        >
          <WalletIcon color={theme.palette.gray.main} bgcolor="#F2F3F7" />
        </IconButton>
        <Box sx={{ ml: "8px", whiteSpace: "nowrap" }}>
          <Typography
            sx={{
              fontSize: "12px",
              lineHeight: "14px",
              color: theme.palette.gray.dark,
            }}
          >
            Мой баланс
          </Typography>
          <Typography variant="body2" color={theme.palette.purple.main}>
            {currencyFormatter.format(cash / 100)}
          </Typography>
        </Box>
        <CustomAvatar />
        <IconButton
          onClick={handleLogoutClick}
          sx={{
            ml: "20px",
            bgcolor: "#F2F3F7",
            borderRadius: "6px",
            height: "36px",
            width: "36px",
          }}
        >
          <LogoutIcon />
        </IconButton>
      </Box>
    </Container>
  ) : (
    <>
      <SectionWrapper
        component="nav"
        maxWidth="lg"
        outerContainerSx={{
          zIndex: "1",
          position: "fixed",
          top: "0",
          backgroundColor: theme.palette.bg.main,
          borderBottom: "1px solid #E3E3E3",
        }}
        sx={{
          px: "20px",
          display: "flex",
          justifyContent: "space-between",
          height: "79px",
          alignItems: "center",
          gap: "50px",
        }}
      >
        <Box
          width="457px"
          justifyContent="space-between"
          display="inline-flex"
          alignItems="center"
        >
          <PenaLogo width={150} color="white" />
          {!isTablet ? null : <Menu />}
        </Box>
        {user && (
          <Button
            component={Link}
            to={"/tariffs"}
            state={undefined}
            variant="pena-contained-dark"
            sx={{ px: "30px", ml: !isTablet ? "auto" : "none" }}
          >
            Личный кабинет
          </Button>
        )}
        {!user && (
          <Button
            component={Link}
            to={"/signin"}
            state={{ backgroundLocation: location }}
            variant="pena-contained-dark"
            sx={{ px: "30px", ml: !isTablet ? "auto" : "none" }}
          >
            Регистрация / Войти
          </Button>
        )}

        <BurgerButton
          onClick={() => setOpen(!open)}
          sx={{ color: "white", display: !isTablet ? "block" : "none" }}
        />
      </SectionWrapper>
      <DialogMenu open={open} handleClose={() => setOpen(false)} />
    </>
  );
}
