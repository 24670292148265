import {Box, Container, Typography} from "@mui/material"
import {Link} from "react-router-dom"
import PenaLogo from "@components/PenaLogo"
import React from "react"

export default function PPofData() {
	return (
		<>
			<Container
				component="nav"
				disableGutters
				maxWidth={false}
				sx={{
					zIndex: "1",
					px: "16px",
					display: "flex",
					height: "79px",
					alignItems: "center",
					gap: "60px",
					bgcolor: "white",
					borderBottom: "1px solid #E3E3E3",
				}}
			>
				<Link to="/">
					<PenaLogo width={124} color="black" />
				</Link>
			</Container>
			<Box
				sx={{
					overflow: "auto",
					width: "100%",
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					height: "100vh",
					paddingTop: "20px",
					backgroundColor: "white"
				}}
			>
				<Box
					sx={{
						maxWidth: "1440px",
						padding: " 15px 30px",
						"*": {
							textAlign: "justify",
							fontSize: "14px",
						},
						p: {
							textIndent: "35px",
						},
						li: {
							textIndent: "35px",
							textAlign: "justify",
							fontSize: "14px",
						},
					}}
				>
					<Typography variant="h5" sx={{ textAlign: "center" }}>
						ПОЛИТИКА ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ
					</Typography>
					<Box>
						<Typography>
							ООО «ПЕНА» (далее – «Оператор») уведомляет, что является оператором ваших персональных данных
							и будет обрабатывать предоставленные персональные данные в соответствии с положениями Федерального
							закона Российской Федерации №152-ФЗ от 27.07.2006 «О персональных данных», Политики конфиденциальности
							и Договора оферты сервиса «Pena Quiz» (далее – Сервис, Сервис «Pena Quiz»).
						</Typography>
						<Typography>
							Настоящее согласие предоставляется путем заполнения «чек-бокса» (проставления «галочки»/ «веб-метки»
							в графе «Согласен на обработку персональных данных и ознакомлен с условиями Политики конфиденциальности»)
							и нажатия соответствующей кнопки.
						</Typography>
						<Typography>
							Заявление   об   отзыве   настоящего   согласия   может   быть   направлено Оператору,
							путем  выражения своего намерения на e-mail inbox@pena.digital и дальнейшему следованию полученной инструкции
						</Typography>
						<Typography marginTop={"25px"}>
							Я выражаю <b style={{fontSize: "18px",}}>свое согласие на обработку</b>, включая: сбор, запись, систематизацию, накопление, хранение,
							уточнение (обновление, изменение), извлечение, использование, передачу (предоставление, доступ),
							блокирование, обезличивание, удаление, уничтожение,
						</Typography>
						<Typography>
							<b style={{fontSize: "18px",}}>своих персональных данных:</b> любых предоставленных мной через «квиз» данных, в том числе:
							фамилии, имени, отчества; пола; возраста; даты рождения; места рождения; гражданства;
							адреса проживания / регистрации; адреса электронной почты; номера телефона; ID в соцсетях;
							сведений о доходе; потребительских предпочтениях и других предоставленных мной самостоятельно данных,
						</Typography>
						<Typography>
							<b style={{fontSize: "18px",}}>оператором:</b> Общество с ограниченной ответственностью "Пена" (ООО "Пена")
						</Typography>
						<Typography>
							ИНН/КПП: 2543126056/254001001
						</Typography>
						<Typography>
							ОГРН: 1182536017993
						</Typography>
						<Typography>
							Адрес: 690091, Приморский край, г.о. Владивостокский, г Владивосток, ул Алеутская, д. 28, офис 207
						</Typography>
						<Typography>
							Директор на основании Устава: Линдберг Иван Романович
						</Typography>
						<Typography>(Далее – Оператор)</Typography>
						<Typography>
							<b style={{fontSize: "18px",}}>в целях:</b> обеспечения коммуникации и взаимодействия между мной и создателями «квизов»
							(заказчиками), направления мне информационных и рекламных сообщений, продвижения товаров, работ,
							услуг партнеров Оператора (создателей «квизов» - заказчиков).
						</Typography>
						<Typography>
							Я согласен и разрешаю Оператору обрабатывать мои персональные данные с использованием средств
							автоматизации.
						</Typography>
						<Typography>
							Я согласен с тем, что мои персональные данные будут переданы третьим лицам – партнерам
							Оператора – создателям «квизов» (Заказчиков), через которые я предоставляю свои данные,
							для реализации целей обработки персональных данных. Без передачи данных партнерам Оператора
							реализация целей обработки персональных данных будет невозможна.
						</Typography>

						<Typography>
							Я согласен с тем, что, если это необходимо для реализации целей обработки персональных данных,
							Операторы имеют право поручить обработку персональных данных:
						</Typography>
						<li style={{fontSize: "18px", marginLeft: "100px"}}>
							оператору платформ для хранения данных (дата-центру): ООО «Яндекс.Облако»
							(ОГРН: 1187746678580, 119021, город Москва, ул. Льва Толстого, д. 16, помещ. 528).
						</li>
						<Typography>
							Настоящее Согласие действует до момента достижения целей обработки или отзыва согласия на
							обработку, но не более 3 (трех) лет с момента предоставления Согласия.
						</Typography>
					</Box>

				</Box>

			</Box>
		</>

	)
}