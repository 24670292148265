import { Box, Button, IconButton, Modal, Typography, useMediaQuery, useTheme } from "@mui/material"

import { Form, Formik, useFormik } from "formik"
import CustomTextField from "@components/CustomTextField"
import InfoButton from "@components/InfoButton";
import { sendContactFormRequest } from "@api/tariff"
import { TimePicker } from "@mui/x-date-pickers"
import moment, { Moment } from "moment"
import { enqueueSnackbar } from "notistack"
import { useState } from "react"
import CloseIcon from "@root/assets/Icons/CloseIcon";
import { useRequestSquizCreate, RSCClose } from "@root/stores/requestSquizCreate"


interface Values {
    contact: string;
    dogiebusiness: string;
    imagination: string;
    name: string;
    time?: Moment | null;
}
const initialValues: Values = {
    contact: "",//phone number
    // whoami: {},
    dogiebusiness: "",
    imagination: "",
    name: "",
    time: null
};

interface FP {
    title: string
    desc?: string
    placeholder: string
    value: string
    onChange: any
    rows?: number
}

const Field = ({
    title,
    desc,
    placeholder,
    value,
    onChange,
    rows,
}: FP) => {
    return (
        <Box
            sx={{
                m: "15px 0"

            }}
        >
            <Typography
                sx={{
                    fontSize: "18px",
                    fontWeight: 500,
                    lineHeight: "21.33px",
                    mb: "10px",
                }}
            >{title}</Typography>
            {desc && <Typography
                sx={{
                    fontSize: "18px",
                    mb: "10px",
                    color: "#9A9AAF"
                }}
            >{desc}</Typography>}
            <CustomTextField
                value={value}
                placeholder={placeholder}
                maxLength={200}
                onChange={onChange}
                rows={rows || 0}
                sx={rows !== undefined ? { height: "68px" } : {}}
            />
        </Box>
    )
}

export const ModalRequestCreate = () => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down(650));
    const [isSending, setIsSending] = useState(false)
    const open = useRequestSquizCreate(store => store.isRSCOpen)
    console.log(open)

    if (isSending) return (
        <Modal
            open={open}
            onClose={() => {
                RSCClose()
                setIsSending(false)
            }}
            
        >
            <Box sx={{
                position: 'absolute' as 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                bgcolor: 'background.paper',
                boxShadow: 24,
                width: isMobile ? "343px" : "418px",
                borderRadius: "10px",
                p: "50px"
            }}>
                <Typography
                    sx={{
                        fontSize: "24px",
                        fontWeight: 500,
                        lineHeight: "28.44px",
                        textAlign: "center"
                    }}
                >Спасибо за заявку!</Typography>
                <Typography
                    sx={{
                        fontSize: "18px",
                        lineHeight: "21.33px",
                        color: "#4D4D4D",
                        m: "5px 0 30px 0"

                    }}
                >С вами свяжутся в течение 24 часов</Typography>
                <Button
                    variant="contained"
                    fullWidth
                    onClick={() => {
                        RSCClose()
                        setIsSending(false)
                    }}
                    sx={{
                        py: "12px",
                        bgcolor: "rgb(126, 42, 234)",
                        borderRadius: "8px",
                        "&:hover": {
                            backgroundColor: "#581CA7",
                        },
                        "&:active": {
                            color: "white",
                            backgroundColor: "black",
                        },
                    }}
                >Ок</Button>

            </Box>
        </Modal>
    )
    return (
        <Modal
            open={open}
            onClose={RSCClose}
        >
            <Box sx={{
                position: 'absolute' as 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                bgcolor: 'background.paper',
                boxShadow: 24,
                width: isMobile ? "344px" : "620px",
                borderRadius: "10px"
            }}>
                <Box>
                    <Box
                        sx={{
                            width: "100%",
                            height: "68px",
                            backgroundColor: theme.palette.background.default,
                            borderRadius: "10px 10px 0 0"
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: "18px",
                                padding: "20px",
                                color: "rgb(154, 154, 175)",
                                borderRadius: "10px 10px 0 0"
                            }}
                        >
                            Заполните форму, чтобы оставить заявку на создание квиза
                        </Typography>
                    </Box>
                    <IconButton
                        onClick={RSCClose}
                        sx={{
                            width: "12px",
                            height: "12px",
                            position: "absolute",
                            right: "15px",
                            top: "15px",
                        }}
                    >
                        <CloseIcon sx={{ width: "12px", height: "12px", transform: "scale(1.5)" }} />
                    </IconButton>
                </Box>
                <Box
                    sx={{
                        p: "15px 70px 50px 50px",
                    }}
                >
                    <Formik
                        initialValues={initialValues}
                        onSubmit={async (values, formikHelpers) => {
                            if (values.contact.length < 8) return enqueueSnackbar("Пожалуйста, оставьте контактные данные")
                            const resp = await sendContactFormRequest({
                                contact: values.contact,
                                whoami: JSON.stringify({
                                    dogiebusiness: values.dogiebusiness,
                                    imagination: values.imagination,
                                    name: values.name,
                                    time: moment(values.time).format("hh:mm")
                                })
                            })
                            //@ts-ignore
                            if (resp[0]?.status === 200) {
                                enqueueSnackbar("Запрос успешно отправлен")
                                setIsSending(true)
                            }
                            if (resp[1]) {
                                //@ts-ignore
                                enqueueSnackbar(resp[1])
                            }
                        }}
                    >
                        {({ values, isSubmitting, setFieldValue }) => (<>
                            <Form>
                                <Field
                                    title="Ваше имя"
                                    placeholder="Иван"
                                    value={values.name}
                                    onChange={({ target }: any) => setFieldValue("name", target.value)}

                                />
                                <Field
                                    title="Какой у вас бизнес?"
                                    placeholder="Логистика"
                                    value={values.dogiebusiness}
                                    onChange={({ target }: any) => setFieldValue("dogiebusiness", target.value)}

                                />
                                <Field
                                    title="Ваши контактные данные"
                                    placeholder="Не менее 8 символов"
                                    value={values.contact}
                                    onChange={({ target }: any) => setFieldValue("contact", target.value)}
                                    desc="(Telegram, WhatsApp, номер телефона)"
                                />
                                <Field
                                    title="Ваши пожелания к квизу"
                                    placeholder="Введите свой текст здесь"
                                    value={values.imagination}
                                    onChange={({ target }: any) => setFieldValue("imagination", target.value)}
                                    rows={2}
                                />

                                <Box
                                    sx={{
                                        m: "15px 0"
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: "18px",
                                            fontWeight: 500,
                                            lineHeight: "21.33px",
                                            mb: "10px",
                                        }}
                                    >Во сколько вам можно позвонить? </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: "18px",
                                            mb: "10px",
                                            color: "#9A9AAF"
                                        }}
                                    >Москва (GMT+3)</Typography>

                                    <TimePicker
                                        onChange={(e) => setFieldValue("time", e)}
                                        ampm={false}
                                        value={values.time}
                                        views={['hours', 'minutes']} format="hh:mm"
                                        sx={{
                                            border: "#c4c4c4 1px solid",
                                            borderRadius: "4px",
                                            "& .MuiInputBase-root": {
                                                display: "flex"
                                            }
                                        }}
                                    />

                                </Box>

                                <Box
                                    sx={{
                                        display: "flex"
                                    }}>
                                    <Button
                                        variant="contained"
                                        fullWidth
                                        type="submit"
                                        disabled={isSubmitting}
                                        sx={{
                                            py: "12px",
                                            bgcolor: "rgb(126, 42, 234)",
                                            borderRadius: "8px",
                                            "&:hover": {
                                                backgroundColor: "#581CA7",
                                            },
                                            "&:active": {
                                                color: "white",
                                                backgroundColor: "black",
                                            },
                                        }}
                                    >Отправить</Button>
                                    <InfoButton sx={{
                                        ml: "15px",
                                        width: "48px"
                                    }} />
                                </Box>
                            </Form>
                        </>)}
                    </Formik>
                </Box>
            </Box>
        </Modal >
    )
}
