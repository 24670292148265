import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const useHistoryTracker = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.state?.previousUrl) {
      localStorage.setItem(
        "history",
        JSON.stringify([location.state.previousUrl])
      );
    }
  }, [location]);

  const handleCustomBackNavigation = () => {
    const historyJSON = localStorage.getItem("history");

    if (historyJSON) {
      const history = JSON.parse(historyJSON);

      if (history.length > 0) {
        if (history[history.length - 1].includes("/support/")) {
          navigate(-2);

          return;
        }

        navigate(-1);
      }

      return;
    }

    navigate(-1);
  };

  return handleCustomBackNavigation;
};
