import {
  Box,
  Dialog,
  IconButton,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import InputTextfield from "@components/InputTextfield";
import PenaLogo from "@components/PenaLogo";
import { enqueueSnackbar } from "notistack";
import { Link as RouterLink } from "react-router-dom";
import { object, string } from "yup";
import { useEffect, useState } from "react";
import { setUserId, useUserStore } from "@root/stores/user";
import { cardShadow } from "@root/utils/theme";
import AmoButton from "./AmoButton";
import { recover } from "@root/api/auth";

interface Values {
  email: string;
}

const initialValues: Values = {
  email: "",
};

const validationSchema = object({
  email: string()
    .required("Поле обязательно")
    .email("Введите корректный email"),
});

export default function RecoverDialog() {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(true);
  const user = useUserStore((state) => state.user);
  const theme = useTheme();
  const upMd = useMediaQuery(theme.breakpoints.up("md"));
  const navigate = useNavigate();
  const location = useLocation();
  const formik = useFormik<Values>({
    initialValues,
    validationSchema,
    onSubmit: async (values, formikHelpers) => {
      const [, recoverError] = await recover(values.email.trim());

      formikHelpers.setSubmitting(false);
      if (recoverError) {
        enqueueSnackbar(recoverError);
        return;
      }
      navigate("/");
      enqueueSnackbar("Письмо придёт Вам на почту");
    },
  });

  useEffect(
    function redirectIfSignedIn() {
      if (user) navigate("/tariffs", { replace: true });
    },
    [navigate, user]
  );

  function handleClose() {
    setIsDialogOpen(false);
    setTimeout(() => navigate("/"), theme.transitions.duration.leavingScreen);
  }

  return (
    <Dialog
      open={isDialogOpen}
      onClose={handleClose}
      PaperProps={{
        sx: {
          width: "600px",
          maxWidth: "600px",
        },
      }}
      slotProps={{
        backdrop: {
          style: {
            backgroundColor: "rgb(0 0 0 / 0.7)",
          },
        },
      }}
    >
      <Box
        component="form"
        onSubmit={formik.handleSubmit}
        noValidate
        sx={{
          position: "relative",
          backgroundColor: "white",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          p: upMd ? "50px" : "18px",
          pb: upMd ? "40px" : "30px",
          gap: "15px",
          borderRadius: "12px",
          boxShadow: cardShadow,
          "& .MuiFormHelperText-root.Mui-error, & .MuiFormHelperText-root.Mui-error.MuiFormHelperText-filled":
            {
              position: "absolute",
              top: "46px",
              margin: "0",
            },
        }}
      >
        <IconButton
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: "7px",
            top: "7px",
          }}
        >
          <CloseIcon sx={{ transform: "scale(1.5)" }} />
        </IconButton>
        <Box>
          <PenaLogo width={upMd ? 233 : 196} color="black" />
        </Box>
        <Typography
          sx={{
            color: theme.palette.gray.dark,
            mt: "5px",
            mb: upMd ? "10px" : "33px",
          }}
        >
          Восстановление пароля
        </Typography>
        <InputTextfield
          TextfieldProps={{
            value: formik.values.email,
            placeholder: "username",
            onBlur: formik.handleBlur,
            error: formik.touched.email && Boolean(formik.errors.email),
            helperText: formik.touched.email && formik.errors.email,
          }}
          onChange={formik.handleChange}
          color="#F2F3F7"
          id="email"
          label="Email"
          gap={upMd ? "10px" : "10px"}
        />
        <Button
          variant="pena-contained-dark"
          fullWidth
          type="submit"
          disabled={formik.isSubmitting}
          sx={{
            py: "12px",
            "&:hover": {
              backgroundColor: theme.palette.purple.dark,
            },
            "&:active": {
              color: "white",
              backgroundColor: "black",
            },
          }}
        >
          Восстановить
        </Button>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "10px",
            mt: "auto",
          }}
        >
          <Link
            component={RouterLink}
            to="/signin"
            state={{ backgroundLocation: location.state.backgroundLocation }}
            sx={{ color: theme.palette.purple.main }}
          >
            Вход в личный кабинет
          </Link>
        </Box>
      </Box>
    </Dialog>
  );
}
