import { Box } from "@mui/material"


export default function CloseSmallIcon() {

	return (
		<Box sx={{
			width: "24px",
			height: "24px",
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			flexShrink: 0,
		}}>
			<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M18 6L6 18" stroke="#A9AAB1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
				<path d="M6 6L18 18" stroke="#A9AAB1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
			</svg>
		</Box>
	)
}