import { Box } from "@mui/material"

interface Props {
  color: string;
  bgcolor: string;
}

export default function SendIcon({ color, bgcolor }: Props) {
	return (
		<Box
			sx={{
				bgcolor,
				borderRadius: "6px",
				height: "36px",
				width: "36px",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				ml: "8px",
			}}
		>
			<svg
				width="22"
				height="19"
				viewBox="0 0 22 19"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M19.5714 7.29857V2.29857C19.5714 1.50959 18.9318 0.869996 18.1429 0.869996L2.42857 0.869995C1.63959 0.869995 1 1.50959 1 2.29857L1 16.5843C1 17.3733 1.63959 18.0128 2.42857 18.0128L18.1429 18.0128C18.9318 18.0128 19.5714 17.3733 19.5714 16.5843V11.5843M20.901 6.58428H13.8571C12.2792 6.58428 11 7.86347 11 9.44142C11 11.0194 12.2792 12.2986 13.8571 12.2986H20.901C20.9557 12.2986 21 12.2542 21 12.1996V6.68329C21 6.62861 20.9557 6.58428 20.901 6.58428Z"
					stroke={color}
					strokeWidth="1.5"
				/>
			</svg>
		</Box>
	)
}
