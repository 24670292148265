import { Box } from "@mui/material"

interface Props {
  color: string;
  bgcolor: string;
}

export default function CalendarIcon({ color, bgcolor }: Props) {
	return (
		<Box
			sx={{
				bgcolor,
				height: "36px",
				width: "36px",
				minWidth: "36px",
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				borderRadius: "6px",
			}}
		>
			<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
				<path
					d="M19.502 3.75455H4.50195C4.08774 3.75455 3.75195 4.09033 3.75195 4.50455V19.5045C3.75195 19.9188 4.08774 20.2545 4.50195 20.2545H19.502C19.9162 20.2545 20.252 19.9188 20.252 19.5045V4.50455C20.252 4.09033 19.9162 3.75455 19.502 3.75455Z"
					stroke={color}
					strokeWidth="1.5"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M16.502 2.25455V5.25455"
					stroke={color}
					strokeWidth="1.5"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M7.50195 2.25455V5.25455"
					stroke={color}
					strokeWidth="1.5"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M3.75195 8.25455H20.252"
					stroke={color}
					strokeWidth="1.5"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M8.62695 12.0045H11.252L9.75195 13.8795C9.99881 13.8791 10.242 13.9396 10.4598 14.0557C10.6777 14.1718 10.8636 14.3398 11.0009 14.545C11.1383 14.7501 11.2229 14.9859 11.2472 15.2316C11.2716 15.4773 11.2349 15.7251 11.1405 15.9532C11.0461 16.1813 10.8968 16.3826 10.706 16.5392C10.5151 16.6958 10.2886 16.8028 10.0465 16.8509C9.80431 16.8989 9.55405 16.8864 9.31788 16.8146C9.08171 16.7427 8.86692 16.6137 8.69258 16.4389"
					stroke={color}
					strokeWidth="1.5"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M13.502 13.1295L15.002 12.0045V16.8795"
					stroke={color}
					strokeWidth="1.5"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</svg>
		</Box>
	)
}
