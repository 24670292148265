import { IconButton, SxProps } from "@mui/material";

type InfoProps = {
  width?: number;
  height?: number;
  sx?: SxProps;
  onClick?: any;
  className?: string;
  color?: string;
};

export default function InfoButton({
  width = 20,
  height = 20,
  sx,
  onClick,
  className,
  color = "#7e2aea",
}: InfoProps) {
  return (
    <IconButton sx={sx} className={className} onClick={onClick}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10C1 14.9706 5.02944 19 10 19Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.25 9.25H10V14.5H10.75"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.8125 7C10.4338 7 10.9375 6.49632 10.9375 5.875C10.9375 5.25368 10.4338 4.75 9.8125 4.75C9.19118 4.75 8.6875 5.25368 8.6875 5.875C8.6875 6.49632 9.19118 7 9.8125 7Z"
          fill={color}
        />
      </svg>
    </IconButton>
  );
}
