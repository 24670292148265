import { Box, useTheme } from "@mui/material"

type LoaderProps = {
  size?: number;
};

export const Loader = ({ size = 40 }: LoaderProps) => {
	const theme = useTheme()

	return (
		<Box
			sx={{
				width: size,
				height: size,
				border: `${size / 6}px solid #ffffff`,
				borderRadius: "50%",
				borderTop: `${size / 6}px solid ${theme.palette.purple.main}`,
				animation: "spin 2s linear infinite",
				"@-webkit-keyframes spin": {
					"0%": { transform: "rotate(0deg)" },
					"100%": { transform: "rotate(360deg)" },
				},
				"@keyframes spin": {
					"0%": { transform: "rotate(0deg)" },
					"100%": { transform: "rotate(360deg)" },
				},
			}}
		/>
	)
}
