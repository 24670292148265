import {
	Box,
	Dialog,
	IconButton,
	Typography,
	useMediaQuery,
	useTheme,
	Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useLocation, useNavigate } from "react-router-dom";
import PenaLogo from "@components/PenaLogo";
import { useEffect, useState } from "react";
import { useUserStore } from "@root/stores/user";
import { cardShadow } from "@root/utils/theme";


export default function OutdatedLink() {
	const [isDialogOpen, setIsDialogOpen] = useState<boolean>(true);
	const user = useUserStore((state) => state.user);
	const theme = useTheme();
	const upMd = useMediaQuery(theme.breakpoints.up("md"));
	const navigate = useNavigate();
	const location = useLocation();

	useEffect(
		function redirectIfSignedIn() {
			if (user) navigate("/tariffs", { replace: true });
		},
		[navigate, user]
	);

	function handleClose() {
		setIsDialogOpen(false);
		setTimeout(() => navigate("/"), theme.transitions.duration.leavingScreen);
	}

	return (
		<Dialog
			open={isDialogOpen}
			onClose={handleClose}
			PaperProps={{
				sx: {
					width: "600px",
					maxWidth: "600px",
				},
			}}
			slotProps={{
				backdrop: {
					style: {
						backgroundColor: "rgb(0 0 0 / 0.7)",
					},
				},
			}}
		>
			<Box
				component="form"
				sx={{
					position: "relative",
					backgroundColor: "white",
					display: "flex",
					alignItems: "center",
					flexDirection: "column",
					p: upMd ? "50px" : "18px",
					pb: upMd ? "40px" : "30px",
					gap: "15px",
					borderRadius: "12px",
					boxShadow: cardShadow,
					"& .MuiFormHelperText-root.Mui-error, & .MuiFormHelperText-root.Mui-error.MuiFormHelperText-filled":
						{
							position: "absolute",
							top: "46px",
							margin: "0",
						},
				}}
			>
				<IconButton
					onClick={handleClose}
					sx={{
						position: "absolute",
						right: "7px",
						top: "7px",
					}}
				>
					<CloseIcon sx={{ transform: "scale(1.5)" }} />
				</IconButton>
				<Box>
					<PenaLogo width={upMd ? 233 : 196} color="black" />
				</Box>
				<Typography
					sx={{
						color: theme.palette.gray.dark,
						mt: "5px",
						mb: upMd ? "10px" : "33px",
					}}
				>
					Внимание! Ссылка устарела!
				</Typography>
				<Typography
					sx={{
						color: theme.palette.gray.dark,
						mt: "5px",
						mb: upMd ? "10px" : "33px",
					}}
				>
					Срок действия ссылки истёк, пожалуйста повторите попытку восстановления пароля
				</Typography>
				<Button
					variant="pena-contained-dark"
					fullWidth
					onClick={()=> navigate("/")}
					sx={{
						py: "12px",
						"&:hover": {
							backgroundColor: theme.palette.purple.dark,
						},
						"&:active": {
							color: "white",
							backgroundColor: "black",
						},
					}}
				>
					На главную
				</Button>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						gap: "10px",
						mt: "auto",
					}}
				>
				</Box>
			</Box>
		</Dialog>
	);
}
