import { Tariff } from "@frontend/kitui";
import { parseAxiosError } from "@root/utils/parse-error";
import makeRequest from "@api/makeRequest";

export interface GetHistoryResponse {
  totalPages: number;
  records: HistoryRecord[];
}

export type HistoryRecord = {
  comment: string;
  createdAt: string;
  id: string;
  isDeleted: boolean;
  key: string;
  rawDetails: [RawDetails, KeyValue];
  updatedAt: string;
  userId: string;
};

export interface GetHistoryResponse2 {
  totalPages: number;
  records: HistoryRecord2[];
}

export type HistoryRecord2 = {
  comment: string;
  createdAt: string;
  id: string;
  isDeleted: boolean;
  key: string;
  rawDetails: {
    price: number;
    tariffs: Tariff[];
  };
  updatedAt: string;
  userId: string;
};

export type KeyValue = { Key: string; Value: string | number };

export type RawDetails = {
  Key: "tariffs" | "price";
  Value: string | number | KeyValue[][];
};

const API_URL = `${process.env.REACT_APP_DOMAIN}/customer/v1.0.1`;

export const getHistory = async (): Promise<
  [GetHistoryResponse | GetHistoryResponse2 | null, string?]
> => {
  try {
    const historyResponse = await makeRequest<
      never,
      GetHistoryResponse | GetHistoryResponse2
    >({
      method: "GET",
      url: `${API_URL}/history?page=1&limit=100&type=payCart`,
      useToken: true,
    });

    if (!Array.isArray(historyResponse.records[0]?.rawDetails)) {
      return [historyResponse] as [GetHistoryResponse2];
    }

    const checked = historyResponse.records.map((data) => {
      const checkedRowDetails = [
        (data.rawDetails as HistoryRecord["rawDetails"]).find(
          (details) => details.Key === "tariffs"
        ) as RawDetails,
        (data.rawDetails as HistoryRecord["rawDetails"]).find(
          (details) => details.Key === "price"
        ) as KeyValue,
      ];

      return { ...data, rawDetails: checkedRowDetails } as HistoryRecord;
    });

    historyResponse.records = checked || [];
    return [historyResponse];
  } catch (nativeError) {
    const [error] = parseAxiosError(nativeError);

    return [null, `Не удалось получить историю. ${error}`];
  }
};

export const sendReport = async (
  id: string
): Promise<[void | null, string?]> => {
  debugger;
  try {
    const sendReportResponse = await makeRequest<{ id: string }, void>({
      method: "POST",
      url: `${API_URL}/sendReport`,
      body: { id },
    });

    return [sendReportResponse];
  } catch (nativeError) {
    const [error] = parseAxiosError(nativeError);

    return [null, `Не удалось отправить отчёт. ${error}`];
  }
};

export const sendReportById = async (
  tariffId: string
): Promise<[void | null, string?]> => {
  debugger;
  try {
    const sendReportResponse = await makeRequest<never, void>({
      method: "POST",
      url: `${API_URL}/sendReport/${tariffId}`,
    });

    return [sendReportResponse];
  } catch (nativeError) {
    const [error] = parseAxiosError(nativeError);

    return [null, `Не удалось отправить отчёт. ${error}`];
  }
};
