import axios from "axios";
import {
  Box,
  IconButton,
  SxProps,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";
import { Document, Page } from "react-pdf";
import { Buffer } from "buffer";
import { downloadFileToDevice } from "@root/utils/downloadFileToDevice";
import EditIcon from "@mui/icons-material/Edit";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { SendDocumentsArgs, Verification } from "@root/model/auth";
import { updateDocument } from "@api/verification";
import { jsonToFormdata } from "@utils/jsonToFormdata";
import { parseAxiosError } from "@utils/parse-error";
import { readFile } from "@root/utils/readFile";
import { enqueueSnackbar } from "notistack";

type KeyNames = "inn" | "rule" | "certificate";
interface Props {
  text: string;
  documentUrl: string;
  sx?: SxProps<Theme>;
  keyName: KeyNames;
}

export default function DocumentItem({
  text,
  documentUrl = "",
  sx,
  keyName,
}: Props) {
  const theme = useTheme();

  const fileInputRef = useRef<HTMLInputElement>(null);

  function handleChooseFileClick() {
    fileInputRef.current?.click();
  }

  const downloadFile = async () => {
    const { data } = await axios.get<ArrayBuffer>(documentUrl, {
      responseType: "arraybuffer",
    });

    if (!data) {
      return;
    }

    downloadFileToDevice(
      `${documentUrl.split("/").pop()?.split(".")?.[0] || "document"}.pdf`,
      Buffer.from(data)
    );

    return;
  };

	const [readyShowDocument, setReadyShowDocument] = useState(false);

	useEffect(() => {
		if (typeof documentUrl === 'string') {
			if (!documentUrl.includes("pena.digital")) {
				fetch(documentUrl)
					.then(e => {
						setReadyShowDocument(true)
					})
					.catch(e => console.log(e))
			}
		}
	}, [])

  async function sendDocument(e: ChangeEvent<HTMLInputElement>) {
    const target = e.target as HTMLInputElement;
    const file = target?.files?.[0] || null;
    if (file !== null) {
      const readedFile = await readFile(file, "binary");
      const [, updateDocumentError] = await updateDocument(
        jsonToFormdata({ [keyName]: readedFile })
      );

      if (updateDocumentError) {
        return enqueueSnackbar(
          `Ошибка отправки документов. ${updateDocumentError}`
        );
      }

      enqueueSnackbar("Данные обновлены");
    }
  }
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        gap: "10px",
        ...sx,
      }}
    >
      <Typography
        sx={{
          color: "#4D4D4D",
          fontWeight: 500,
          fontVariantNumeric: "tabular-nums",
        }}
      >
        {text}
      </Typography>
      {documentUrl && readyShowDocument && (
        <>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              sx={{ color: theme.palette.purple.main, cursor: "pointer" }}
              onClick={downloadFile}
            >
              {documentUrl.split("/").pop()?.split(".")?.[0]}
            </Typography>
            <IconButton onClick={handleChooseFileClick}>
              <EditIcon sx={{ color: theme.palette.purple.main }} />
            </IconButton>
            <input
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={sendDocument}
              type="file"
              id="image-file"
              multiple
              accept={"application/pdf"}
            />
          </Box>

          <Document file={documentUrl} className={"cnvs"}>
            <Page
              pageNumber={1}
              width={80}
              renderTextLayer={false}
              renderAnnotationLayer={false}
            />
          </Document>
        </>
      )}
    </Box>
  );
}
