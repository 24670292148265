import { useEffect, useState } from "react"
import { Box, Button, SxProps, Theme, Typography, useMediaQuery, useTheme } from "@mui/material"
import InputTextfield from "@components/InputTextfield"
import PasswordInput from "@components/passwordInput"
import UserFields from "./UserFields"
import SectionWrapper from "@components/SectionWrapper"
import { openDocumentsDialog, sendUserData, setSettingsField, useUserStore } from "@root/stores/user"
import UnderlinedButtonWithIcon from "@root/components/UnderlinedButtonWithIcon"
import UploadIcon from "@root/components/icons/UploadIcon"
import DocumentsDialog from "./DocumentsDialog/DocumentsDialog"
import EyeIcon from "@root/components/icons/EyeIcon"
import { cardShadow } from "@root/utils/theme"
import { getMessageFromFetchError } from "@frontend/kitui"
import { enqueueSnackbar } from "notistack"
import { VerificationStatus } from "@root/model/account"
import { verify } from "./helper"
import { withErrorBoundary } from "react-error-boundary"
import { handleComponentError } from "@root/utils/handleComponentError"
import { OnChangeLoginPassword } from "./onChangeLoginPassword"

export type LP = "email" | "password" | "all" | ""

function AccountSettings() {
	const theme = useTheme()
	const upMd = useMediaQuery(theme.breakpoints.up("md"))
	const upSm = useMediaQuery(theme.breakpoints.up("sm"))
	const isTablet = useMediaQuery(theme.breakpoints.down(1000))
	const isMobile = useMediaQuery(theme.breakpoints.down(600))

	const { settingsFields, user } = useUserStore((state) => state)
	const verificationStatus = useUserStore((state) => state.verificationStatus)
	const verificationType = useUserStore((state) => state.verificationType)
	const comment = useUserStore((state) => state.comment)
	const userId = useUserStore((state) => state.userId) ?? ""

	console.log(user)

	const [onChangeTypeLP, setOnChangeTypeLP] = useState<LP>("")

	const [readySend, setReadySend] = useState(false)
	const [loginPasswordFocus, setLoginPasswordFocus] = useState(false)

	const okLP = () => {
		const ready = readySend

		if (ready) {
			handleSendDataClick()
		}
		setReadySend(false)
		setOnChangeTypeLP("")
	}
	const cancelLP = () => {
		const type = onChangeTypeLP
		setOnChangeTypeLP("")
		setReadySend(false)
		if (type === "email" && user?.email !== settingsFields.email.value) setSettingsField("email", user?.email || user?.login || "")
		if (type === "password") setSettingsField("password", "")
		if (type === "all") {
			setSettingsField("email", user?.email || user?.login || "")
			setSettingsField("password", "")
		}
	}

	// useEffect(() => {
	// 	verify(userId)
	// }, [])

	const textFieldProps = {
		gap: upMd ? "16px" : "10px",
		color: "#F2F3F7",
		bold: true,
	}

	const trySendData = () => {
		if (user?.email !== settingsFields.email.value && settingsFields.password.value.length > 0) {
			setReadySend(true)
			setOnChangeTypeLP("all")
			return
		}
		if (user?.email !== settingsFields.email.value) {
			setReadySend(true)
			setOnChangeTypeLP("email")
			return
		}
		if (settingsFields.password.value.length > 0) {
			setReadySend(true)
			setOnChangeTypeLP("password")
			return
		}
		handleSendDataClick()
	}

	function handleSendDataClick() {
		sendUserData()
			.then(() => {
				enqueueSnackbar("Информация обновлена")
			})
			.catch((error) => {
				const message = getMessageFromFetchError(error)
				if (message) enqueueSnackbar(message)
			})
	}

	return (
		<SectionWrapper
			maxWidth="lg"
			sx={{
				mt: "25px",
				mb: "70px",
				px: isTablet ? (isMobile ? "18px" : "40px") : "20px",
			}}
		>
			<DocumentsDialog />
			<Typography variant="h4" mt="20px">
				Настройки аккаунта
			</Typography>
			<Box
				sx={{
					mt: "40px",
					mb: "40px",
					backgroundColor: "white",
					display: "flex",
					flexDirection: "column",
					borderRadius: "12px",
					p: "20px",
					gap: "40px",
					boxShadow: cardShadow,
				}}
			>
				<Box
					sx={{
						display: "flex",
						gap: "31px",
						justifyContent: "space-between",
						flexDirection: upMd ? "row" : "column",
					}}
				>
					<UserFields
						loginPasswordFocus={loginPasswordFocus}
						setLoginPasswordFocus={setLoginPasswordFocus}
						setOnChangeTypeLP={setOnChangeTypeLP}
					/>
					<Box
						sx={{
							maxWidth: "246px",
						}}
					>
						<Typography variant="p1">Статус</Typography>
						<VerificationIndicator verificationStatus={verificationStatus} sx={{ mt: "16px", p: "14px 7.5px" }} />
						{verificationStatus === VerificationStatus.NOT_VERIFICATED && (
							<>
								<UnderlinedButtonWithIcon
									icon={<UploadIcon />}
									sx={{ mt: "55px" }}
									ButtonProps={{
										onClick: () => openDocumentsDialog("juridical"),
									}}
								>
									Загрузить документы для юр лиц
								</UnderlinedButtonWithIcon>
								<UnderlinedButtonWithIcon
									icon={<UploadIcon />}
									sx={{ mt: "15px" }}
									ButtonProps={{
										onClick: () => openDocumentsDialog("nko"),
									}}
								>
									Загрузить документы для НКО
								</UnderlinedButtonWithIcon>
							</>
						)}
						{verificationStatus === VerificationStatus.VERIFICATED && (
							<UnderlinedButtonWithIcon
								icon={<EyeIcon />}
								sx={{ mt: "55px" }}
								ButtonProps={{
									onClick: () => openDocumentsDialog(verificationType),
								}}
							>
								Посмотреть свою верификацию
							</UnderlinedButtonWithIcon>
						)}
						{comment && <p>{comment}</p>}
					</Box>
				</Box>
				<Button
					variant="pena-contained-dark"
					onClick={trySendData}
					disabled={settingsFields.hasError || loginPasswordFocus}
					sx={{ alignSelf: "end" }}
				>
					Сохранить
				</Button>
			</Box>
			<OnChangeLoginPassword
				type={onChangeTypeLP}
				ok={okLP}
				cancel={cancelLP}
			/>
		</SectionWrapper>
	)
}

export default withErrorBoundary(AccountSettings, {
	fallback: <Typography mt="8px" textAlign="center">Ошибка при отображении настроек аккаунта</Typography>,
	onError: handleComponentError,
})

const verificationStatusData: Record<VerificationStatus, { text: string; color: string; }> = {
	verificated: { text: "Верификация пройдена", color: "#0D9F00" },
	waiting: { text: "В ожидании верификации", color: "#F18956" },
	notVerificated: { text: "Не верифицирован", color: "#E02C2C" },
}

function VerificationIndicator({
	verificationStatus,
	sx,
}: {
	verificationStatus: VerificationStatus;
	sx?: SxProps<Theme>;
}) {
	return (
		<Box
			sx={{
				py: "14px",
				px: "8.5px",
				borderWidth: "1px",
				borderStyle: "solid",
				color: verificationStatusData[verificationStatus].color,
				borderColor: verificationStatusData[verificationStatus].color,
				borderRadius: "8px",
				textAlign: "center",
				...sx,
			}}
		>
			<Typography lineHeight="100%">{verificationStatusData[verificationStatus].text}</Typography>
		</Box>
	)
}