import { Box, SxProps, Theme, Typography, useMediaQuery, useTheme } from "@mui/material"

interface Props {
  width?: string;
  backgroundImage: string;
  headerText: string;
  backgroundPosition: string;
  backgroundSize: string;
  text: string;
  textOrientation: "row" | "column";
  sx?: SxProps<Theme>;
  sxBoxText?: SxProps<Theme>;
  sxHeader?: SxProps<Theme>;
  sxText?: SxProps<Theme>;
  small?: boolean;
}

export default function PromoCard({
	backgroundImage,
	headerText,
	text,
	backgroundSize,
	backgroundPosition,
	textOrientation,
	sx,
	sxHeader,
	sxBoxText,
	sxText,
	width,
	small = false,
}: Props) {
	const theme = useTheme()
	const isTablet = useMediaQuery(theme.breakpoints.up(1000))
	const isMobile = useMediaQuery(theme.breakpoints.down(600))

	return (
		<Box
			sx={{
				height: small ? "300px" : "300px",
				mb: small ? "40px" : undefined,
				width,
				maxWidth: "500px",
				borderRadius: "12px",
				backgroundColor: theme.palette.bg.dark,
				overflow: "hidden",
				backgroundRepeat: "no-repeat",
				boxShadow: `0px 100px 309px rgba(37, 39, 52, 0.24),
                            0px 41.7776px 129.093px rgba(37, 39, 52, 0.172525), 
                            0px 22.3363px 69.0192px rgba(37, 39, 52, 0.143066),
                            0px 12.5216px 38.6916px rgba(37, 39, 52, 0.12),
                            0px 6.6501px 20.5488px rgba(37, 39, 52, 0.0969343),
                            0px 2.76726px 8.55082px rgba(37, 39, 52, 0.0674749)`,
				...sx,
			}}
		>
			<Box
				sx={{
					backgroundSize: backgroundSize,
					backgroundRepeat: "no-repeat",
					backgroundImage: `url(${backgroundImage})`,
					backgroundPosition: backgroundPosition,
					height: small ? "340px" : "300px",
					width: "100%",
					borderRadius: "12px",

					p: "20px",
				}}
			>
				<Box
					sx={{
						display: "grid",
						gridTemplate: small || textOrientation === "column" ? "auto auto / auto" : "2.5em / 30% 50%",
						...sxBoxText
					}}
				>
					<Box
						sx={{
							mb: "20px",
						}}
					>
						<Typography variant="h5" sx={{ maxWidth: "180px", whiteSpace: isMobile ? "nowrap" : "normal", ...sxHeader}}>
							{headerText}
						</Typography>
					</Box>
					<Typography
						sx={{
							overflow: "hidden",
							maxWidth: "200px",
							maxHeight: "5.92em",
							...sxText
						}}
					>
						{text}
					</Typography>
				</Box>
			</Box>
		</Box>
	)
}
