import { Privilege } from "@frontend/kitui";
import { create } from "zustand"
import { devtools } from "zustand/middleware"


interface RequestSquizCreate {
    isRSCOpen: boolean;
}

const initialState: RequestSquizCreate = {
    isRSCOpen: false
}

export const useRequestSquizCreate = create<RequestSquizCreate>()(
	devtools(
		(get, set) => initialState,
		{
			name: "Privileges",
			enabled: process.env.NODE_ENV === "development",
		}
	)
)

export const RSCOpen = () => useRequestSquizCreate.setState({ isRSCOpen: true })
export const RSCClose = () => useRequestSquizCreate.setState({ isRSCOpen: false })