import React from "react";
import { Typography, Box } from "@mui/material";

export default () => (
  <Box
    sx={{
      padding: " 15px 30px",
      "*": {
        textAlign: "justify",
        fontSize: "14px",
      },
      p: {
        textIndent: "35px",
      },
      li: {
        textIndent: "35px",
        textAlign: "justify",
        fontSize: "14px",
      },
    }}
  >
    <Typography variant="h5" sx={{ textAlign: "center" }}>
      ПУБЛИЧНАЯ ОФЕРТА{" "}
    </Typography>
    <Typography variant="h6" sx={{ textAlign: "center" }}>
      по использованию Платформы PenaHub{" "}
    </Typography>
    <Typography sx={{ textAlign: "center" }}>
      Город Владивосток , Приморский край, Российская Федерация
    </Typography>
    <Typography>
      В соответствии с частью 2 статьи 437 Гражданского кодекса Российской
      Федерации настоящее публичное предложение{" "}
      <Typography variant="span" sx={{ fontWeight: 600 }}>
        (Оферта)
      </Typography>{" "}
      Общества с ограниченной ответственностью (ООО) «ПЕНА», в лице его
      Директора (актуальные данные в разделе_
      <Typography
        variant="span"
        sx={{
          fontSize: "18px",
          textDecoration: "underline",
        }}
      >
        ”Реквизиты”
      </Typography>
      ), действующего на основании Устава, именуемого в дальнейшем «Сторона -1»
      ,размещенное в информационно — телекоммуникационной сети Интернет на сайте
      Pena Hub по адресу: pena.digital является его официальным предложением
      неограниченному кругу физических и юридических лиц, именуемых «Сторона 2»
      за вознаграждение получить доступ к сервисам Платформы
    </Typography>
    <Typography>
      В случае принятия Стороной -2 всех изложенных ниже условий{" "}
      <Typography variant="span" sx={{ fontWeight: 600 }}>
        (Акцепт)
      </Typography>
      , договор считается заключенным на условиях, изложенных в данной Оферте.
    </Typography>
    <Typography variant="h6" sx={{ textAlign: "center" }}>
      ОПРЕДЕЛЕНИЯ И ТЕРМИНЫ
    </Typography>

    <Typography>
      В целях настоящей Оферты нижеприведенные термины используются в следующем
      значении:
    </Typography>
    <Typography>
      <Typography variant="span" sx={{ fontWeight: 600 }}>
        «Сторона 1»
      </Typography>{" "}
      – Общество с ограниченной ответственностью (ООО) «ПЕНА»,
      (ОГРН1182536017993 ИНН /КПП 2543126056/254301001), оказывающее на
      возмездной основе Стороне -2 услуги по использованию технических опций и
      сервисов Платформы, а также смежных и дополнительных услуг на основании
      заранее сформированных Тарифов.
    </Typography>
    <Typography>
      <Typography variant="span" sx={{ fontWeight: 600 }}>
        «Сторона 2»/«Пользователь»
      </Typography>{" "}
      – физические или юридические лица, осуществившие Акцепт Оферты
    </Typography>
    <Typography>
      <Typography variant="span" sx={{ fontWeight: 600 }}>
        «Платформа»
      </Typography>{" "}
      – инновационная, интерактивная информационная система, размещенная в сети
      Интернет по адресу Pena.digital, включая поддомены – hub, admin, services,
      storage, tempgen, links, docs, quiz, newerait владельцем которой в
      соответствии с пп.17 п. 1 ст.2 Закона « Об информации, информационных
      технологиях и защите информации» № 149-ФЗ выступает Сторона 1 Платформа
      представляет собой совокупность информации, текстов, графических
      элементов, дизайна, изображений, а также программное обеспечение,
      содержащиеся в информационной системе, обеспечивающей доступность их
      Пользователям
    </Typography>
    <Typography>
      <Typography variant="span" sx={{ fontWeight: 600 }}>
        «Сервисы Платформы»
      </Typography>{" "}
      – совокупность информационно-технических функций сайта, обеспечивающих
      потребителю (Стороне 2) возможность решать свои задачи в
      автоматизированном режиме.
    </Typography>
    <Typography>
      <Typography variant="span" sx={{ fontWeight: 600 }}>
        “Тариф”
      </Typography>{" "}
      – объем услуги, оказываемой Стороной -1 Стороне -2 по её выбору,
      содержание которой определяется Сервисами Платформы.
    </Typography>
    <Typography>
      <Typography variant="span" sx={{ fontWeight: 600 }}>
        “Триал -период”
      </Typography>{" "}
      - ограниченный сроком период времени , в течение которого Пользователь
      безвозмездно, либо частично безвозмездно апробирует Продукт или версию
      Продукта с сокращенным функционалом
    </Typography>
    <Typography>
      <Typography variant="span" sx={{ fontWeight: 600 }}>
        “Регистрация”
      </Typography>{" "}
      – совокупность действий Стороны-2, осуществляемых по алгоритму,
      предложенному Платформой, результатом которой является создание Личного
      кабинета Пользователя платформы.
    </Typography>
    <Typography>
      <Typography variant="span" sx={{ fontWeight: 600 }}>
        «Личный кабинет Пользователя»
      </Typography>{" "}
      – сервис, предоставляемый в разделе Платформы, доступный Пользователю
      после регистрации, через который осуществляется доступ к сервисам
      Платформы и взаимодействие с ними.
    </Typography>
    <Typography>
      <Typography variant="span" sx={{ fontWeight: 600 }}>
        Персональные данные
      </Typography>{" "}
      – любая информация о Пользователе или иных лицах, собираемая Пользователем
      и размещенная им на Платформе, независимо от целей использования,
      относящаяся к прямо или косвенно определенному или определяемому
      физическому лицу (субъекту персональных данных)
    </Typography>
    <Typography>
      <Typography variant="span" sx={{ fontWeight: 600 }}>
        Обработка персональных данных
      </Typography>{" "}
      – любое действие (операция) или совокупность действий (операций),
      совершаемых с использованием средств автоматизации или без использования
      таких средств с персональными данными, включая сбор, запись,
      систематизацию, накопление, хранение, уточнение (обновление, изменение),
      извлечение, использование, передачу (распространение, предоставление,
      доступ), обезличивание, блокирование, удаление, уничтожение персональных
      данных.
    </Typography>
    <Typography>
      <Typography variant="span" sx={{ fontWeight: 600 }}>
        Конфиденциальность персональных данных
      </Typography>{" "}
      – обязательное для соблюдения Оператором или иным получившим доступ к
      персональным данным лицом требование не допускать их распространения без
      согласия субъекта персональных данных или наличия иного законного
      основания.
    </Typography>
    <Typography></Typography>
    <Typography>
      <Typography variant="span" sx={{ fontWeight: 600 }}>
        «Cookies»
      </Typography>{" "}
      – небольшой фрагмент данных, отправленный веб-сервером и хранимый на
      компьютере пользователя, который веб-клиент или веб-браузер каждый раз
      пересылает веб-серверу в HTTP-запросе при попытке открыть страницу
      соответствующего сайта.
    </Typography>

    <Typography variant="h6" sx={{ textAlign: "center" }}>
      1. ПРЕДМЕТ ОФЕРТЫ И ЕЁ АКЦЕПТ
    </Typography>
    <Typography>
      1.1. Предметом настоящей Оферты является оказание Стороной 1 Стороне 2
      (Пользователю) технических, организационных, информационных услуг
      посредством использования Сервисов Платформы, а также сопутствующих услуг,
      не связанных с использованием Платформы, но предназначенных для сходных
      целей, как на возмездной основе, согласно избранному Пользователем Тарифу,
      так и безвозмездно / частично возмездно, в течение Триал -периода,
      установленного Стороной-1
    </Typography>
    <Typography>
      1.2. Платформа является сложным технологическим объектом, не исключает и
      не ограничивает Пользователя в осуществлении посредством неё любых
      законных видов деятельности, включая общественную (некоммерческую) и
      предпринимательскую (коммерческую) деятельность.
    </Typography>
    <Typography>
      1.3. Тарифы пользования Платформой , условия и стоимость , а также условия
      Триал -периодов размещаются в разделе https://hub.pena.digital/tariffs
      Платформы.
    </Typography>
    <Typography></Typography>
    <Typography>
      1.4. Сторона-1 вправе{" "}
      <Typography variant="span" sx={{ fontWeight: 600 }}>
        без предварительного уведомления Пользователя
      </Typography>{" "}
      в одностороннем порядке менять содержание и стоимость Тарифов, обязуясь
      размещать актуальные сведения об этом в указанном разделе. Обязанность
      проверки актуальности Тарифов и ознакомления с ними лежит на Пользователе.
    </Typography>
    <Typography>
      1.5. Изменение Тарифа не имеет обратной силы и его условия применяются
      только к отношением между Сторонами, возникшими после таких изменений.
      Получение услуги Стороной-2 /Пользователем возможно только после Акцепта
      Оферты. Акцептом Оферты является фактическое начало использования
      Платформы с момента получения доступа к сервисам (если право использования
      Платформы предоставляется бесплатно)
    </Typography>
    <Typography>
      1.6. Акцепт Оферты является подтверждением того, что Пользователь
      ознакомился со всеми условиями Оферты и принимает их без каких-либо
      оговорок и ограничений.
    </Typography>
    <Typography>
      1.7. В случае несогласия с каким-либо из условий Оферты Сторона 1
      предлагает отказаться от совершения действий, необходимых для ее Акцепта.
    </Typography>

    <Typography variant="h6" sx={{ textAlign: "center" }}>
      2. ИСПОЛЬЗОВАНИЕ ПЛАТФОРМЫ
    </Typography>
    <Typography>
      2.1. Использование платформы осуществляется на основании настоящей Оферты
      , Политики Конфиденциальности , а также Пользовательского Соглашения , в
      совокупности именуемые Регулирующие документы, которые выступают как
      приложения к настоящей Оферте , имеют равную с ней юридическую силу и
      действие, как если бы они были воспроизведены в основной части Оферты.
      Любая ссылка на Оферту, в том числе и после Акцепта , включает в себя
      ссылку на все Регулирующие документы.
    </Typography>
    <Typography>
      2.2. Регулирующие документы могут изменяться Стороной-1 в одностороннем
      порядке, с обязательной публикацией их актуальной редакции в
      соответствующих разделах Платформы.
    </Typography>
    <Typography>
      2.3. Если у Пользователя возникли вопросы по поводу регулирования
      использования Платформы, он может связаться по электронной почте,
      указанной в разделе «Реквизиты и контактная информация» настоящей Оферты.
      Консультация по правовым аспектам регулирования отношений Сторон является
      правом, а не обязанностью Стороны-1
    </Typography>

    <Typography variant="h6" sx={{ textAlign: "center" }}>
      3. ЛИЧНЫЙ КАБИНЕТ ПОЛЬЗОВАТЕЛЯ (АККАУНТ)
    </Typography>
    <Typography>
      3.1. Для использования сервисов Платформы Пользователю необходимо
      зарегистрироваться , в результате чего будет создан Личный кабинет
      Пользователя (аккаунт).
    </Typography>
    <Typography>
      3.2. Правом на создание Личного кабинета обладает правоспособное,
      зарегистрированное в законном порядке юридическое лицо, а также физическое
      лицо, обладающее дееспособностью и достигшее 18 лет. Использование
      платформы лицом, не достигшим совершеннолетия , либо лицом, лишенным
      дееспособности , допускается только с согласия законных представителей.
    </Typography>
    <Typography>
      3.3. При регистрации Пользователь обязуется предоставить достоверную и
      полную информацию о себе, а в дальнейшем поддерживать эту информацию в
      актуальном состоянии. Пользователь не вправе выдавать себя за другое лицо,
      организацию , которой не является .
    </Typography>
    <Typography>
      3.4. Размещая о себе информацию, определяемую законом, как персональные
      данные, Пользователь дает согласие на использование указанных данных на
      условиях{" "}
      <Typography
        variant="span"
        sx={{
          fontSize: "18px",
          textDecoration: "underline",
        }}
      >
        Политики конфиденциальности .
      </Typography>
    </Typography>
    <Typography>
      3.5. Юридическое лицо, при регистрации его в качестве Пользователя, вводит
      по предложенной Платформой форме регистрации полные сведения об
      организации, либо регистрируется без таковых, по выбору.
    </Typography>
    <Typography>
      3.6. Если наименование является творческим псевдонимом, товарным знаком
      или фирменным наименованием, Пользователь дает право Стороне-2 на
      использование данного наименования без выплаты соответствующих
      вознаграждений.
    </Typography>
    <Typography>
      3.7. Сторона-1 вправе устанавливать требования к логину и паролю от
      Личного кабинета при регистрации.
    </Typography>
    <Typography>
      3.8. Обязанность хранения паролей доступа и риски последствий неисполнения
      такого обязательства возлагаются на Пользователя .Пользователь
      самостоятельно несет ответственность за устойчивость и безопасность
      выбранных им средств аутентификации и обеспечивает их конфиденциальность.
      Пользователь обязуется при окончании работы на Платформе обеспечить
      безопасный выход из Личного кабинета, исключающий кражу его данных и
      другие нарушения.
    </Typography>
    <Typography>
      3.9. Доступ к Личному кабинету и его дальнейшее использование
      осуществляется Пользователем после аутентификации, либо двухфакторной
      аутентификации с использованием кода , направленного на электронную почту
      или по указанному Пользователем при регистрации телефону
    </Typography>
    <Typography>
      3.10. Все действия на Платформе, совершенные Пользователем с
      использованием Личного кабинета, признаются в качестве однозначного и
      бесспорного подтверждения совершения действий именно Пользователем. В
      случае добровольного предоставления доступа к Личному кабинету третьим
      лицам, в том числе на основании совершенных с третьими лицами сделок,
      соглашений, договоров, риски правовых, технических, иных негативных
      последствий и ответственность за действия третьих лиц несет Пользователь.
    </Typography>
    <Typography>
      3.11. Все действия, совершенные с использованием Личного кабинета
      Пользователя, считаются произведенными самим Пользователем, кроме случаев
      уведомления службы технической поддержки Стороны-1 о несанкционированном
      доступе или ином нарушении конфиденциальности своих средств доступа к
      Личному кабинету.
    </Typography>
    <Typography>
      3.12 Личный кабинет не может выступать предметом купли-продажи, дарения ,
      обмена, наследования и других гражданско-правовых сделок без уведомления
      об этом Стороны-1 .
    </Typography>
    <Typography>
      3.13.Личный кабинет (аккаунт) может быть удален Пользователем путем
      направления запроса на электронную почту Стороны 1, указанной в разделе
      Контакты.
    </Typography>
    <Typography>
      3.14. Если Пользователем грубо и неоднократно нарушены условия
      Пользовательского соглашения, допущены нарушения законодательства РФ
      международного права, обычаи делового оборота и цифровой этики, Сторона -1
      вправе заблокировать временно до устранения таких нарушений или удалить
      личный кабинет Пользователя, а также отказать такому Пользователю в
      повторном создании Личного аккаунта.
    </Typography>
    <Typography variant="h6" sx={{ textAlign: "center" }}>
      4. ОБОРОТ ИНФОРМАЦИИ (КОНТЕНТА)
    </Typography>
    <Typography>
      4.1. Приобретая сервисы и возможности Платформы, позволяющие хранить ,
      перерабатывать , распространять и иным образом участвовать в обороте
      размещенной им на Платформе информации , независимо от ее формата
      (текстовые, графические ,мультимедийные файлы и проч.), Пользователь
      обязуется соблюдать действующее законодательства РФ , не допускать утечки
      персональных данных , а также сведений, составляющих чужую личную или
      коммерческую тайну и обеспечивать конфиденциальность любой информации,
      которая гарантируется законом ее субъектам и обладателям.
    </Typography>
    <Typography>
      4.2. Пользователь самостоятельно осуществляет модерацию размещаемой им на
      Платформе информации на предмет соблюдения авторских и иных исключительных
      прав, а также соответствия информации законодательству РФ и нормам
      международного права. Пользователь использует интеллектуальную
      собственность, правообладателем которой он является по закону или по
      договору.
    </Typography>
    <Typography>
      4.3. Пользователь самостоятельно несет имущественную, административную,
      уголовную ответственность за нарушение авторских прав, незаконное участие
      в распространении запрещенной и недостоверной информации (фейки, клевета,
      дискредитация и проч.), нарушения требований о возрастном ограничении
      информации, и защите персональных данных и др. нарушения оборота
      информации, предусмотренные законом.
    </Typography>
    <Typography>
      4.4. В случае претензий и исков направленных Стороне -1, как владельцу
      Платформы , со стороны третьих лиц ,чьи права и интересы затронуты или
      нарушены в ходе оборота информации , размещенной Пользователем на
      Платформе, Пользователь обязуется оперативно своими силами и за свой счет
      устранить все нарушения и возместить все убытки самостоятельно, а в случае
      возложения их на Сторону -1 судебными органами , компенсировать
      произведенные пострадавшей стороне выплаты и затраты, связанные с
      судебными издержками .
    </Typography>
    <Typography>
      4.5. В случае санкций, требований, запретов со стороны органов власти,
      правоохранительных и судебных органов, направленных на Сторону -1 , как
      владельца Платформы, но вызванных недобросовестными действиями
      Пользователя , он обязуется возместить все прямые и косвенные убытки ,
      включая упущенную выгоду.
    </Typography>
    <Typography>
      4.6. Элементы дизайна и интерфейса Платформы , используемые шрифты,
      тексты, графические изображения, мультимедийные объекты , фотографии
      ,программное обеспечение ,скрипты и компьютерные коды , базы данных и иной
      размещенный на Платформе контент, за исключением информации, загруженной
      Пользователем в его Личный кабинет, является результатом интеллектуальной
      деятельности Стороны -1 (РИД) и других правообладателей и не могут
      использоваться без их согласия, за исключением, когда такая возможность
      предоставлена Тарифами и приобретается Пользователем за вознаграждение.
    </Typography>
    <Typography variant="h6" sx={{ textAlign: "center" }}>
      5. ДОПОЛНИТЕЛЬНЫЕ УСЛУГИ
    </Typography>
    <Typography>
      5.1. По запросу Пользователя , выраженному в форме заявки , направленной
      на электронную почту team@pena.digital, Сторона-1 обязуется оказать
      дополнительные услуги, не связанные с использованием Платформы, на
      условиях оферт, размещенных в разделе “ДОКУМЕНТЫ” и являющиеся дополнением
      к настоящей Оферте.
    </Typography>
    <Typography>
      5.2. Акцептом дополнительных услуг дополнительных услуг и принятием
      соответствующих условий является внесение оплаты (предоплаты) за их
      исполнение
    </Typography>
    <Typography>
      5.3. Акцепт настоящей оферты не обязывает Пользователя к использованию
      дополнительных услуг Стороны-1.
    </Typography>
    <Typography variant="h6" sx={{ textAlign: "center" }}>
      6. РЕКЛАМА
    </Typography>
    <Typography>
      6.1. При регистрации Пользователь вправе предоставить Стороне -2 право на
      рассылку рекламных уведомлений (спама) и изменить это в настройках в любой
      момент использования Платформы
    </Typography>
    <Typography>
      6.2. Сторона-2 дает Стороне-1 право на использование в работе Платформы
      файлов «cookies» с целью выяснения предпочтений и потребностей
      Пользователей и эффективного и удобного использования Платформы,в
      частности, для персонализации контента и рекламы, социальных сетей и
      маркетингового и статистического анализа, в том числе, для передачи
      информации об использовании Платформы партнерам Стороны-1 , которые могут
      объединять ее с другой информацией, которую вы им предоставили или которая
      собрана в ходе оказания Платформой услуг.
    </Typography>

    <Typography variant="h6" sx={{ textAlign: "center" }}>
      7. ПРЕФЕРЕНЦИИ
    </Typography>

    <Typography>
      7.1. Пользователи Платформы из числа некоммерческих организаций (НКО)
      имеют право на 60 % скидку по любому из Тарифов , при условии их
      регистрации на Платформе с указанием полных сведений об НКО и ее
      контактных данных.
    </Typography>
    <Typography className="docx_heading1">
      7.2. Сторона-2 , из числа постоянных Пользователей Продуктов, имеет право
      на поощрение ее лояльности в виде скидок и подарков от Стороны-1, согласно
      акций и других кампаний.
    </Typography>
    <Typography>
      Доступ к ним возможен при указании промо-кода , сообщаемого Стороне-2 в
      ходе проведения Стороной -1 таких акций.
    </Typography>
    <Typography variant="h6" sx={{ textAlign: "center" }}>
      8. ОТВЕТСТВЕННОСТЬ
    </Typography>
    <Typography>
      8.1. Пользователь понимает, что Платформа представляет собой сложный
      технический объект, требующий постоянного обслуживания, оптимизации,
      обновления и доработки, не возражает против того, что некоторые сервисы
      Платформы могут работать в тестовом режиме. Вид, технические
      характеристики, сервисы Платформы могут меняться без предварительного
      уведомления и согласия Пользователя.{" "}
    </Typography>
    <Typography>
      8.2. Сторона-1 не может гарантировать пользователю бесперебойную работу и
      безошибочную, но обязуется принимать все усилия к оперативному устранению
      технических проблем
    </Typography>
    <Typography>
      8.3. Сторона-1 вправе устанавливать ограничения отдельным Пользователям,
      категориям Пользователей без предварительного уведомления.
    </Typography>
    <Typography>
      8.4. Правила о защите прав потребителя к вопросам обеспечения
      бесперебойности и скорости работы Платформы, а также к сервисам,
      пользование которыми осуществляется безвозмездно, не применяются{" "}
    </Typography>
    <Typography>
      8.5. Пользователь отвечает перед третьими лицами за свои действия и
      бездействие, связанное с использованием сервисов Платформы, если такие
      действия ведут к нарушениям прав и законных интересов третьих лиц и
      действующего законодательства РФ.
    </Typography>
    <Typography variant="h6" sx={{ textAlign: "center" }}>
      9. ЮРИСДИКЦИЯ
    </Typography>
    <Typography>
      9.1. Во всех спорах Стороны руководствуются действующим законодательством
      РФ, вне зависимости от территория нахождения Пользователя или нахождения
      его оборудования, используемого для работы Платформы.{" "}
    </Typography>
    <Typography>
      9.2. При изменении законодательства РФ, делающих затруднительным или
      невозможным исполнение каких-либо положений Регулирующих документов,
      Стороны обязуются заключить новый Регулирующий документ в той редакции,
      что отвечала бы первоначальным целям использования Платформы
    </Typography>
    <Typography>
      9.3. Нормы международного права и обычаи делового оборота используются
      только в той части, что не противоречит законодательству РФ.
    </Typography>
    <Typography>
      9.4. Все споры и разногласия предварительно разрешаются в претензионном
      порядке, при этом стороны всецело содействуют мирному урегулированию
      споров. При недостижении согласия споры рассматриваются во Фрунзенском
      районном суде города Владивостока, за исключением споров между
      юридическими лицами, юрисдикция которых определяется
      арбитражно-процессуальным законом РФ.
    </Typography>
    <Typography variant="h6" sx={{ textAlign: "center" }}>
      10. СРОК ДЕЙСТВИЯ СОГЛАШЕНИЯ
    </Typography>
    <Typography>
      10.1.Настоящий Договор и иные Регулирующие документы вступают в силу с
      момента их акцепта Пользователем и действуют бессрочно. Каждая из Сторон
      вправе отказаться от исполнения настоящего Договора, уведомив об этом
      другую Сторону посредством уведомления по электронной почте.
    </Typography>
    <Typography>
      10.2.Правила Договора будет применяться в этом случае к периоду
      ,предшествующему прекращения договорных отношений между Сторонами
    </Typography>
    <Typography>
      10.3. случае переуступки требований, продажи предприятия или Платформы
      третьим лицам, все обязательства перед Пользователями сохраняются перед
      новым правообладателем в полном объеме. Пользователь дает согласия на
      такую переуступку.
    </Typography>
    <Typography variant="h6" sx={{ textAlign: "center" }}>
      11. ЗАКЛЮЧИТЕЛЬНЫЕ УСЛОВИЯ
    </Typography>
    <Typography>
      11.1. Пользователь дает согласие на получения уведомлений (нотификаций)
      для информирования Пользователя о технических изменениях в работе функций
      Платформы, юридических документов, направления уведомлений рекламного
      характера{" "}
    </Typography>
    <Typography>
      11.2. Пользователь вправе использовать электронные документы ,
      удостоверенные простой (неквалифицированной) электронной подписью ,
      наличие которой подтверждается фактом её формирования с применением
      одновременно обоих ключей :
    </Typography>
    <ul type="disc">
      <li>Логин и пароль Пользователя</li>
      <li>Адрес электронной почты, указанный при аутентификации</li>
    </ul>
    <Typography>
      Электронные документы, подписанные таким способом , приравниваются к
      документам на бумажном носителе, подписанном собственноручной подписью.
    </Typography>
    <Typography>
      11.3. Любые действия, совершенные использованием простой электронной
      подписи определенного Пользователя, считаются совершенными таким
      Пользователем. Пользователь освобождается от последствий использования
      таких данных третьими лицами, если докажет , что принял все меры
      заботливости и осмотрительности по защите своего личного кабинета ,
      своевременно уведомил Сторону-1 о подозрительных действиях с его
      аккаунтом, указывающим на несанкционированный доступ к личному кабинету,
      либо сообщил о краже или утрате паролей , иных данных, дающих доступ к
      личному кабинету.
    </Typography>
    <Typography>
      11.4. При обнаружении вредоносных программ , небезопасных кодов
      обеспечения с оборудования Пользователя и выявления автоматических
      запросов к Платформе с оборудования Пользователя ,Сторона -1 вправе защиты
      своего права на информационную безопасность Платформы прекратить прием
      любой информации, сгенерированной автоматически, запретить соединение с
      оборудованием Пользователя, заблокировать или удалить аккаунт Пользователя
      , вплоть до запрета повторного создания личного кабинета таким
      Пользователем.
    </Typography>
    <Typography>
      11.5. Сторона -1 .как владелец Платформы и правообладатель её элементов,
      являющихся результатами интеллектуальной деятельности, запрещает
      декомпиляцию и реверсивный инжиниринг программного обеспечения Платформы.
    </Typography>

    <Typography variant="h6" sx={{ textAlign: "center" }}>
      РЕКВИЗИТЫ:{" "}
    </Typography>
    <Box sx={{ textIndent: 0 }}>
      <Typography>ООО «ПЕНА»</Typography>
      <Typography>ОГРН 1182536017993</Typography>
      <Typography>ИНН / КПП: 2543126056/254001001</Typography>
      <Typography>р/сч. № 40702810720000073015</Typography>
      <Typography>Корр/счет 30101810745374525104</Typography>
      <Typography>БИК 044525104</Typography>
      <Typography>Банк: ООО "Банк Точка"</Typography>
      <Typography>
        Адрес: г. Владивосток, ул. Алеутская 28, кабинет 207
      </Typography>
      <Typography>Тел.: +79644422240</Typography>
      <Typography>E-mail: team@pena.digital</Typography>
      <Typography>
        Директор на основании Устава: Линдберг Иван Романович
      </Typography>
    </Box>
  </Box>
);
