import { useEffect, useRef } from "react"
import { useNavigate, NavigateFunction } from "react-router-dom"

interface LocationState {
  previousUrl: string;
}

interface RouteLocation {
  state: LocationState;
}

export const usePrevLocation = (location: RouteLocation | null) => {
	const navigate: NavigateFunction = useNavigate()
	const prevLocRef = useRef<RouteLocation | null>(location)

	useEffect(() => {
		prevLocRef.current = location
	}, [location])

	const handleCustomBackNavigation = () => {
		if (prevLocRef.current?.state?.previousUrl) {
			const prevUrl = prevLocRef.current.state.previousUrl
			if (prevUrl.includes("/support/")) {
				return navigate(-2)
			}
		}
		return navigate(-1)
	}

	return handleCustomBackNavigation
}
