import { useNavigate } from "react-router-dom"
import { useMediaQuery, useTheme, Box, Link, Typography } from "@mui/material"
import SectionWrapper from "@components/SectionWrapper"
import CustomIcon from "@components/icons/CustomIcon"
import CalendarIcon from "@components/icons/CalendarIcon"
import PieChartIcon from "@components/icons/PieChartIcon"
import TariffCard from "./TariffCard"
import WideTemplCard from "@components/wideTemplCard"
import TemplCardPhoneLight from "@components/templCardPhoneLight"
import CardImage2 from "../../assets/landing/card2.png"

export default function Tariffs() {
	const theme = useTheme()
	const upMd = useMediaQuery(theme.breakpoints.up("md"))
	const isMobile = useMediaQuery(theme.breakpoints.up(550))
	const isTablet = useMediaQuery(theme.breakpoints.down(1000))
	const navigate = useNavigate()

	return (
		<SectionWrapper
			maxWidth="lg"
			sx={{
				mt: upMd ? "60px" : "20px",
				mb: upMd ? "67px" : "75px",
				px: isTablet ? (upMd ? "40px" : "18px") : "20px",
				display: "flex",
				flexDirection: "column",
			}}
		>
			<Typography variant={isMobile ? "h4" : "h5"}>Выберите удобный тариф</Typography>
			<Box
				sx={{
					width: upMd ? "100%" : undefined,
					mt: "40px",
					mb: "30px",
					display: "grid",
					flexWrap: "wrap",
					justifyContent: "center",
					gap: upMd ? "40px" : "30px",
					gridTemplateColumns: `repeat(auto-fit, minmax(300px, ${isTablet ? "439px" : "360px"} ))`,
				}}
			>
				<TariffCard
					icon={<CalendarIcon color="white" bgcolor={theme.palette.purple.main} />}
					headerText="Тарифы на время"
					text="Безлимитные тарифы на любой вкус и срок"
					buttonProps={{
						text: "Подробнее",
						onClick: () => navigate("time"),
					}}
					sx={{ maxWidth: isTablet ? "440px" : "360px" }}
				/>
				<TariffCard
					icon={<PieChartIcon color="white" bgcolor={theme.palette.purple.main} />}
					headerText="Тариф на объем"
					text="Хотите использовать сервис в любой момент не смотря на календарь. Тарифы на объем - лучший выбор для вас"
					buttonProps={{
						text: "Подробнее",
						onClick: () => navigate("volume"),
					}}
					sx={{ maxWidth: isTablet ? "440px" : "360px" }}
				/>
				<TariffCard
					icon={<CustomIcon color="white" bgcolor={theme.palette.purple.main} />}
					headerText="Мой тариф"
					text="Собери свой идеальный тариф себе сам"
					buttonProps={{
						text: "Подробнее",
						onClick: () => navigate("/tariffconstructor"),
					}}
					sx={{ maxWidth: isTablet ? "440px" : "360px" }}
				/>
			</Box>
			<Typography component="div">
				{"Бесплатный период после регистрации - 14 дней"}
			</Typography>
			<Typography
				sx={{
					mt: upMd ? "60px" : "70px",
					fontSize: isMobile ? "36px" : "24px",
					fontWeight: "500",
				}}
			>
        Наши продукты
			</Typography>

			{/*{upMd ? <WideTemplCard sx={{ marginTop: "55px" }} /> : <TemplCardPhoneLight />}*/}
			{upMd ?
				<WideTemplCard
					sx={{ marginTop: "55px" }}
					name={"PenaQuiz"}
					desc={"Конструктор квиз опросов, для любых видов исследований и квиз маркетинга, арбитража трафика"}
					image={CardImage2}
					href={"https://quiz.pena.digital"}
				/>
				:
				<TemplCardPhoneLight
					name={"PenaQuiz"}
					desc={"Конструктор квиз опросов, для любых видов исследований и квиз маркетинга, арбитража трафика"}
					image={CardImage2}
					href={"https://quiz.pena.digital"}
				/>
			}
		</SectionWrapper>
	)
}
