import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import { Alert, Box, Button, Badge, Typography, useMediaQuery, useTheme } from "@mui/material";

import { Loader } from "./Loader";

import { currencyFormatter } from "@root/utils/currencyFormatter";
import { payCart } from "@root/api/cart";
import { setUserAccount, useUserStore } from "@root/stores/user";
import { setNotEnoughMoneyAmount, startPayCartProcess, useNotEnoughMoneyAmount } from "@root/stores/notEnoughMoneyAmount";
import { RSCOpen } from "@root/stores/requestSquizCreate";
import { useCart } from "@root/utils/hooks/useCart";

interface Props {
  priceBeforeDiscounts: number;
  priceAfterDiscounts: number;
  isConstructor?: boolean;
}

export default function TotalPrice({ priceAfterDiscounts, priceBeforeDiscounts, isConstructor = false }: Props) {
  const theme = useTheme();
  const upMd = useMediaQuery(theme.breakpoints.up("md"));
  const notEnoughMoneyAmount = useNotEnoughMoneyAmount(state => state.notEnoughMoneyAmount);
  const userId = useUserStore(store => store.userId) || ""
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const cart = useCart();

  async function handlePayClick() {
    setLoading(true);

    const isCC = cart.services.length > 0 && cart.services[0].tariffs.some(t => t.privileges[0].privilegeId === "quizManual")
    const [payCartResponse, payCartError] = await payCart();

    if (payCartError) {
      if (payCartError.includes("insufficient funds: ")) {
        const notEnoughMoneyAmount = parseInt(payCartError.replace(/^.*insufficient\sfunds:\s(?=\d+$)/, ""));

        setNotEnoughMoneyAmount(notEnoughMoneyAmount);
      }

      setLoading(false);
      if (!payCartError.includes("insufficient funds: ")) enqueueSnackbar(payCartError);
      return 
    }

    if (payCartResponse) {
      if (isCC) RSCOpen()
      setUserAccount(payCartResponse);
    }

    setLoading(false);
  }

  function handleReplenishWallet() {
    startPayCartProcess(userId)
    navigate("/payment", { state: { notEnoughMoneyAmount } });
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: upMd ? "row" : "column",
        mt: upMd ? "80px" : "70px",
        pt: upMd ? "30px" : undefined,
        borderTop: upMd ? `1px solid ${theme.palette.gray.dark}` : undefined,
      }}
    >
      <Box
        sx={{
          width: upMd ? "68.5%" : undefined,
          pr: upMd ? "15%" : undefined,
          display: "flex",
          flexWrap: "wrap",
          flexDirection: "column",
        }}
      >
        <Typography variant="h4" mb={upMd ? "18px" : "30px"}>
          Итоговая цена
        </Typography>
          {!isConstructor &&
              <>
                  <Typography color={theme.palette.gray.main} mb={"20px"}>
                      Здесь написана окончательная стоимость всех услуг сложенных в корзину с учётом всех скидок.
                  </Typography>
                  <Typography color={theme.palette.gray.main}>
                      После нажатия кнопки оплатить, вы будете перенаправлены на форму оплаты, для оплаты ВСЕЙ корзины (рекомендуем перед оплатой, убрать все лишнее)
                  </Typography>
              </>
          }
      </Box>
      <Box
        sx={{
          color: theme.palette.gray.dark,
          width: upMd ? "31.5%" : undefined,
          display: "flex",
          alignItems: upMd ? "center" : "start",
          flexDirection: upMd ? "row" : "column",
          gap: "30px"
        }}
      >
        <Badge
 badgeContent={
                    (priceAfterDiscounts - priceBeforeDiscounts) ? (
                      <span
                        style={{
                          backgroundColor: "#ff4904",
                          color: "white",
                          fontSize: "14px",
                        }}
                      >
                        -
                        {`${((priceBeforeDiscounts - priceAfterDiscounts) / (priceBeforeDiscounts / 100)).toFixed(0)}%`}
                      </span>
                    ) : null
                  }
                  color="success"
                  sx={{
                    "& .MuiBadge-dot": {
                      backgroundColor: "#ff4904",
                      width: "10px",
                      height: "10px",
                    },
                    "& .MuiBadge-anchorOriginTopRightRectangle": {
                      backgroundColor: "#ff4904",
                      top: "5px",
                      right: "5px",
                    },

                    "& .MuiBadge-anchorOriginTopRightRectangular": {
                      backgroundColor: "#ff4904",
                      height: "31px",
                      padding: "5px 10px",
                      right: "0px",
                      top: upMd ? 0 : "17px",
                    },
                  }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: upMd ? "column" : "row",
              alignItems: upMd ? "start" : "center",
              mt: upMd ? "10px" : "30px",
              mb: "15px",
              gap: "15px",
            }}
          >
            <Typography variant="oldPrice" sx={{ opacity: (priceBeforeDiscounts - priceAfterDiscounts)?1:0 , order: upMd ? 1 : 2 }}>
              {currencyFormatter.format(priceBeforeDiscounts / 100)}
            </Typography>
            <Typography
              variant="price"
              sx={{
                fontWeight: 500,
                fontSize: "26px",
                lineHeight: "31px",
                order: upMd ? 2 : 1,
              }}
            >
              {currencyFormatter.format(Math.trunc(priceAfterDiscounts) / 100)}
            </Typography>
          </Box>
        </Badge>
        <Box sx={{display: "flex", alignItems: "center",
            gap: "30px", flexWrap: "wrap",}}>
            {notEnoughMoneyAmount > 0 && (
                <Alert severity="error" variant="filled">
                    Не хватает {currencyFormatter.format(Math.trunc(notEnoughMoneyAmount) / 100)}
                </Alert>
            )}
            <Button
                variant="pena-contained-dark"
                disabled = {priceAfterDiscounts === 0}
                onClick={() => (notEnoughMoneyAmount === 0 ? !loading && handlePayClick() : handleReplenishWallet())}
            >
                {loading ? <Loader size={24} /> : notEnoughMoneyAmount === 0 ? "Оплатить" : "Пополнить"}
            </Button>
        </Box>
      </Box>
    </Box>
  );
}
